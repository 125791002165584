import {Redirect, Route, useHistory} from "react-router-dom";
import React from 'react'
import { useAppSelector } from "@store/hooks";
import { appUserSelector } from "@store/slices/app.slice";
import { isCustomer } from "../helpers/global.helper";
import { AppType } from "@shared/constants/app.constant";

export const CustomerRoute = ({ component, isAuthenticated, ...rest }: any) => {
    const appUser = useAppSelector(appUserSelector);
    const history = useHistory();
    
    const routeComponent = (props: any) => (
        isCustomer() && appUser?.role_name === AppType.Customer
            ? React.createElement(component, props)
             : <Redirect to={{ pathname: `/auth/login/?returnUrl=${window.location.pathname}` }} />
            // : history.push(`/auth/login/?returnUrl=${window.location.pathname}`)
    );
    return <Route {...rest} render={routeComponent} />;
};