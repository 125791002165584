import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import styles from '@auth/styles';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { saveDetail, signUpAction, signupUserSelector } from '@store/slices/auth.slice';
import { phoneSchema } from '@auth/auth.schema';
import {isCustomer, isBusiness, unmaskMobile, useQuery} from '@shared/helpers/global.helper';
import InputMask from 'react-input-mask'
import { useState } from 'react';
import { authService } from '@services/auth.service';
import {NotificationModel} from "@interfaces/notifications";

const useStyles = makeStyles(styles as any);

export const Phone = () => {
    const [phoneError, setPhoneError] = useState<string>('')
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const user = useAppSelector(signupUserSelector)
    const history = useHistory();
    const query = useQuery();
    let returnUrl = query.get("returnUrl") ? `/?returnUrl=${query.get("returnUrl")}` : '';
    // Hack to get around the fact that we're skipping the notification section

    const [notificationForm, setProfileForm] = useState<NotificationModel>({
        is_sold_token_email_notify: false,
        is_purchased_token_email_notify: false,
        is_redeemed_token_email_notify: false,
    })


    const { control, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(phoneSchema),
    });

    const onSubmit = async (event: any) => {
        const phone = unmaskMobile(event.phoneNumber)
        if (phone && phone.length < 12) {
            setPhoneError('Please enter a valid US number');
            return;
        }
        
        const phoneExist = await authService.isAvailable({ value: phone, field: 'phone_number' });
        
        if (phoneExist) {
            setPhoneError('This phone number is already in use.');
            return;
        }

        dispatch(saveDetail({ phone_number: phone }));
        if (isCustomer()) {
            //history.push('/auth/signup/payment-method');
            // history.push(`/auth/signup/notifications${returnUrl}`);
            // return;
            // dispatch(saveDetail({ ...user/*, paymentSkipped: false*/, ...notificationForm }));
            dispatch(signUpAction({ model: { ...user, ...{phone_number: phone}, ...notificationForm }, history, path: `/auth/signup/success${returnUrl}` }));
        }
        if (isBusiness()) {
            history.push('/auth/signup/profile');
            return;
        }
    }

    return (
        <div className={classes.boxDimension}>
            <Typography variant="h6" className={classes.title} >
                Please enter your mobile phone number
            </Typography>
            <Box mt={2} width="100%">
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}  >
                    <Grid container justifyContent="center" direction="column" >
                        <Grid item>
                            <Controller
                                name="phoneNumber"
                                control={control}
                                defaultValue={user.phone_number}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <InputMask
                                        mask="+1 (999) 999 9999"
                                        value={value}
                                        onChange={onChange}
                                    >
                                        {(inputProps: any) => (
                                            <TextField
                                                label={'Phone Number'}
                                                value={value}
                                                onChange={onChange}
                                                size={'medium'}
                                                type={'text'}
                                                variant="outlined"
                                                className="w-100"
                                                {...inputProps}
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        )}
                                    </InputMask>
                                )}
                            />
                            {phoneError?.length > 0 && (
                                <Box color="red" textAlign="left" py={1}>{phoneError}</Box>
                            )}
                        </Grid>

                    </Grid>
                    <Box width={1} pt={4} className="flex-justify-center" sx={{ flexDirection: { xs: 'column', md: 'row-reverse' } }}>
                        <Button className="w-100" variant="contained" color="primary" type="submit">
                            Continue
                        </Button>&nbsp;&nbsp;
                        <Button className="w-100" color="primary" variant="outlined" component={Link} to="/auth/signup/address">
                            Back
                        </Button>
                    </Box>
                </form>
            </Box>
        </div>
    )
}
