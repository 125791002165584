import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, Grid, InputAdornment, TextField, ToggleButton, ToggleButtonGroup, Typography, Radio, FormGroup, FormControl, RadioGroup, FormControlLabel, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormTextField } from '@shared/components/UseFormTextField';
import { UseFormCheckBox } from '@shared/components/UseFormCheckBox';
import { Theme } from '@mui/system';
import { createTokenSchema } from '../token.schema';
import { InputEvent } from '@shared/models/input.model';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AppContainer } from '@shared/components/AppContainer';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { createTokenAction, getCategoriesAction, tokenSelector } from '@store/slices/token.slice';
import { tokenService } from '@services/token.service';
import { currencyConversion, getOtherCurrency, ImageToDataUri, parseJSON, readFileAsDataURL, validateFileSize } from '@shared/helpers/global.helper';
import { TextFieldAdornment } from '@shared/components/TextFieldAdornment';
import {
    CURRENCIES,
    CURRENCY,
    LOCKOUT_PERIOD_LIST,
    LOCKOUT_PERIOD_TYPE, MAX_CHARACTER_LIMIT_PERK_DESCRIPTIONS,
    MAX_CHARACTER_LIMIT_PERK_TITLE,
    
} from '@shared/constants/app.constant';
import { UseFormSelect } from '@shared/components/UseFormSelect';
import { IToken } from '@interfaces/token';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { appSelector, appUserSelector } from '@store/slices/app.slice';
import { $CombinedState } from '@reduxjs/toolkit';


const useStyles = makeStyles((theme: Theme) => ({
    active: {
        background: `${theme.palette.primary.dark} !important`,
        color: 'white !important'
    },
    uploadContainer: {
        display: 'flex',
        alignItems: 'center',
        border: '1px dashed #000000',
        flexDirection: 'column',
        padding: '40px'
    },
    redBorder: {
        border: '2px dashed red !important',
    },
    previewContainer: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        border: '1px dashed #000000',
        flexDirection: 'row',
        height: '140px',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    },
    uploadDescription: {
        maxWidth: '254px',
        margin: '0 auto 16px auto'
    },
    customDropdownButton: {
        font: 'inherit !important',
        padding: '16.5px 14px !important',
        borderRadius: '4px !important',
        border: '1px solid #dadada !important',
        justifyContent: 'space-between !important',
        width: '100% !important'
    },
    reservationForm: {
        marginTop: '17px',
        padding: '37px 31px',
        border: '2px solid #CBD5E1',
        borderRadius: '8px'
    },
    rotateIcon: {
        '& svg': {
            transform: 'rotate(180deg) !important'
        }
    }
}));


enum TokenType {
    Single = 'SINGLE',
    Multiple = 'MULTIPLE'
}

type TokenForm = {
    currency: string,
    lockout_period_type: string,
    lockout_period: number,
    expiration_duration_type: string,
    token_type: string,
    floor_price: number,
    royalty: number,
    art_image?: string,
    contract_address: string,
    contract_type: string,
    thumbnail?: string,
    copies_error: string,
    art_image_error: string,
    open_table_error: string,
    redirect_error: string,
    table_url_error: string,
    art_image_updated?: boolean,
    cover_updated?: boolean,
    cover_error: string,
    perks: Array<{ index: number, value: string }>,
    perk_descriptions: Array<{ index: number, value: string }>,
    gate: Array<{ gate_configuration: { type: string }, values: string }>,
}

type ICover = {
    id: number;
    file: string;
}

export const BusinessCreateToken = (props: any) => {
    const classes = useStyles();
    const artImageRef: any = useRef();
    const coverRef: any = useRef();
    const dispatch = useAppDispatch();
    const [importStatus, setimportStatus] = React.useState(0);
    const [openReservation, setOpenReservation] = React.useState(false);
    const [openTableURL, setOpenTableURL] = React.useState(false);
    const [openDiningOption, setOpenDiningOption] = React.useState(false);
    const [openResyOption, setOpenResyOption] = React.useState(false);
    const [openOtherOption, setOpenOtherOption] = React.useState(false);
    const [liveTokenDetail, setliveTokenDetail] = useState({} as IToken)
    const [tokenForm, setTokenForm] = useState<TokenForm>({
        currency: CURRENCY.USD,
        lockout_period_type: LOCKOUT_PERIOD_TYPE.HOURS,
        lockout_period: -1,
        expiration_duration_type: LOCKOUT_PERIOD_TYPE.DAYS,
        token_type: TokenType.Multiple,
        floor_price: 0,
        royalty: 0,
        art_image: '',
        thumbnail: '',
        art_image_error: '',
        open_table_error: '',
        redirect_error: '',
        table_url_error: '',
        copies_error: '',
        art_image_updated: false,
        cover_updated: false,
        cover_error: '',
        contract_address: '',
        contract_type: 'INTERNAL',
        perks: [{ index: 0, value: '' }],
        perk_descriptions: [{ index: 0, value: '' }],
        gate: [
            {
                gate_configuration: { type: '' },
                values: ''
            }
        ]
    })

    const [coverImage, setCoverImage] = useState<ICover[]>([]);
    const tokenSlice = useAppSelector(tokenSelector);
    const appSlice = useAppSelector(appSelector)
    let { id } = useParams<{ id: string }>();
    let history = useHistory();

    const openReservationForm = () => {
        setOpenReservation(!openReservation);
    }
    const handleDining = (event: any) => {
        setOpenDiningOption(!openDiningOption)
        const gateConfig = [...tokenForm.gate];

        var index = gateConfig.length;
        if (event.currentTarget.checked) {
            if (index >= 1) {
                var position = checkIfValueExists('');
                if (position !== -1) {
                    gateConfig.splice(position, 1);
                }
            }
        }
        setTokenForm((prevState: any) => ({ ...prevState, gate: gateConfig }));
    }

    const openTableChecked = (event: any) => {
        setOpenDiningOption(!openDiningOption)
        const gateConfig = [...tokenForm.gate];

        if (event.currentTarget.checked) {

            gateConfig.push({ gate_configuration: { type: 'OPENTABLE' }, values: '' })

        } else {
            var position = checkIfValueExists('OPENTABLE');
            if (position !== -1) {
                gateConfig.splice(position, 1);
            }

        }
        setTokenForm((prevState: any) => ({ ...prevState, gate: gateConfig }));

    }

    const handleOtherOption = (event: any) => {
        const gateConfig = [...tokenForm.gate];
        setOpenOtherOption(!openOtherOption);
        var index = gateConfig.length;
        if (event.currentTarget.checked) {

            gateConfig.push({ gate_configuration: { type: 'REDIRECT' }, values: '' })

        } else {
            var position = checkIfValueExists('REDIRECT');
            if (position !== -1) {
                gateConfig.splice(position, 1);
            }

        }
        setTokenForm((prevState: any) => ({ ...prevState, gate: gateConfig }));

    }
    const onTableUrlUpdate = (e: InputEvent) => {

        const gateConfig = [...tokenForm.gate];
        var position = checkIfValueExists('OPENTABLE');
        gateConfig[position].values = e.currentTarget.value;
        setTokenForm((prevState: any) => ({ ...prevState, gate: gateConfig }));

    }
    const onRedirectUpdate = (e: InputEvent) => {

        const gateConfig = [...tokenForm.gate];
        var position = checkIfValueExists('REDIRECT');
        gateConfig[position].values = e.currentTarget.value;
        setTokenForm((prevState: any) => ({ ...prevState, gate: gateConfig }));

    }

    const checkIfValueExists = (Gate: string) => {
        const gateConfig = [...tokenForm.gate];
        const index = gateConfig.map(i => i.gate_configuration.type).indexOf(Gate);

        return index;
    }

    const handleOpenTable = () => {
        // setTokenForm({ ...tokenForm, gate?.gate_configuration?.type: 'EXTERNAL' });
    }
    /*const handleImportToken = (importStatus: number) => {
        setimportStatus(importStatus);
        if (importStatus === 0) {
            setTokenForm({ ...tokenForm, contract_type: 'INTERNAL' });
        }
        else {
            setTokenForm({ ...tokenForm, contract_type: 'EXTERNAL' });
        }
    }*/

    const { control, handleSubmit, reset, watch, register, setError, formState: { errors } } = useForm({
        resolver: yupResolver(createTokenSchema),
    });

    const onChangeHandler = (e: any, type: string) => {
        if (type === 'token_type' && e.target.value === TokenType.Single) {
            reset({ copies: 1 })
        }
        onTokenFormChange(type, e.target.value)
    }

    const onPerksUpdate = (e: InputEvent, index: number) => {
        const perkClone = [...tokenForm.perks];
        perkClone[index].value = e.target.value
        onTokenFormChange('perks', perkClone)
    }

    const onPerksDescriptionsUpdate = (e: InputEvent, index: number) => {

        const perksDescriptionClone = [...tokenForm.perk_descriptions];
        perksDescriptionClone[index].value = e.target.value
        onTokenFormChange('perk_descriptions', perksDescriptionClone)

    }

    const onAddPerk = () => {
        const perkClone = [...tokenForm.perks, { index: tokenForm.perks.length, value: '' }];

        const perkCloneDesc = [...tokenForm.perk_descriptions, { index: tokenForm.perk_descriptions.length, value: '' }];
        setTokenForm((prevState: any) => ({ ...prevState, perks: perkClone, perk_descriptions: perkCloneDesc }));
    }

    const onArtImageChange = (event: InputEvent) => {
        const reader = new FileReader();
        const file = event.target.files instanceof FileList
            ? event.target.files[0] : null
        if (!file) return;

        if (!validateFileSize([file])) {
            setTokenForm({ ...tokenForm, art_image_error: 'Maximum allowed image size  is 10MB.' });
            return;
        }
        reader.readAsDataURL(file);
        reader.onload = async () => {
            const thumbnail = await ImageToDataUri(reader.result, 100, 100)
            setTokenForm((prevState: any) => ({ ...prevState, art_image: reader.result, art_image_updated: true, thumbnail, art_image_error: '' }));
        };
    }

    const onCoverChange = (event: InputEvent) => {
        const files: any = event.target.files instanceof FileList
            ? event.target.files : []
        setCoverImage([])

        if (!validateFileSize(files)) {
            setTokenForm({ ...tokenForm, cover_error: 'Maximum allowed image size  is 10MB.' });
            return;
        }
        if (files && files.length > 15) {
            setTokenForm({ ...tokenForm, cover_error: 'Maximum 15 cover images are allowed.' });
            return;
        }
        Array.from(files).forEach(async (item: any) => {
            const file = await readFileAsDataURL(item);
            setCoverImage((prevState: any) => {
                return [...prevState, { file }]
            });
        })
        onTokenFormChange('cover_updated', true);
    }

    const getToken = async (id: string) => {
        const response: any = await tokenService.getTokenById(id);
        const token: IToken = response.data.data;

        let perks = parseJSON(token?.profile?.perks);
        perks = perks.map((item: string, index: number) => {
            return { index: index, value: item }
        })

        let perk_descriptions = parseJSON(token?.profile?.perk_descriptions);
        perk_descriptions = perk_descriptions.map((item: string, index: number) => {
            return { index: index, value: item }
        })
        setTokenForm((prevState: any) => {
            return {
                ...prevState,
                contract_type: token?.profile?.contract_type,
                perks: perks,
                perk_descriptions: perk_descriptions,
                art_image: token?.profile?.art_image?.file,
                currency: token?.profile?.currency,
                token_type: token?.profile?.token_type,
                gate: token?.profile?.gate,
                floor_price: token?.profile?.floor_price
            }
        });

        setCoverImage(token?.profile?.covers?.map((x: any) => ({ id: x.id, file: x.file })))
        reset({
            id: token?.id,
            title: token?.profile?.title, description: token?.profile?.description, price: token?.listing_amount,
            royalty: token?.profile?.royalty, lockout_period: token?.profile?.lockout_period, expiration_duration: token?.profile?.expiration_duration, renewal_price: token?.profile?.renewal_price, floor_price: token?.profile?.floor_price,
            copies: token?.profile?.available_copies_count ? token?.profile?.available_copies_count : 1, category_id: token?.profile?.category.id,
            contract_address: token?.profile?.contract_address,
            contract_type: token?.profile?.contract_type
        })
        setliveTokenDetail(token)
    }

    const onTokenFormChange = (field: string, value: any) => {
        setTokenForm((prevState: any) => {
            return { ...tokenForm, [field]: value }
        });
    }

    const onSubmit = (event: any) => {
        if (!tokenForm.art_image) {
            onTokenFormChange('art_image_error', 'Art Image is required');
            return;
        }

        const gateConfig = [...tokenForm.gate];
        var position = checkIfValueExists('');
        if (position !== -1) {
            gateConfig.splice(position, 1);
        }
        setTokenForm((prevState: any) => ({ ...prevState, gate: gateConfig }));

        if (openDiningOption && checkIfValueExists('OPENTABLE') === -1) {
            onTokenFormChange('open_table_error', 'Open table is required.');
            return;
        }

        if (checkIfValueExists('REDIRECT') !== -1 && tokenForm.gate[checkIfValueExists('REDIRECT')].values === '') {
            onTokenFormChange('redirect_error', 'Redirect field is required.');
            return;
        }
        if (checkIfValueExists('OPENTABLE') !== -1 && tokenForm.gate[checkIfValueExists('OPENTABLE')].values === '') {
            onTokenFormChange('table_url_error', 'Table reservation link is required.');
            return;
        }

        if (!coverImage || coverImage.length === 0) {
            onTokenFormChange('cover_error', 'Cover image is required');
            return;
        }
        if (id && event.copies < liveTokenDetail?.profile?.sold_copies_count) {
            onTokenFormChange('copies_error', `Number of memberships must be greater than or equal to sold copies:${liveTokenDetail?.profile?.sold_copies_count}`);
            return;
        }
        onTokenFormChange('cover_error', '');
        onTokenFormChange('copies_error', '');
        onTokenFormChange('art_image_error', '');
        onTokenFormChange('open_table_error', '');
        onTokenFormChange('redirect_error', '');
        onTokenFormChange('table_url_error', '');

        if (!tokenForm.art_image_updated) {
            delete tokenForm.art_image;
            delete tokenForm.thumbnail;
        }

        if (id && tokenForm.cover_updated) {
            event.covers = [...liveTokenDetail?.profile?.covers?.map((x: any) => ({ id: x.id, file: x.file })), ...coverImage]
        }
        else if (!id) {
            event.covers = [...coverImage.map((x: any) => ({ file: x.file }))]
        }
        const { cover_error, art_image_error, copies_error, open_table_error, redirect_error, table_url_error, art_image_updated, cover_updated, ...rest } = tokenForm;
        dispatch(createTokenAction({
            model: {
                ...event,
                ...rest,
                perks: JSON.stringify(tokenForm.perks?.map(x => x.value)),
                perk_descriptions: JSON.stringify(tokenForm.perk_descriptions?.map(x => x.value)),
            },
            history
        }))
    }

    useEffect(() => {
        dispatch(getCategoriesAction());
        if (id) {
            getToken(id)
        }
    }, [])
    const watchPrice = watch("price", false);
    const watchRenewalPrice = watch("renewal_price", false);

    return (
        <AppContainer>
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} className="white" style={{ padding: '15px' }}>
                <h1>{id ? 'Edit' : 'Create New'} Membership</h1>
                
                <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                        <h3>Upload Membership Image</h3>
                        <Box className={`${classes.uploadContainer} ${tokenForm.art_image_error ? classes.redBorder : ''}`}>
                            <Typography className={classes.uploadDescription}>
                                PNG, GIF, JPG. Max 10mb.
                            </Typography>
                            <input
                                ref={artImageRef}
                                onChange={(e: InputEvent) => onArtImageChange(e)}
                                accept="image/*"
                                style={{ display: 'none' }}
                                type="file"
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => artImageRef?.current.click()}
                            >
                                Choose File
                            </Button>

                        </Box>
                        {tokenForm.art_image_error?.length > 0 && (
                            <Box mt={2} color="red">{tokenForm.art_image_error}</Box>
                        )}
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <h3>Art Image Preview</h3>
                        <Box className={classes.previewContainer} >
                            {tokenForm.art_image ? (
                                <img src={tokenForm.art_image} />
                            ) : (
                                <Typography className={classes.uploadDescription}>
                                    Upload file to preview Art Image
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                        <h3>Upload Marketing Image</h3>
                        <Box className={`${classes.uploadContainer} ${tokenForm.cover_error ? classes.redBorder : ''}`}>
                            <Typography className={classes.uploadDescription}>
                                PNG, GIF, JPG. Max 10mb.
                            </Typography>
                            <input
                                ref={coverRef}
                                onChange={(e: InputEvent) => onCoverChange(e)}
                                accept="image/*"
                                multiple
                                style={{ display: 'none' }}
                                type="file"
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => coverRef?.current.click()}
                            >
                                Choose File(s)
                            </Button>

                        </Box>
                        {tokenForm.cover_error?.length > 0 && (
                            <Box color="red" py={2}>{tokenForm?.cover_error}</Box>
                        )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <h3>Cover Preview</h3>
                        <Grid container spacing={4} >
                            {coverImage.map((item: any) => (
                                <Grid item md={coverImage.length == 3 ? 4 : 6} xs={12}>
                                    <Box className={classes.previewContainer}  >
                                        {item ? (
                                            <img src={item.file} />
                                        ) : (
                                            <Typography className={classes.uploadDescription}>
                                                Upload file to preview your Membership cover
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>
                            ))}
                            {coverImage.length == 0 && (
                                <Grid item md={6} xs={12}>
                                    <Box className={classes.previewContainer}  >
                                        <Typography className={classes.uploadDescription}>
                                            Upload file(s) to preview your Membership cover(s). You can upload multiple
                                            images
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container flexDirection="column">
                    <Grid item md={6} >
                        {/* Disable Token Import */}
                        {/*<FormControl>
                            <h3>Import token</h3>

                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="contract_type"
                                aria-required
                            >
                                <FormControlLabel control={<Radio />} checked={tokenForm.contract_type === "INTERNAL"} value="INTERNAL" label="I want to create a new Membership" onClick={(e) => handleImportToken(0)} disabled={!!id} />
                                <FormControlLabel control={<Radio />} checked={tokenForm.contract_type === "EXTERNAL"} value="EXTERNAL" label="I want to use an existing NFT for my Membership" onClick={(e) => handleImportToken(1)} disabled={!!id} />
                            </RadioGroup>
                            {importStatus ?
                                <>
                                    <Box mt={2}>
                                        <UseFormTextField control={control} name="contract_address" placeholder="Enter token contract address" disabled={!!id} />
                                    </Box>
                                </>
                                : <></>
                            }
                        </FormControl>*/}
                        <h3>Number of memberships</h3>
                        
                        {/*{
                            id
                                ? <UseFormTextField control={control} name="copies" disabled placeholder="e.g. 10" />
                                : <UseFormTextField control={control} name="copies" placeholder="e.g. 10" />
                        }*/}
                        <UseFormTextField control={control} name="copies" placeholder="e.g. 10" disabled={!!id} />
                        {tokenForm.copies_error?.length > 0 && (
                            <Box color="red" py={2}>{tokenForm?.copies_error}</Box>
                        )}
                        <Typography variant="caption" className="grey-color">
                            Enter "-1" if you'd like there to unlimited memberships
                        </Typography>
                    </Grid>
                    <Grid item md={6}>
                        <h3>Category</h3>
                        <UseFormSelect items={tokenSlice.categories} control={control} name="category_id" placeholder="Category" />
                    </Grid>
                    <Grid item md={6}>
                        <h3>Price</h3>
                        {/*{
                            id
                                ? <UseFormTextField control={control} name="price" disabled placeholder="Enter price of Membership NFT"
                                                    inputProps={{
                                                        endAdornment: <TextFieldAdornment value={tokenForm?.currency}
                                                                                          select={(value: string) => onTokenFormChange('currency', value)} items={[CURRENCY.ETH]} disabled={true} />,
                                                    }}
                                />
                                : <UseFormTextField control={control} name="price" placeholder="Enter price of Membership NFT"
                                                    inputProps={{
                                                        endAdornment: <TextFieldAdornment value={tokenForm?.currency}
                                                                                          select={(value: string) => onTokenFormChange('currency', value)} items={[CURRENCY.ETH]} />,
                                                    }}
                                />
                        }*/}
                        <UseFormTextField control={control} name="price" placeholder="Enter price of Membership" disabled={!!id}
                            inputProps={{
                                endAdornment: <TextFieldAdornment value={tokenForm?.currency}
                                    select={(value: string) => onTokenFormChange('currency', value)} items={[CURRENCY.USD]} disabled={!!id} />,
                            }}
                        />
                        <Typography variant="caption" className="grey-color">
                            Enter "0" if you'd like your membership to be free
                        </Typography>

                        {/*<Typography variant='subtitle1' >
                            {getOtherCurrency(tokenForm.currency)} Equivalent: &nbsp; <b>{convertedPrice}
                            </b>
                        </Typography>*/}
                        {/*<Typography variant='subtitle1' >
                            Stakes commission: <b>5%</b>
                        </Typography>*/}
                    </Grid>
                    <Grid item md={6}>
                        <h3>Title</h3>
                        <UseFormTextField control={control} name="title" placeholder="e.g. LA Premium Membership." />
                    </Grid>

                    <Grid item md={6}>
                        <h3>Description</h3>
                        <UseFormTextField multiline={true} control={control} name="description" placeholder="e.g. After purchasing you’ll be able to get a VIP seat..." />
                    </Grid>
                    <Grid item md={6}>
                        <h3>Perks</h3>
                        {tokenForm.perks.map(item => {
                            return <Box my={2} sx={{ paddingLeft: '25px' }} key={item.index}>
                                <h5 style={{ marginBottom: '10px' }}>Perk {item.index + 1}</h5>
                                <TextField
                                    name="perks" label="e.g. VIP Access!"
                                    value={tokenForm.perks[item.index]?.value}
                                    inputProps={{ maxLength: MAX_CHARACTER_LIMIT_PERK_TITLE }}
                                    onChange={(e: InputEvent) => onPerksUpdate(e, tokenForm.perks[item.index]?.index)}
                                    className="w-100"
                                    variant="outlined"
                                    required
                                />
                                <Box className={`w-100`} style={{ textAlign: 'right' }}>
                                    <Typography variant="caption" className={`subtitle grey-color`}>
                                        {MAX_CHARACTER_LIMIT_PERK_TITLE - tokenForm.perks[item.index]?.value.length} characters remaining
                                    </Typography>
                                </Box>
                                <Box>
                                    <h5 style={{ marginBottom: '10px' }}>Perk {item.index + 1} Description</h5>
                                    <TextField
                                        name="perk_descriptions" label="e.g. “After purchasing you’ll be able to get a VIP seat...”"
                                        multiline
                                        rows={2}
                                        inputProps={{ maxLength: MAX_CHARACTER_LIMIT_PERK_DESCRIPTIONS }}
                                        value={tokenForm.perk_descriptions[item.index]?.value}
                                        onChange={(e: InputEvent) => onPerksDescriptionsUpdate(e, tokenForm.perk_descriptions[item.index]?.index)}
                                        className="w-100"
                                        variant="outlined"
                                        required
                                    />
                                    <Box className={`w-100`} style={{ textAlign: 'right' }}>
                                        <Typography variant="caption" className={`subtitle grey-color`}>
                                            {MAX_CHARACTER_LIMIT_PERK_DESCRIPTIONS - tokenForm.perk_descriptions[item.index]?.value.length} characters remaining
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        })}

                        {/* {tokenForm.perk_descriptions.map(descriptions => {
                            return <Box my={2} key={descriptions.index}>
                                <Box sx={{ paddingLeft: '25px' }}>
                                    <h3>Description</h3>
                                    <TextField
                                        name="perk_descriptions" label="e.g. “After purchasing you’ll be able to get a VIP seat...”"
                                        multiline
                                        rows={2}
                                        inputProps={{ maxLength: MAX_CHARACTER_LIMIT_TEXTFIELD }}
                                        value={tokenForm.perk_descriptions[descriptions.index]?.value}
                                        onChange={(e: InputEvent) => onPerksDescriptionsUpdate(e, tokenForm.perk_descriptions[descriptions.index]?.index)}
                                        className="w-100"
                                        variant="outlined"
                                        required
                                    />
                                </Box>

                            </Box>
                        })} */}
                        <Button color="primary" onClick={onAddPerk} startIcon={<AddCircleIcon />}>
                            Add another perk
                        </Button>
                    </Grid>
                    {/*{!id && (
                        <Grid item md={6}>
                            <h3>Royalty  %</h3>
                            <UseFormTextField control={control} name="royalty" placeholder="Enter Royalty %"
                                inputProps={{
                                    endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                }} />
                            <Typography variant="caption" className="grey-color">
                                Fees to your business on resale
                                <br/>
                                *Note: This fee will only apply to NFT Marketplaces that accept royalties
                            </Typography>

                        </Grid>
                    )}*/}
                    {/*<Grid item md={6}>
                        <h3>Lockout Period</h3>
                        <UseFormTextField control={control} name="lockout_period" placeholder="Enter Lockout Period"
                            inputProps={{
                                endAdornment: <TextFieldAdornment value={tokenForm?.lockout_period_type}
                                    select={(value: string) => onTokenFormChange('lockout_period_type', value)}
                                    items={LOCKOUT_PERIOD_LIST} />,
                            }}
                        />
                        <Typography variant="caption" className="grey-color">
                            The period of time in which the Membership will not be usable after activation. Enter "-1" if
                            you'd like there to be no lockout period.
                        </Typography>
                    </Grid>*/}
                    <Grid item md={6}>
                        <h3>Renewal Price</h3>
                        {/*{
                            id
                                ? <UseFormTextField control={control} name="renewal_price" disabled placeholder="Enter renewal price of Membership NFT"
                                                    inputProps={{
                                                        endAdornment: <TextFieldAdornment value={tokenForm?.currency}
                                                                                          select={(value: string) => onTokenFormChange('currency', value)} items={CURRENCIES} disabled={true} />,
                                                    }}
                                />
                                : <UseFormTextField control={control} name="renewal_price" placeholder="Enter renewal price of Membership NFT"
                                                    inputProps={{
                                                        endAdornment: <TextFieldAdornment value={tokenForm?.currency}
                                                                                          select={(value: string) => onTokenFormChange('currency', value)} items={CURRENCIES} />,
                                                    }}
                                />
                        }*/}
                        <UseFormTextField control={control} name="renewal_price" placeholder="Enter the renewal price of the Membership" disabled={!!id}
                            inputProps={{
                                endAdornment: <TextFieldAdornment value={tokenForm?.currency}
                                    select={(value: string) => onTokenFormChange('currency', value)} items={CURRENCIES} disabled={!!id} />,
                            }}
                        />

                        <Typography variant="caption" className="grey-color">
                            The cost of renewing the Membership.
                        </Typography>
                        {/*<Typography variant='subtitle1' >
                            {getOtherCurrency(tokenForm.currency)} Equivalent: &nbsp; <b>{convertedRenewalPrice}
                            </b>
                        </Typography>*/}
                        {/*<Typography variant='subtitle1' >
                            Stakes commission: <b>5%</b>
                        </Typography>*/}
                    </Grid>
                    <Grid item md={6}>
                        <h3>Expiration Time</h3>
                        <UseFormTextField control={control} name="expiration_duration" placeholder="Enter Expiration Time"
                            inputProps={{
                                endAdornment: <TextFieldAdornment value={tokenForm?.expiration_duration_type}
                                    select={(value: string) => onTokenFormChange('expiration_duration_type', value)}
                                    items={[LOCKOUT_PERIOD_TYPE.DAYS]} />,
                            }}
                        />
                        <Typography variant="caption" className="grey-color">
                            The length of time the Membership will be valid for before it needs to be renewed. Enter "-1" if it
                            should never expire.
                        </Typography>
                    </Grid>
                    {/*<Grid item md={6}>
                        <h3>Floor Price</h3>
                        <UseFormTextField control={control} name="floor_price" placeholder="Enter Floor Price"
                            inputProps={{
                                endAdornment: <TextFieldAdornment value={tokenForm?.currency}
                                    select={(value: string) => onTokenFormChange('currency', value)}
                                    items={CURRENCIES} />,
                            }} />
                        <Typography variant="caption" className="grey-color">
                            Floor price is the minimum price the Membership NFT can be resold for. Floor price cannot be greater than the list price
                        </Typography>
                    </Grid>*/}

                    <Grid item md={6} >
                        <h3>Add reservation link</h3>

                        <Button color="primary" endIcon={<ArrowDropDownIcon />} onClick={openReservationForm} className={`${classes.customDropdownButton} ${openReservation && classes.rotateIcon}`}>
                            Add reservation link
                        </Button>
                        <Typography variant="caption" className="grey-color">
                            Add special access or digital content that is only available to users who own your membership
                        </Typography>
                        {openReservation || checkIfValueExists('OPENTABLE') !== -1 || checkIfValueExists('REDIRECT') !== -1 ?
                            <>
                                <Grid item md={12}>
                                    <Box className={classes.reservationForm} >
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={openDiningOption || checkIfValueExists('OPENTABLE') !== -1} onChange={(event) => handleDining(event)} />} label="Dining Reservations" />
                                            {openDiningOption || checkIfValueExists('OPENTABLE') !== -1 ? <>
                                                <ul style={{ margin: '0 0 8px 0', listStyle: 'none' }}>
                                                    <li>
                                                        <FormControlLabel sx={{ my: 1 }} control={<Checkbox checked={checkIfValueExists('OPENTABLE') !== -1} onChange={(event) => openTableChecked(event)} required />} label="OpenTable" />
                                                        {tokenForm.open_table_error && <p className='error-cls'>{tokenForm.open_table_error}</p>}
                                                        {!openDiningOption ? <>
                                                            <ul style={{ margin: '0 0 8px 0', listStyle: 'none' }}>
                                                                <li>
                                                                    <TextField value={tokenForm.gate[checkIfValueExists('OPENTABLE')]?.values || ''}  onChange={(e: InputEvent) => onTableUrlUpdate(e)} placeholder="URL" />
                                                                    {tokenForm.table_url_error && <p className='error-cls'>{tokenForm.table_url_error}</p>}
                                                                </li>
                                                            </ul>
                                                        </> : ''}
                                                    </li>

                                                </ul>
                                            </> : ''}
                                            <FormControlLabel sx={{ my: 1 }} control={<Checkbox checked={checkIfValueExists('REDIRECT') !== -1} onChange={(event) => handleOtherOption(event)} value="REDIRECT" />} label="Redirect link" />
                                            {openOtherOption || checkIfValueExists('REDIRECT') !== -1 ? <>
                                                <ul style={{ margin: '0 0 8px 0', listStyle: 'none' }}>
                                                    <li>
                                                        <TextField value={tokenForm.gate[checkIfValueExists('REDIRECT')]?.values || ''} name="redirect_url" onChange={(e: InputEvent) => onRedirectUpdate(e)} placeholder="Redirect link" />
                                                        {tokenForm.redirect_error && <p className='error-cls'>{tokenForm.redirect_error}</p>}
                                                    </li>
                                                </ul>
                                            </> : ''}
                                        </FormGroup>
                                    </Box>
                                </Grid>
                            </> : ''
                        }
                    </Grid>

                    <Grid item md={6}>
                        <Box mt={4}>
                            <Button className={'button'}
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                <Typography
                                    fontWeight={700}
                                    className={'button-label'}
                                >
                                    {id ? 'Save' : 'Create'}
                                </Typography>
                            </Button>
                            <Button type="button" color="primary" component={Link} to="/tokens">
                                <Typography className={'button-label'}>
                                    Cancel
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
            <br />

        </AppContainer >
    )
}
