import { InputAdornment, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'

type FieldProps = {
    control?: any;
    size?: any;
    type?: string;
    name: any;
    placeholder?: string;
    defaultValue?: string;
    disabled?: boolean;
    multiline?: boolean;
    inputProps?: any;
    value?: any;
}
export const UseFormTextField = ({ control, inputProps, name, disabled = false, placeholder, size = "small", multiline = false, type = 'text', defaultValue = '', value}: FieldProps) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                    label={placeholder}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    size={'medium'}
                    type={type}
                    multiline={multiline}
                    rows={2}
                    variant="outlined"
                    className="w-100"
                    InputProps={inputProps}
                    error={!!error}
                    helperText={error ? error.message : null}
                />
            )}
        />
    )
}
