import { RegisterModel } from "./register"

export enum Role {
    Customer = 'CONSUMER',
    Business = 'BUSINESS',
}

export interface SignIn {
    email: string;
    password: string;
}

export interface Verification {
    email: string;
    phone_number: string;
    password:string;
}

export interface Auth {
    user: RegisterModel;
    verification: Verification
}


export enum VerificationState {
    INITIAL = 'INITIAL',
    SUBMITTED = 'SUBMITTED',
    CONFIRMED = 'CONFIRMED',
    VERIFIED = 'VERIFIED'
}