
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { Alert, Box, Button, Grid, } from '@mui/material';
import { AppContainer } from '@shared/components/AppContainer';
import { getTokenAction, tokenSelector } from '@store/slices/token.slice';
import { useEffect } from 'react';

export const BusinessTokenPreview = () => {
    const tokenDetails = useAppSelector(tokenSelector)
    let { id } = useParams<{ id: string }>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (id) {
            dispatch(getTokenAction(id))
        }
    }, [])

    return (
        <AppContainer>
            <Grid container
                className="white" style={{ padding: '15px' }}
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item md={6}>
                    <h1>Create new Membership</h1>
                    <Box display="flex" alignItems="center" flexDirection="column" >
                        <Box width={1}><Alert severity="success">Membership has been successfully created.</Alert></Box>
                        <br />
                        <img width="100%" src={tokenDetails.token?.profile?.art_image?.file} />
                        <Box my={2} width="100%" display="flex" justifyContent="space-between">
                            <Button style={{ width: '45%' }} component={Link} to={`/tokens/${tokenDetails.token?.id}`} variant="contained" color="primary" >
                                View Token
                            </Button>
                            <Button style={{ width: '45%' }} component={Link} to={`/token/edit/${tokenDetails.token?.id}`} variant="outlined" >
                                Edit Token
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </AppContainer>
    )
}
