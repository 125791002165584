import { Link, useHistory } from 'react-router-dom';
import { Button, Grid, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';

import styles from '../styles';
import { AppLogo } from '@shared/components/Logo';
import { forgotPasswordAction } from '../../store/slices/auth.slice';
import { useAppDispatch } from '@store/hooks';
import { forgotPasswordSchema } from '../auth.schema';
import { UseFormTextField } from '@shared/components/UseFormTextField';

const useStyles = makeStyles(styles as any);

export const ForgotPassword = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });

  const onSubmit = (event: any) => {
    dispatch(forgotPasswordAction({ email: event.email, history }))
  }

  return (
    <>
      <Box display="flex" sx={{ justifyContent: { xs: 'center', md: 'space-between' } }} px={4} pt={3}>
        <AppLogo />
      </Box>
      <div className={classes.root}>
        <Box className={`${classes.paper} ${classes.boxDimension}`}
          sx={{
            bgcolor: 'background.paper',
            boxShadow: { xs: 0, md: 10 },
            borderRadius: 1,
            width: { xs: '100%' }
          }} >
          <Typography variant="h6" className={classes.title} gutterBottom>
            Forgot Password
          </Typography>
          <Typography variant="caption" className={classes.subtitle} gutterBottom>
            Enter your email below
          </Typography>
          <Box mt={4} width="100%">
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} >
              <Grid container justifyContent="center" direction="column" spacing={2}>
                <Grid item>
                  <UseFormTextField control={control} name="email" placeholder="Email" />
                </Grid>
                <Grid item>
                  <Button variant="contained" className="w-100" color="primary" type="submit">
                    Submit
                  </Button>
                </Grid>
                <Grid item >
                  <Button color="primary" className="w-100" component={Link} to="/auth/login">
                    Back to Login
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </div>
    </>
  )
}
