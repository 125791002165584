const styles = {
    root: {
        display: 'flex',
        minHeight: 700,
        justifyContent: 'center',
        alignItems: 'center',
        
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        
        padding: '20px',
        margin: '20px',
    },
    boxDimension: {
        minWidth: 350,
        maxWidth: 350,
    },
    profile: {
        minWidth: 750,
        maxWidth: 750,
        padding: '30px',
        marginBottom: '30px'
    },
    uploadContainer: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        border: '1px dashed #000000',
        flexDirection: 'column',
        padding: '40px',
    },
    title: {
        fontWeight: 'bold'
    },
    subtitle: {
        color: 'grey',
        textAlign: 'center',
        fontSize: 'small'
    },
    subtitleLeft: {
        color: 'grey',
        textAlign: 'left',
        fontSize: 'small'
    },
    paymentMethod: {
        width: '100%',
        cursor: 'pointer',
        padding: '2px 10px',
        border: '1px solid rgb(100 116 139 / 14%) !important',
        margin: '7px 1px !important'
    },
    selected: {
        border: '1px solid #041E42 !important'
    },
    noWidth: {
        width: 'unset !important',
        maxWidth: 'unset !important'
    }
}

export default styles;
