import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useHistory } from 'react-router-dom';

import styles from '@auth/styles';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { saveDetail, signupUserSelector } from '@store/slices/auth.slice';
import { userNameSchema } from '@auth/auth.schema';
import { UseFormTextField } from '@shared/components/UseFormTextField';
import {isBusiness, isCustomer, showContentByENV, useQuery} from '@shared/helpers/global.helper';

const useStyles = makeStyles(styles as any);

export const FullName = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const user = useAppSelector(signupUserSelector)
    const history = useHistory();
    const query = useQuery();
    let returnUrl = query.get("returnUrl") ? `/?returnUrl=${query.get("returnUrl")}` : '';

    const { control, handleSubmit } = useForm({
        resolver: yupResolver(userNameSchema),
    });

    const onSubmit = (event: any) => {
        dispatch(saveDetail({ first_name: event.firstName, last_name: event.lastName }));
        history.push(`/auth/signup/address${returnUrl}`)
    }
    return (
        <div className={classes.boxDimension}>
            <Typography variant="h6" className={classes.title} gutterBottom>
                {isCustomer() && (
                    <> Please enter your full name</>
                )}
                {isBusiness() && (
                    <>Please enter your business contact's full name</>
                )}
            </Typography>
            <Box mt={4} width="100%">
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} >
                    <Grid container justifyContent="center" direction="column" spacing={2}>
                        <Grid item>
                            <UseFormTextField control={control} name="firstName" placeholder="First Name" defaultValue={user.first_name} />
                        </Grid>
                        <Grid item>
                            <UseFormTextField control={control} name="lastName" placeholder="Last Name" defaultValue={user.last_name} />
                        </Grid>
                    </Grid>
                    <Box width={1} py={2} className="flex-justify-center" sx={{ flexDirection: { xs: 'column', md: 'row-reverse' } }}>
                        <Button className="w-100" variant="contained" color="primary" type="submit">
                            Continue
                        </Button>&nbsp;&nbsp;
                        <Button className="w-100" color="primary" variant="outlined" component={Link} to={`/auth/signup`}>
                            Back
                        </Button>
                    </Box>
                </form>
            </Box>
        </div>
    )
}
