import { Typography, Skeleton } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

export const DashboardHeaderSkeleton = () => {
    return (
        <Box display="flex" flexDirection="column" py={3} justifyContent="space-between">
             <Typography component="div" className="w-50" variant="body1"><Skeleton /></Typography>
             <Typography component="div" className="w-25" variant="body1"><Skeleton /></Typography>
        </Box>
    )
}
