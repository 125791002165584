import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

import Vectormobilecode from '@assets/images/Vectormobilecode.png';

export const VerifySecurityCode = ({ email, phone, children }: { email: string, phone:string, children: React.ReactNode }) => {
    const getMaskedEmail = (value: string) => {
        if (!value) return;
        // return value.replace(/\d(?=\d{4})/g, "*");
        return value.replaceAll(/(?!^.*)[a-zA-Z0-9\+\_\-\s](?=.{5})/gim, "*");
    }

    const getLast4Digits = (phoneNumber: string) => {
        if (!phoneNumber) return;
        let last4 = phoneNumber.substring(phoneNumber.length - 4);
        return last4;
    }

    return (
        <>
            <Typography variant="h6">
                Enter verification code
            </Typography>
            <Box py={2}  >
                <Typography variant="body1" className="grey-color">
                    We sent a six-digit verification code to your phone number
                </Typography>
                <Typography variant="body2" className="grey-color">
                    <strong>{getLast4Digits(phone)}</strong>
                </Typography>
            </Box>
            {children}
        </>
    )
}
