import { FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'

type FieldProps = {
    control: any;
    size?: any;
    name: any;
    placeholder?: string;
    defaultValue?: string;
    disabled?: boolean;
    inputProps?: any
    items: Array<{ id: string, name: string }>
}
export const UseFormSelect = ({ control, inputProps, name, disabled = false, placeholder, size = "small", defaultValue = '', items }: FieldProps) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl className="w-100">
                    <InputLabel id="demo-simple-select-helper-label">{placeholder}</InputLabel>
                    <Select
                        className="w-100"
                        value={value}
                        size="medium"
                        onChange={onChange}
                        error={!!error}
                        label={placeholder}
                    >
                        {items.map(item => (
                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                        ))}
                    </Select>
                    <FormHelperText style={{ color: '#d32f2f' }}>{error ? error.message : null}</FormHelperText>
                </FormControl>
            )}
        />
    )
}
