import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Button, Table, Grid, TableHead, TableBody, TableRow, Menu, MenuItem, TableCell, ListItem, ListItemAvatar, ListItemText, Avatar, Chip, Typography } from '@mui/material';
import { AppContainer } from '@shared/components/AppContainer';
import { isBusiness, isCustomer } from '@shared/helpers/global.helper';
import { KebabIcon } from '@shared/components/KebabIcon';
import AnPlaceholder from '@assets/images/an-placeholder.svg';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Theme } from '@mui/system';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import SentIcon from '@assets/images/a-sent.svg';
import DeliveredIcon from '@assets/images/a-delivered.svg';
import ViewedIcon from '@assets/images/a-viewed.svg';
import ClickedIcon from '@assets/images/a-clicked.svg';
import EngagedIcon from '@assets/images/a-engage.svg';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { commonSelector, getAnnouncement } from '@store/slices/common.slice';
import { appUserProfileSelector } from '@store/slices/app.slice';
import Moment from 'moment';
import DOMPurify from 'dompurify';


const useStyles = makeStyles((theme: Theme) => ({
    statusCard: {
        padding: '22px 18px',
        border: '1px solid #CBD5E1',
        borderRadius: '5px',
    },
    statusDetail: {
        fontSize: '20px',
        lineHeight: '16px',
        color: '#1E293B',
    },
    statusActive: {
        filter: 'brightness(0) saturate(100%) invert(12%) sepia(11%) saturate(2394%) hue-rotate(178deg) brightness(95%) contrast(90%) !important'
    },
    statusSubDetail: {
        fontSize: '12px',
        lineHeight: '16px',
        color: '#1E293B',
    },
    settingsRow: {
        margin: '0 auto',
        maxWidth: '780px',

    },
    tableResponsiveBox: {
        overflow: 'auto',
        paddingBottom: '25px;'
    },
    imgContainer: {
        '& > div': {
            borderRadius: '4px !important'
        }
    },
    customTable: {
        wordWrap: 'break-word',
        tableLayout: 'fixed',
        '& td': {
            paddingTop: '0',
            borderBottom: '1px solid #E2E8F0',
        },
        '& th': {
            fontFamily: 'SF Pro Display !important',
            fontSize: '10px !important',
            lineHeight: '16px !important',
            letterSpacing: '0em !important',
            textAlign: 'left !important',
            color: '#000 !important',
            borderBottom: '0 !important',
            padding: '8px 16px 16px 16px'
        }
    }
}));

export const ViewAnnouncement = () => {
    const classes = useStyles();
    const [tableOptionAnchorEl, setTableOptionAnchorEl] = React.useState(null);
    const openTableOption = Boolean(tableOptionAnchorEl);
    const { announcementId } = useParams<{ announcementId: string }>();
    const handleTableOptionClick = (event: any) => {
        setTableOptionAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setTableOptionAnchorEl(null);
    };

    const userProfile = useAppSelector(appUserProfileSelector);
    const commonSlice = useAppSelector(commonSelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getAnnouncement(announcementId))

    }, [])
    const dateFormatter = (announcementDate: any) => {
        Moment.locale('en');
        return (Moment(announcementDate).format('MMMM Do YYYY, h:mm a'))
    };
    const breadcrumbs = [
        <Link key="1" component={RouterLink} to="/announcements" color="inherit" href="/">
            Announcement
        </Link>,
        // <Link key="1" component={RouterLink} to={`/public/token/${tokenDetails?.token.id}`} color="inherit" href="/">
        //     {tokenDetails?.token.profile?.title}
        // </Link>,
        <Typography key="3" color="inherit">
            {commonSlice.announcement?.data?.subject}
        </Typography>
    ];

    return <AppContainer>
        <Box mb={6}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
        </Box>

        <Box width={1} >

            <Box style={{ padding: '38px 44px', marginBottom: '20px', border: '1px solid #CBD5E1', borderRadius: '0px', background: '#FFF' }}>
                <Box p={1} alignItems="center" sx={{ display: 'grid', gridTemplateColumns: { xs: 'repeat(4, 1fr)', sm: 'repeat(4, 1fr)' },
                    gridTemplateAreas: `"subject subject subject date"` }} >
                    <Box sx={{ gridArea: 'subject'}}>
                        <ListItem alignItems="center" sx={{ padding: 0 }}>
                            <ListItemAvatar className={classes.imgContainer} sx={{ display: { xs: 'none', sm: 'block' } }} >
                                <Avatar alt="AnPlaceholder" src={commonSlice.announcement?.data?.token_profile?.thumbnail?.file} sx={{ width: '54px', height: '54px', marginRight: '23px' }} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            className={'body5'}
                                            style={{ marginBottom: '5px' }}
                                        >
                                           {commonSlice.announcement?.data?.subject}
                                        </Typography>
                                    </React.Fragment>
                                }
                                /*secondary={
                                    <React.Fragment>
                                        <Typography
                                            className={'body2 '}


                                        >
                                           {commonSlice.announcement?.data?.business?.name}
                                        </Typography>
                                    </React.Fragment>
                                }*/
                            />
                        </ListItem>
                    </Box>
                    <Box sx={{ gridArea: 'date'}}>
                        <Typography
                            style={{ marginBottom: '5px', flexGrow: 1, color: '#94A3B8', textAlign: 'right' }}

                        >
                           {dateFormatter(commonSlice.announcement?.data?.created_at)}
                        </Typography>
                    </Box>
                </Box >
                <Box mt={2} mb={4} >
                    <Typography
                        className={'body1'} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(commonSlice.announcement?.data?.message || '' ) }}
                    >
                      {/*{`${commonSlice.announcement?.data?.message}`}*/}
                    </Typography>
                </Box>

                {isBusiness() && (
                    <>
                        <Box gap={2} pt={4} className={`border-top`} sx={{ display: 'grid', gridTemplateColumns: { xs: 'repeat(2, 1fr)', sm: 'repeat(3, 1fr)', md: 'repeat(5, 1fr)' } }}>
                            <Box >
                                <Box className={classes.statusCard}>
                                    <Box alignItems="center" className={`flex-space-between`} >
                                        <Typography
                                            className={'caption'}
                                            style={{ color: '#94A3B8' }}
                                        >
                                            SENT
                                        </Typography>
                                        <div>
                                            <img src={SentIcon} alt="Sent" />
                                        </div>

                                    </Box>
                                    <Typography
                                        className={classes.statusDetail}
                                    >
                                         {commonSlice.announcement?.data?.user_count ? commonSlice.announcement?.data?.user_count : '0'}
                                    </Typography>
                                </Box>
                            </Box>

                            <Box >
                                <Box className={classes.statusCard}>
                                    <Box alignItems="center" className={`flex-space-between`} >
                                        <Typography
                                            className={'caption'}
                                            style={{ color: '#94A3B8' }}
                                        >
                                            VIEWED
                                        </Typography>
                                        <div>
                                            <img src={ViewedIcon} alt="Viewed" />
                                        </div>

                                    </Box>
                                    <Typography
                                        className={classes.statusDetail}
                                    >
                                        {commonSlice.announcement?.data?.read_count ? commonSlice.announcement?.data?.read_count : '0'}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>

                    </>
                )
                }

            </Box>


        </Box>
    </AppContainer>
}
