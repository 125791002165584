import { toast, ToastOptions } from 'react-toastify';

class ToasterService {
    success(message: string) {
        toast.success(message,{
            icon:true
        });
    }
    error(message: string) {
        toast.error(message,{
            icon:true,
            autoClose: 6000
        });
    }
    warn(message: string) {
        toast.warn(message,{
            icon:true,
            autoClose: 6000
        });
    }
}

export const toasterService = new ToasterService();