import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RouteComponentProps } from "react-router-dom";

import { networkService } from "@services/network-service";
import { isCustomer } from "@shared/helpers/global.helper";
import { RootState } from "@store/store";
import { SignIn, Auth, Role, Verification } from "@interfaces/auth";
import { MessageType, setAccessToken, setLoggedInUser, setMessage } from "@store/slices/app.slice";
import { Business, RegisterModel, AnnouncementsResponse, AnnouncementResponse, AnnouncementAction, RestaurantList } from "@interfaces/register";
import { environment } from "@env/environment";
import { ICommon } from "@interfaces/common";
import { toasterService } from "@services/toaster.service";

const initialState: ICommon = {
    business: {} as Business,
    announcements: {} as AnnouncementsResponse,
    announcement: {} as AnnouncementResponse,
    announcementNotifications: {} as AnnouncementsResponse,
    restaurants: {} as RestaurantList
};


export const getBusinessByIdAction = createAsyncThunk(
    'common/getBusinessById',
    async (id: string, { rejectWithValue, dispatch }) => {
        try {
            const response = await networkService.get(`/businesses/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
// Passing an arguement of "TRUE" on the getAllAnnouncement function will get only 3 Objects otherwise it will get all the Announcements. 
export const getAllAnnouncement = createAsyncThunk(
    'common/getAllAnnouncement',
    async (args, { rejectWithValue, dispatch }) => {
        try {
            const response = await networkService.get(`/announcements`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getNotificationAnnouncements = createAsyncThunk(
    'common/getNotificationAnnouncements',
    async (args, { rejectWithValue, dispatch }) => {
        try {
            const response = await networkService.get(`/announcements?is_quick_notification=true`);
            return response.data;
           
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getAnnouncement = createAsyncThunk(
    'common/getAnnouncement',
    async (announcementId: string, { rejectWithValue, dispatch }) => {
        try {
            const response = await networkService.get(`/announcement/${announcementId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getRestaurantList = createAsyncThunk(
    'common/getRestaurantList',
    async (args, { rejectWithValue, dispatch }) => {
        try {
            const response = await networkService.get(`/restaurants/`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const createAnnouncementAction = createAsyncThunk(
    'common/createAnnouncementAction',
    async ({ history, model }: { model: AnnouncementAction, history: RouteComponentProps["history"] }, { rejectWithValue, dispatch }) => {
        try {
            let response: any;
            model.status = model.status === "true" ? 'ACTIVE' : 'PENDING'
            if (model?.id) {
                const { id } = model;
                response = await networkService.put(`/announcement/up/${id}`, model);
                toasterService.success('Announcement updated successfully.')
                history.push(`/announcements`)
            } else {
                response = await networkService.post('/announcement', model);
                toasterService.success('Announcement created successfully.')
                history.push(`/announcements`)
            }
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const publishAnnouncement = createAsyncThunk(
    'common/publishAnnouncement',
    async ({ history, model }: { model: AnnouncementAction, history: RouteComponentProps["history"] }, { rejectWithValue, dispatch }) => {
        try {
            let response: any;
                response = await networkService.put('/announcement/publish', model);
                toasterService.success('Announcement published successfully.');
                history.push(`/announcements`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const archiveAnnouncement = createAsyncThunk(
    'common/archiveAnnouncement',
    async ({ history, model }: { model: AnnouncementAction, history: RouteComponentProps["history"] }, { rejectWithValue, dispatch }) => {
        try {
            let response: any;
                response = await networkService.put('/announcement/archive', model);
                toasterService.success('Announcement archived successfully.');
                history.push(`/announcements`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const readAnnouncement = createAsyncThunk(
    'common/readAnnouncement',
    async ({ announcementId, history }: { announcementId: string, history: RouteComponentProps["history"] }, { rejectWithValue, dispatch }) => {
        try {
            let response: any;
                response = await networkService.put(`/announcement/read/${announcementId}`);
                history.push(`/view-announcement/${announcementId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const commonSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetAnnouncementSlice(state) {
            Object.assign(state, initialState)
        }
    },
    extraReducers: builder => {
        builder.addCase(getBusinessByIdAction.fulfilled, (state: ICommon, { payload }: PayloadAction<any>) => {
            state.business = payload?.data;
        })
        builder.addCase(getAllAnnouncement.fulfilled, (state: ICommon, { payload }: PayloadAction<any>) => {
            state.announcements = payload;
        })
        builder.addCase(getNotificationAnnouncements.fulfilled, (state: ICommon, { payload }: PayloadAction<any>) => {
            state.announcementNotifications = payload;
        })
        builder.addCase(getAnnouncement.fulfilled, (state: ICommon, { payload }: PayloadAction<any>) => {
            state.announcement = payload;
        })
        builder.addCase(getRestaurantList.fulfilled, (state: ICommon, { payload }: PayloadAction<any>) => {
            state.restaurants = payload;
        })
      
    }
})

export const { reducer: commonReducer } = commonSlice;
export const {resetAnnouncementSlice} = commonSlice.actions;

export const commonSelector = (rootState: RootState) => rootState.common;
