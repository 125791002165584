import {Box, Button, Grid, Link, Typography} from '@mui/material'
import { Link as RouterLink, useParams } from 'react-router-dom';
import { AppContainer } from '@shared/components/AppContainer';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getMyTokensAction, getTokenAction, resetToken, tokenSelector } from '@store/slices/token.slice';
import React, { useEffect, useState } from 'react';
import { TokenOverview } from '@shared/components/TokenOverview';
import { appUserProfileSelector } from '@store/slices/app.slice';
import {environment} from "@env/environment";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Chair} from "@mui/icons-material";
import DOMPurify from "dompurify";

export const RestaurantOverview = () => {



    return (
        <AppContainer>
            <Grid container mt={0} columnSpacing={0} sx={{padding: '1px', flexDirection: {md: 'row', xs: 'column-reverse'}}}>
                <Box style={{ padding: '38px 44px', marginBottom: '20px', border: '1px solid #CBD5E1', borderRadius: '0px', background: '#FFF' }}>
                    <Box mt={2} mb={4} width={'100%'}>
                        <Typography className={'title5 text-center'}>
                            Reserve for Restaurants
                        </Typography>

                        <Typography className={'title3 text-center'}>
                            <p>Reserve helps local restaurants acquire new guests and turn them into regulars.</p>
                        </Typography>

                        <Typography className={'body1'}>
                            <p><strong>About Reserve</strong>: Reserve is a membership for restaurant lovers. Our mission is to connect
                                our members with the most interesting and delightful food and dining experiences. As a
                                Reserve restaurant partner, you will benefit from being one of a select group of restaurants that has direct access to the most active restaurant regulars in Miami.</p>
                        </Typography>

                        <Typography className={'body1'}>
                            <p><strong>The Reserve Experience:</strong> Reserve does not charge fees to our restaurant partners. Our only
                                requirement is that the restaurant does "a little extra" for our members such as offering a
                                complimentary small starter, desert, welcome cocktail, or anything similar. Our members are
                                food lovers and are eager to try a taste of the next dish your chef is working on, your most
                                popular classic, or anything that shows off your dining experience. We want to help you turn our
                                new guests into regulars at your restaurant!</p>
                        </Typography>

                        <Typography className={'body1'}>
                            <p><strong>Our Criteria:</strong> The local restaurants we work with are curated by Reserve and include all types
                                of food, settings, price points, and genres. The primary criteria are great food, great service, and
                                passion. We look forward to learning if Reserve and your restaurant are the right fit for each
                                other.
                                </p>
                        </Typography>

                        <Typography className={'body1'}>
                            <p>
                                <strong>Seamless Integration & Execution:</strong> We understand it is critical for a busy restaurant to have a
                                seamless process in place to deliver and delight our members. Reserve syncs with OpenTable
                                and other reservation platforms. When the member arrives at the restaurant it will be highlighted
                                in your reservation platform so your host can easily identify them and deliver the experience.
                                </p>
                        </Typography>

                        <Typography className={'body1'}>
                            <p>
                                <strong>Special Events:</strong> In addition to offering the Reserve experience on an ongoing basis, we are
                                also working with our restaurant partners to create special events for our members such as chef’s
                                tasting menus, wine pairings, music, and more.
                                </p>
                        </Typography>

                        <Typography className={'body1'}>
                            <p>
                                <strong>Business Objectives:</strong> Our goal is to deliver 2,000 member visits per year (4,000+ covers
                                including guests) to a restaurant partner. We anticipate reaching this target by the third quarter of
                                2024.
                                </p>
                        </Typography>

                        <Typography className={'body1'}>
                            <p>
                                <strong>Cities Served:</strong> Reserve is launching in March in the Miami area. We will also be expanding to
                                Palm Beach, New York and Los Angeles in late 2024.
                                </p>
                        </Typography>

                        <Typography className={'body1'}>
                            <p>
                                <strong>Contact Us:</strong> If you are interested in becoming a Reserve restaurant partner, please contact us
                                at <Link 
                                         href="mailto:reserve@getstakes.io" target="_blank"
                                         rel="noopener noreferrer">{'reserve@getstakes.io'}</Link>
                                </p>
                        </Typography>
                        
                    </Box>
                </Box>
            </Grid>

        </AppContainer>
    )
}
