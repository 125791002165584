import React, {useEffect, useState} from 'react';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Box,
    Button,
    Checkbox, DialogActions,
    Divider,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    Paper,
    Radio,
    Theme,
    Typography
} from '@mui/material'

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PinDropIcon from '@mui/icons-material/PinDrop';
import MovieIcon from '@mui/icons-material/Movie';
import DeleteIcon from '@mui/icons-material/Delete';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PaidIcon from '@mui/icons-material/Paid';
import Link from '@mui/material/Link';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {Link as RouterLink, useHistory, useLocation, useParams} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import {AppContainer} from '@shared/components/AppContainer';
import {useAppDispatch, useAppSelector} from '@store/hooks';
import {getMyTokensAction, getTokenAction, tokenSelector} from '@store/slices/token.slice';
import {
    currencyConversion,
    formatPrice,
    getCurrencySymbol,
    getOtherCurrency,
    parseJSON,
    useQuery
} from '@shared/helpers/global.helper';
import {InputEvent} from '@shared/models/input.model';
import {useTheme} from '@mui/material/styles';
import {tokenService} from '@services/token.service';
import ListingSuccessImage from '@assets/images/post-listing-sucess.png';
import PaymentFailed from '@assets/images/tokenfailed.svg';
import AlertDialog from '@shared/components/AlertDialog';
import TermsConditionDialog from '@shared/components/TermsConditionDialog';
import {
    appSelector,
    appUserProfileSelector,
    getProfileAction,
    setLoading,
    setLoadingMsg
} from '@store/slices/app.slice';
import useMediaQuery from '@mui/material/useMediaQuery';
import {getWalletAction, walletSelector} from "@store/slices/wallet.slice";
import Wallet from "@shared/components/Wallet";
import {WalletTags} from "@interfaces/wallet";
// import {WalletService} from "@unlock-protocol/unlock-js";
import {ethers} from "ethers";
import {toasterService} from "@services/toaster.service";
import {store} from "@store/store";
import {environment} from "@env/environment";
import {unlockAddresses, networkNames} from "@env/blockchain";
import {CURRENCY} from "@shared/constants/app.constant";
import DOMPurify from "dompurify";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme: Theme) => ({
    white: {
        backgroundColor: theme.palette.background.paper,
    },
    dark: {
        color: '#64748B'
    },
    header: {
        marginBottom: '8px',
    },
    green: {
        color: 'green'
    },

    summary: {
        padding: '15px 25px'
    },
    price: {
        padding: '24px 32px'
    },
    list: {
        padding: '0px !important'
    },
    cancelButton: {
        color: '#64748B !important',
        textTransform: 'none'
    },
    thumbnail: {
        
        width: '50px',
        borderRadius: '10px'
    },
}));


export const CustomerBuyToken = () => {
    let query = useQuery();
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(false)
    const [purchasedItemDetail, setPurchasedItemDetail] = useState<any>({})
    const [toCheckout, setToCheckout] = useState(false)
    const [payWithCrypto, setPayWithCrypto] = useState(false)
    const [termsChecked, setTermsChecked] = useState(false)
    const [termsConditionOpen, setTermsConditionOpen] = useState(false)
    const [stripeDialog, setStripeDialog] = useState(false)
    const [stripeFail, setStripeFail] = useState(false)
    const [memberTermsConditionOpen, setMemberTermsConditionOpen] = useState(false)
    const appSlice = useAppSelector(appSelector)
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const tokenDetails = useAppSelector(tokenSelector);
    const dispatch = useAppDispatch();
    let {id} = useParams<{ id: string }>();
    const history = useHistory();
    const userProfile = useAppSelector(appUserProfileSelector);
    const [tokenPrice, setTokenPrice] = useState(tokenDetails?.token?.profile?.price || 0);

    const onChange = (e: InputEvent) => {
        setTermsChecked(e.target.checked)
    }
    const breadcrumbs = [

        <Link key="1" component={RouterLink} to={'/membership'} color="inherit" href="/">
            {tokenDetails?.token.profile?.title}
        </Link>,
        <Typography key="2" color="inherit">
            Buy Membership
        </Typography>
    ];

    const onViewToken = () => {
        setOpen(false)
        history.push(`/restaurant-list`);
    }


    const checkForOwnership = (tokenId: string) => {
        let isOwner = false;
        isOwner = tokenDetails?.myTokens?.data?.some((userToken) => {
            return parseInt(userToken.parent_id) === parseInt(tokenId);
        });

        return isOwner;
    }

    useEffect(() => {

        // Example of a custom event in React
        /*const onClick = (event:any) => console.info("click", event);
        window.addEventListener('click', onClick);*/

        // If user already owns token, redirect back to membership
        if(checkForOwnership(environment.DEFAULT_RESERVEAT_TOKEN_ID)) {
            history.push('/membership');
        }

        if (id) {
            dispatch(getTokenAction(id));
        } else {
            dispatch(getTokenAction(environment.DEFAULT_RESERVEAT_TOKEN_ID));
            id = environment.DEFAULT_RESERVEAT_TOKEN_ID;
        }

        
        // Handle stripe purchase path
        if (query.get('stripePurchase') === 'true' && query.get('session_id') !== null) {
            // Check that this is a fresh session
            let sessionId = query.get('session_id') || '';
            setStripeDialog(true);

            const purchaseTokenCC = async () => {
                // const response: any = await tokenService.buyTokenCC(id, sessionId);
                const response: any = await tokenService.buyTokenCC(environment.DEFAULT_RESERVEAT_TOKEN_ID, sessionId);
                let data = await response.data;
                if (!data?.error && !data?.pending) {
                    setPurchasedItemDetail({token_id: data.tokenId});
                    setStripeDialog(false);
                    setOpen(true);
                    dispatch(getMyTokensAction());
                } else if (data.pending) {
                    setTimeout(purchaseTokenCC, 5000); // Check to see if purchase has completed
                } else if (data?.error) {
                    setStripeDialog(false);
                    setStripeFail(true);
                }
            }

            purchaseTokenCC();

        }

        // Handle renewal vs. initial purchase
        if (query.get('renew') === 'true') {
            setTokenPrice(tokenDetails?.token?.profile?.renewal_price)
        }

    }, []);

    const activateStripe = async () => {
        let stripeParams = {
            name: tokenDetails.token.profile.title,
            // image: tokenDetails.token.profile.art_image.file,
            image: 'https://prod-reserveat-uploads.s3.amazonaws.com/assets/reserveat-logo-text.png',
            price: +(tokenDetails.token.profile.price * 100),
            membership_duration: tokenDetails.token.profile.expiration_duration,
            id: tokenDetails?.token?.id
        }
        const response: any = await tokenService.checkout(stripeParams);
        const body = await response.data;
        window.location.href = body.url;
    }

    return (


        <AppContainer>
            <Box mb={6}>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
            </Box>
            <TermsConditionDialog open={termsConditionOpen} onClose={() => setTermsConditionOpen(false)}>
                <Box height={250}>
                    <p style={{whiteSpace: 'pre-line'}}>
                        {`reserveexp.com Terms of Use
                        
                    These Terms of Use (“Terms”) apply to (i) your access and use of www.reserveexp.com (the “Site”); (ii) the minting, sale and display of non-fungible tokens that may be minted through this site (“NFTs”); and, (iii) the software used in conjunction with the NFTs for purposes of utilizing, authorizing, and validating the experiential benefits associated with the NFTs (the “Software”).  Your purchase of the right to mint and own NFTs includes certain experiential benefits offered by the person authorizing the minting of the NFTs (the “Issuer”). The smart contract associated with an NFT will specify those experiential benefits, along with other terms and conditions of the sale, and should be reviewed by you before you make any purchase on this Site.
                    These Terms are entered into between you and Stakes OC, Inc. (“getStakes”, “Company,” “we,” or “us”). These Terms expressly incorporate any other documents referenced herein (such as our Privacy Policy) and govern your access to and use of the Site, as well as all content, functionality, and services offered on or through the Site, the Site Content (as defined in Section 3), and the Software.

                    Reviewing and Accepting These Terms
                    
                    Please read these Terms carefully, as they set out your rights and responsibilities when you use access and/or use this Site; purchase the right to mint an NFT through the site; and/or use the Software. When each NFT is minted, the agreement authorizing that minting, as well as your rights and obligations per that NFT, is between you and the Issuer. This Site serves only as the platform facilitating the transaction between you and the Issuer and getStakes is not a party to any such agreement.
                    All getStakes NFTs are stored on, and accessible through, the Ethereum blockchain. As such, the Company does not maintain the NFTs on this Site. The Company has no control over the transfer, storage, ownership, and/or maintenance of the NFTs.
                    When you connect your cryptocurrency wallet to the Site, by using a trusted service provider such as MetaMask or Wallet Connect, or you click “I accept” below, you accept and agree to be bound and abide by these Terms and any and all terms incorporated herein by reference, which shall also be considered “Terms,” for purposes of this paragraph. By agreeing to these Terms, you hereby certify that you are at least 18 years of age. If you do not agree to these Terms, you should not access or use the Site.
                    PLEASE NOTE THAT SECTION 15 CONTAINS AN ARBITRATION CLAUSE AND CLASS ACTION WAIVER. BY AGREEING TO THESE TERMS, YOU AGREE TO RESOLVE ALL DISPUTES THROUGH BINDING INDIVIDUAL ARBITRATION, WHICH MEANS THAT YOU WAIVE ANY RIGHT TO HAVE THE DISPUTE DECIDED BY A JUDGE OR JURY, AND YOU WAIVE ANY RIGHT TO PARTICIPATE IN COLLECTIVE ACTION, WHETHER THAT BE A CLASS ACTION, CLASS ARBITRATION, OR REPRESENTATIVE ACTION.
                    We reserve the right to change or modify these Terms at any time and in our sole discretion. You agree and understand that by accessing or using the Site following any change to these Terms, you are agreeing to the revised Terms and all of the terms incorporated therein by reference.
                    You should review the Terms each time you access the Site to ensure that you understand how the Terms apply to your activities on the Site. See Section 15/Modification concerning your right to reject modifications to this Agreement’s arbitration clause.


                    Linking Your Cryptocurrency Wallet
                    
                    When you link your cryptocurrency wallet to the Site, you understand and agree that you are solely responsible for maintaining the security of your wallet and your control over any wallet-related authentication credentials, private or public cryptocurrency keys, and non-fungible tokens or cryptocurrencies that are stored in, or are accessible through, your wallet. Any unauthorized access to your cryptocurrency wallet by third parties could result in the loss or theft of NFTs and/or other digital property held in your wallet and/or any associated wallets, as well as linked financial information such as bank account(s) or credit card(s). We are not responsible for managing and maintaining the security of your cryptocurrency wallet or for any unauthorized access to, or use of, your cryptocurrency wallet. If you notice any unauthorized or suspicious activity in your cryptocurrency wallet that seems to be related to this Site, please notify us immediately.

                    Ownership
                    
                    Unless otherwise indicated in writing by us, the Site, all content, and all other materials contained therein, including, without limitation, our logos, and all designs, text, graphics, pictures, information, data, software, sound files, other files, and the selection and arrangement thereof (collectively, “Site Content”) are the proprietary property of getStakes or our affiliates, licensors, or users, as applicable. The getStakes logo and any getStakes product or service names, logos, or slogans that may appear on the Site or elsewhere are trademarks of getStakes or our affiliates, and may not be copied, imitated, or used, in whole or in part, without our prior written permission.
                    You may not use any Site Content or link to the Site without our prior written permission. You may not use framing techniques to enclose any Site Content without our express written consent. In addition, the look and feel of the Site Content, including without limitation, all page headers, custom graphics, button icons, and scripts constitute the service mark, trademark, or trade dress of getStakes and may not be copied, imitated, or used, in whole or in part, without our prior written permission.

                    Terms of Sale
                    
                    By placing an order on the Site, you are agreeing to submit a binding offer to purchase the right to mint an NFT.
                    With respect to the NFTs, each purchaser of an NFT is granted an exclusive, limited license to such NFT and its content to access, use, or store such NFT, and its content, solely for their personal purposes per the terms agreed to by you and Issuer.  The NFTs are a limited-edition digital creation based upon content that may be trademarked and/or copyrighted by Issuer. Unless otherwise specified, your purchase of an NFT does not give you the right to publicly display, perform, distribute, sell, or otherwise reproduce the NFT, or its content, for any commercial purpose. You further agree that you are not receiving any copyright interest in the NFT, or its content. Any commercial exploitation of the NFT could subject you to claims of copyright infringement.
                    If you sell a an NFT, you agree that you will not have any claims against us for any breach of these Terms by a purchaser or other third party. If you purchase the right to mint an NFT on the Site, you hereby agree to hold us and the seller of such getStakes NFT harmless from and against any and all violations or breaches of these Terms.

                    In addition, when you purchase the right to mint an NFT on this Site, you agree to pay all applicable fees associated with the transaction. We will use good faith efforts to provide accurate information concerning transaction fees prior to your purchase.
                    No refunds are permitted except with respect to any statutory warranties or guaranties that cannot be excluded or limited by law.

                    Intellectual Property
                    
                    Other than Site Content, all other trademarks, product names, and logos on the Site are the property of their respective owners and may not be copied, imitated, or used, in whole or in part, without the permission of the applicable trademark holder. Without limiting the foregoing, if you believe that third-party material hosted on the Site infringes your copyright or trademark rights, please file a notice of infringement by contacting the Designated Copyright Agent listed below.
                    Your notice must contain the following information as required by the Digital Millennium Copyright Act (17 U.S.C. § 512) (“DMCA”):
                    -The full name and a physical or electronic signature of the person authorized to act on behalf of the copyright owner;
                    -Identification of the copyrighted work claimed to have been infringed. If multiple copyrighted works are covered by your notice, you may provide a representative list of the copyrighted works that you claim have been infringed;
                    -Reasonably sufficient detail to enable us to identify and locate the copyrighted work that is claimed to be infringing (e.g., a link to the page on the Site that contains the material);
                    -A mailing address, telephone number, and email address where we can contact you;
                    -A statement that you have a good faith belief that the disputed use of the copyrighted work is not authorized by the copyright owner, its agent, or the law; and
                    -A statement made by you, under penalty of perjury, that the information in the notice is accurate and that you are the copyright owner or are authorized to act on behalf of the copyright owner.
                    Please submit your notice to the Designated Agent below:
                    Stakes OC, Inc.
                    Legal Department
                    1815 Purdy Ave
                    Miami Beach, FL 33139
                    Email: legal@getstakes.io
                    Once you provide us with an adequate notice as described above, we will respond expeditiously and take whatever action, in our sole discretion, we deem appropriate, including removing the disputed copyrighted work from the Site.
                    Counter-Notice:
                    If you believe that a DMCA notice of copyright infringement has been improperly submitted against you, you may submit a counter-notice to the Designated Agent with the following information required by the DMCA:
                    -Your physical or electronic signature;
                    -Identification of the copyrighted work that has been removed or to which access has been disabled including a link to the page on the Site that contained the material before it was removed or disabled;
                    -A statement under penalty of perjury that you have a good faith belief that the copyrighted work was removed or disabled because of mistake or misidentification;
                    -Your name, address, e-mail address, and telephone number; and
                    -A statement that you (i) consent to the jurisdiction of the Federal District Court in the judicial district where your address is located if the address is in the United States, or the United District Court for the Southern District of Florida if your address is located outside of the United States, and (ii) accept service of process from the person who provided the DMCA notice of the alleged copyright infringement.
                    Please submit your notice to the Designated Agent below:
                    Stakes OC, Inc.
                    Legal Department
                    1815 Purdy Ave
                    Miami Beach, FL 33139
                    Email: legal@getstakes.io

                    In the event the Company receives a counter-notice in compliance with the above requirements, we will provide the person who submitted the DMCA copyright infringement notice with a copy of the counter-notice and inform them that the Company will replace the removed material in ten (10) business days from the date of the counter-notice unless the Company first receives notice from the person who submitted the DMCA copyright infringement notice that they have filed an action seeking a court order to restrain the allegedly infringing activity.
                    PLEASE NOTE THAT GETSTAKES INTENDS TO COMPLY WITH ALL PROVISIONS OF THE DIGITAL MILLENNIUM COPYRIGHT ACT BUT WILL NOT UNILATERALLY TAKE RESPONSIBILITY FOR POLICING AND REMOVING MATERIAL THOUGHT TO BE INFRINGING.
                    We hereby grant you a limited, non-exclusive, non-transferable, revocable license to access and use the Site Content. In return, you agree not to engage, or assist, in any activity that violates any law, statute, ordinance, regulation, or sanctions program, including but not limited to, the U.S. Department of Treasury’s Office of Foreign Assets Control (“OFAC”), or that involves proceeds of any unlawful activity; not to engage in any other activity or behavior that poses a threat to Stakes OC, Inc. (e.g., by distributing a virus or other harmful code; through unauthorized access to the Site or other users’ cryptocurrency wallets; or interfering with other users’ access to, or use of, the Site or Software).
                    You also agree not to (1) distribute, publish, broadcast, reproduce, copy, retransmit, or publicly display any Site Content; (2) modify or create derivative works from the Site Content, or any portion thereof; (3) use any data mining, robots, or similar data gathering or extraction methods on the Site or with respect to the Site Content; or, (4) download any portion of the Site Content, other than for purposes of page caching, except as expressly permitted by us.
                    If you are unsure whether a contemplated use of the Site Content or a getStakes NFT and its content would violate these Terms, please contact us at info@getstakes.io.

                    Taxes
                    
                    We are not responsible for determining the withholding, sales, use, value added, transfer, or any other taxes, together with any interest and penalties imposed with respect thereto (“Taxes”), that may apply to transactions on this Site. You agree that you are solely responsible for determining what, if any, Taxes apply to your transactions and to withhold, collect, report and remit the correct amounts of Taxes to the appropriate taxing authorities. Unless otherwise indicated on an applicable invoice, amounts due on this Site are exclusive of sale, use, value-added or similar Taxes (“Sales Taxes”). This means that Sales Taxes become your sole responsibility.

                    Privacy
                    
                    You acknowledge and agree to the collection, use, and disclosure of your personal information in accordance with our Privacy Policy, which is incorporated into these Terms.

                    Modifications
                    
                    You agree and understand that we may modify part or all of this Site and/or the Software without notice, and that we may update these Terms and any other document incorporated by reference therein at any time.

                    Acknowledgements as to Nature of Sale
                    
                    YOU HEREBY ACKNOWLEGE, WARRANT, AND AGREE THAT, IN THE EVENT YOU PURCHASE THE RIGHT TO MINT AN NFT, YOUR PREDOMINANT INCENTIVE IN MAKING THAT PURCHASE IS TO CONSUME THE EXPERIENTIAL BENEFITS ASSOCIATED WITH THAT NFT.  YOU HEREBY FURTHER ACKNOWLEDGE, WARRANT, AND AGREE THAT, IN THE EVENT YOU PURCHASE THE RIGHT TO MINT AN NFT, ANY INCENTIVE YOU MAY HAVE AS TO THAT NFT APPRECIATING IN VALUE OVER TIME IS INCIDENTIAL TO YOUR INCENTIVE IN CONSUMING THE EXPERIENTIAL BENEFITS ASSOCIATED WITH THAT NFT.
                    GETSTAKES HEREBY DISCLAIMS ANY CLAIM, REPRESENTATION, PROMISE, OR WARRANTY THAT ANY PARTICULAR NFT WILL APPRECIATE IN VALUE OVER TIME.  ALTHOUGH IT IS POSSIBLE THAT NFTs MAY APPRECIATE OVER TIME, YOU SHOULD NOT EXPECT THIS TO BE THE CASE AND YOU SHOULD NOT PURCHASE THE RIGHT TO MINT AN NFT FOR THIS PURPOSE.
                    Because of (I) the consumable nature of the NFTs, which may entitle owners to the experiential benefits provided by the Issuers of those NFTs, and (II) the Issuer’s limitations in being able to provide those experiential benefits to different persons, you may be limited in the number of NFTs you can mint from any particular Issuer.
                   
                    Risks
                    
                    Please note the following risks in minting, purchasing, using, and/or selling or NFTs: Although the price, supply, and liquidity of the NFTs are predominantly based on the value of the experiential benefits provided by the NFT and the Issuer’s capacity to provide those benefits to different NFT owners, the price and liquidity of blockchain assets, including NFTs, may be extremely volatile and may be subject to large fluctuations. Legislative and regulatory changes, or actions at the state, federal, or international level may adversely affect the use, transfer, exchange, and value of NFTs. NFTs are not legal tender and are not backed by any government. Transactions in NFTs may be irreversible, and, accordingly, losses due to fraudulent or accidental transactions may not be recoverable. Some transactions in NFTs shall be deemed to be made when recorded on a public ledger, which may not necessarily be the date or time that you initiated the transaction.
                    You also acknowledge the risk that Issuers providing the experiential benefits pursuant to an NFT may, for reasons outside the control of Stakes OC, Inc., cease to offer the benefits provided by the NFT when you purchased the right to mint the NFT because of the Issuer’s cessation of business operations or for any other reason.  YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOU ARE RELYING ON THE REPUTATION, GOODWILL, AND/OR YOUR PERSONAL KNOWLEDGE OF THE ISSUER WHEN PURCHASING THE RIGHT TO MINT AN NFT.  THE COMPANY HEREBY DISCLAIMS ANY AND ALL LIABILITY WITH RESPECT TO ANY CLAIMS, INCLUDING, BUT NOT LIMITED TO, CLAIMS FOR MONEY DAMAGES, YOU MAY HAVE CONCERNING THE EXPERIENTIAL BENEFITS THAT MAY, OR MAY NOT, BE PROVIDED BY THE ISSUER PURSUANT TO AN GETSTAKES NFT.
                    You agree and understand that you are solely responsible for determining the nature, potential value, suitability, and appropriateness of these risks for yourself, and that we do not give or provide advice or recommendations regarding NFTs. You agree and understand that you are accessing and using this Site at your own risk; however, this brief statement does not disclose all of the risks associated with NFTs and other digital assets. You agree and understand that we will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using NFTs, however caused, including, but not limited to, those caused by the Site or the Software.

                    Disclaimers
                    
                    EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN WRITING BY STAKES OC, INC., THE SITE, SITE CONTENT, THE SOFTWARE, AND THE NFTS ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED. GETSTAKES MAKES NO WARRANTY THAT THE SITE OR ITS SOFTWARE WILL (1) MEET YOUR REQUIREMENTS; (2) BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (3) BE ACCURATE, RELIABLE, COMPLETE, LEGAL, OR SAFE.
                    WE WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND FROM ANY ACTION TAKEN OR NOT TAKEN IN RELIANCE ON MATERIAL OR INFORMATION CONTAINED ON THE SITE. GETSTAKES DOES NOT REPRESENT OR WARRANT THAT SITE CONTENT IS ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR-FREE.
                    WHILE WE ATTEMPT TO MAKE YOUR ACCESS TO, AND USE OF, THE SITE, SITE CONTENT, AND SOFTWARE SAFE, WE CANNOT AND DO NOT REPRESENT OR WARRANT THAT THE SITE, SITE CONTENT, AND/OR THE SOFTWARE, OR OUR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE CANNOT GUARANTEE THE SECURITY OF ANY DATA THAT YOU DISCLOSE ONLINE. YOU HEREBY ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND WILL NOT HOLD US RESPONSIBLE FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.
                    WHILE WE TAKE ALL REASONABLE STEPS TO ENSURE THAT THE SITE IS AVAILABLE AT ALL TIMES, YOU SHOULD EXPECT THAT THE SITE WILL ENCOUNTER SOME DOWNTIME DUE TO TECHNICAL ISSUES AND/OR MAINTENANCE AND WE WILL NOT BE LIABLE IN THE EVENT THE SITE IS UNAVAILABLE AT ANY TIME.
                    WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF EITHER THIS SITE, THE SOFTWARE, AND/OR NFTS, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (A) USER ERROR SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY CONSTRUCTED TRANSACTIONS, OR MISTYPED ADDRESSES; (B) SERVER FAILURE OR DATA LOSS; (C) CORRUPTED WALLET FILES; (D) UNAUTHORIZED ACCESS TO APPLICATIONS; (E) ANY UNAUTHORIZED THIRD PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION, THE USE OF VIRUSES, PHISHING, BRUTE FORCING OR OTHER MEANS OF ATTACK AGAINST THE SITE, SOFTWARE OR GETSTAKES NFTS.
                    NFTS ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE ETHEREUM NETWORK. ANY TRANSFER OF TITLE THAT MIGHT OCCUR IN ANY UNIQUE DIGITAL ASSET OCCURS ON THE DECENTRALIZED LEDGER WITHIN THE ETHEREUM PLATFORM. WE DO NOT GUARANTEE THAT GETSTAKES CAN AFFECT THE TRANSFER OF TITLE OR RIGHTS IN ANY  NFT.
                    We are not responsible for sustained casualties due to vulnerability or any kind of failure, abnormal behavior of software (e.g., wallet, smart contract), blockchains, or any other features of the Site, the Software, or NFTs. Stakes OC, Inc. is not responsible for casualties due to late reports by developers or representatives (or no report at all) of any issues with the blockchain supporting NFTs including forks, technical node issues, or any other issues affecting the value of NFTs.
                    Nothing in these Terms shall exclude or limit liability of either party for fraud, death or bodily injury caused by gross negligence, violation of laws, or any other activity that cannot be limited or excluded by legitimate means.

                    Limitation of Liability
                    
                    TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL STAKES OC, INC. BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES ARISING FROM THESE TERMS, THE SITE, SITE CONTENT, THE SOFTWARE, THE NFTS, OR THIRD PARTY SITES AND PRODUCTS, OR FOR ANY DAMAGES RELATED TO LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF DATA, WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF STAKES OC, INC. HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                    ACCESS TO, AND USE OF, THE SITE, SITE CONTENT, THE SOFTWARE, THE NFTS, OR THIRD-PARTY SITES AND PRODUCTS, ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA RESULTING THEREFROM.
                    NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF STAKES OC, INC. ARISING OUT OF, OR IN ANY WAY RELATED TO, THIS AGREEMENT, THE SITE, SITE CONTENT, THE SOFTWARE, AND/OR THE NFTS EXCEED THE GREATER OF (A) $100.00 OR (B) THE AMOUNT PAID FOR THE NFT AT ISSUE.

                    Indemnification
                    
                    To the fullest extent permitted by applicable law, you agree to indemnify, defend and hold harmless Stakes OC, Inc. and our respective past, present and future employees, officers, directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors and assigns (individually and collectively, the “getStakes Parties”), from and against all actual or alleged third party claims, damages, awards, judgments, losses, liabilities, obligations, penalties, interest, fees, expenses (including, without limitation, attorneys’ fees and expenses) and costs (including, without limitation, court costs, costs of settlement and costs of pursuing indemnification and insurance), of every kind and nature whatsoever, whether known or unknown, foreseen or unforeseen, matured or unmatured, or suspected or unsuspected, in law or equity, whether in tort, contract or otherwise (collectively, “Claims”), including, but not limited to, damages to property or personal injury, that are caused by, arise out of, or are related to (a) your use or misuse of the Site, Site Content, the Software, or NFTs, (b) your violation of these Terms, (c) your violation of the rights of a third party, including another user of the Site and (e) your failure to pay any Taxes or Sales Taxes in connection with your transactions on this Site. You agree to promptly notify us of any third-party Claims and cooperate with the getStakes Parties in defending such Claims. You further agree that the getStakes Parties shall have control of the defense or settlement of any third-party Claims

                    Governing Law
                    
                    These Terms of Use, your rights and obligations, and all actions contemplated by, arising out of, or related to these Terms shall be governed by the laws of the State of Florida, as if these Terms are a contract wholly entered into and wholly performed within the State of Florida. YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE SITE, SITE CONTENT, AND THE SOFTWARE, AS CONTEMPLATED BY THESE TERMS, SHALL BE DEEMED TO HAVE OCCURRED IN THE STATE OF FLORIDA AND SUBJECT TO THE INTERNAL LAWS OF THE STATE OF FLORIDA WITHOUT REGARD TO ITS CONFLICTS OF LAWS PROVISIONS.

                    Disputes and Arbitration Agreement
                    
                    Carefully read the following arbitration agreement ("Arbitration Agreement"). It requires you to arbitrate disputes with Stakes OC, Inc. and limits the manner in which you can seek relief from us.
                    Applicability of Arbitration Agreement. You agree that any dispute or claim relating in any way to your access or use of the Site, Site Content, the Software, NFTs, or to any aspect of your relationship with Stakes OC, Inc. will be resolved by binding arbitration, rather than in court, except that (1) you may assert claims in small claims court if your claims qualify; and (2) you or getStakes may seek equitable relief in court for infringement or other misuse of intellectual property rights (such as trademarks, trade dress, domain names, trade secrets, copyrights, and patents).
                    Arbitration Rules and Forum. The Federal Arbitration Act governs the interpretation and enforcement of this Arbitration Agreement. To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claim to our registered agent at Legal Department, Stakes OC, Inc., 2195 Meridian Avenue, Miami Beach, FL 33139. The arbitration will be conducted by JAMS, an established alternative dispute resolution provider. Disputes involving claims and counterclaims under $250,000, not inclusive of attorneys’ fees and interest, shall be subject to JAMS’s most current version of the Streamlined Arbitration Rules and procedures available at http://www.jamsadr.com/rules-streamlined-arbitration/; all other claims shall be subject to JAMS’s most current version of the Comprehensive Arbitration Rules and Procedures, available athttp://www.jamsadr.com/rules-comprehensive-arbitration/. JAMS’s rules are also available at jamsadr.com or by calling JAMS at 800-352-5267. If JAMS is not available to arbitrate, the parties will select an alternative arbitral forum. If the arbitrator finds that you cannot afford to pay JAMS’s filing, administrative, hearing and/or other fees and cannot obtain a waiver from JAMS, getStakes will pay them for you. You may choose to have the arbitration conducted by either telephone or video conference or based on written submissions, or you may request to meet in-person for arbitration in Miami, Florida. You agree that any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.
                    Authority of Arbitrator. The arbitrator shall have exclusive authority to (a) determine the scope and enforceability of this Arbitration Agreement and (b) resolve any dispute related to the interpretation, applicability, enforceability, or formation of this Arbitration Agreement including, but not limited to, any claim that all or any part of this Arbitration Agreement is void or voidable. The arbitration will decide the rights and liabilities, if any, of you and getStakes. The arbitration proceeding will not be consolidated with any other matters or joined with any other cases or parties. The arbitrator shall have the authority to award monetary damages and to grant any non-monetary remedy or relief available to an individual under applicable law, the arbitral forum’s rules, and the Agreement (including the Arbitration Agreement). The arbitrator shall issue a written award and statement of decision describing the essential findings and conclusions on which the award is based, including the calculation of any damages awarded. The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have. The award of the arbitrator is final and binding upon you and us. And you agree that to the extent monetary or non-monetary remedy or relief is granted, such request for relief may be enforced as needed by any court of competent jurisdiction.
                    Waiver of Jury Trial. YOU AND GETSTAKES HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You and getStakes are instead electing that all claims and disputes shall be resolved by arbitration under this Arbitration Agreement, except as otherwise indicated in this Section 15. An arbitrator can award on an individual basis the same damages and relief as a court and must follow this Arbitration Agreement as a court would. However, there is no judge or jury in arbitration, and court review of an arbitration award is subject to very limited review.
                    Waiver of Class or Other Non-Individualized Relief. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A COLLECTIVE OR CLASS BASIS, ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If a decision is issued stating that applicable law precludes enforcement of any part of this subsection’s limitations as to a given claim for relief, then that claim must be severed from the arbitration and brought in the state or federal courts located in the State of Florida, County of Miami-Dade. All other claims shall be arbitrated.
                    Severability. Except as provided herein, if any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable, then such specific part or parts shall be of no force and effect and shall be severed and the remainder of the Arbitration Agreement shall continue in full force and effect.
                    Survival of Agreement. This Arbitration Agreement will survive the termination of your relationship with getStakes.
                    Modification. Notwithstanding any provision in these Terms to the contrary, we agree that if getStakes makes any future material change to this Arbitration Agreement, you may reject that change within thirty (30) days of such change becoming effective by writing to us at the following address: Stakes OC, Inc., 2195 Meridian Avenue, Miami Beach, FL 33139, Attention Legal Department.

                    Severability
                    
                    If any term, clause, or provision of these Terms is held invalid or unenforceable, then that term, clause, or provision shall be severable from the remaining Terms and will not affect the validity or enforceability of any remaining part of that term, cause, or provision, or any other terms, clause, or provision of these Terms.

                    Entire Agreement
                    
                    These Terms comprise the entire agreement between you and us relating to your access to and use of the Site, Site Content, the Software, and any NFTs you have minted, and supersede any and all prior discussions agreements, and understandings of any kind, either verbal or in writing. Except as otherwise provided herein, these Terms are intended solely for the benefit of the parties and are not intended to confer third party beneficiary rights upon any other person or entity.`}
                    </p>
                </Box>
            </TermsConditionDialog>
            <TermsConditionDialog open={memberTermsConditionOpen}
                                  onClose={() => setMemberTermsConditionOpen(false)}>
                <Box height={250}>
                    <p style={{whiteSpace: 'pre-line'}}>
                        {tokenDetails?.token?.profile?.terms_and_conditions}
                    </p>
                </Box>
            </TermsConditionDialog>

            <AlertDialog open={open} image={<img src={ListingSuccessImage} alt="Success"/>}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <strong>Membership Successfully Processed </strong>
                </Typography>
                <Box my={3} width={1}>
                    <Box width={1}> <Button style={{backgroundColor: '#041E42', width: "100%"}} variant="contained"
                                            onClick={() => onViewToken()} size="small" color="primary">View Our Restaurant Partners
                    </Button></Box>
                </Box>
            </AlertDialog>

            <AlertDialog open={stripeDialog} image={<img src={ListingSuccessImage} alt="Success"/>}>
                <Typography variant="h6" component="h2">
                    <strong>Your order is being processed! </strong>
                    <br/>
                </Typography>

                <Typography>
                    We're processing your membership now. This can take several minutes, so please don't close or
                    navigate away while it finishes.
                </Typography>
            </AlertDialog>

            <AlertDialog open={stripeFail} image={<img src={PaymentFailed} alt="Error"/>}
                         onClose={() => setStripeFail(false)} showClose={true}>
                <Typography variant="h6" component="h2">
                    <strong>Uh-oh, something went wrong</strong>
                    <br/>
                </Typography>

                <Typography>
                    Something went wrong processing your payment. We're looking into it. If you continue to experience
                    problems, contact reserveat_support@getstakes.io.
                </Typography>


            </AlertDialog>

            <Grid container spacing={2} sx={{flexDirection: {md: 'row', xs: 'column'}}}>
                <Grid item md={8} xs={12}>
                    <Box>
                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                            <Typography variant="h4" className={classes.header}>
                                <strong>Your Order</strong>
                            </Typography>
                            <Button variant="text" component={RouterLink} to={`/membership`} startIcon={<DeleteIcon/>}
                                    className={classes.cancelButton}>
                                <strong>Cancel Order</strong>
                            </Button>
                        </Box>

                        <Paper elevation={1} className={classes.summary}>
                            <Box display="flex" alignItems="center" justifyContent="flex-start">
                                {/*<img src={tokenDetails?.token?.profile?.thumbnail?.file} className={classes.thumbnail}/>*/}
                                <Box ml={2}>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography variant='h5' className={classes.header} mr={1}>
                                            <strong>{tokenDetails?.token?.profile?.title}</strong>
                                        </Typography>
                                    </Box>
                                    <Box display="flex" flexDirection={smallScreen ? 'column' : 'row'}
                                         justifyContent="flex-start">
                                        {/*<Box display="flex" alignItems="center" mr={2}>
                                            <PersonOutlineIcon className="grey-color"/>
                                            <Box ml={1}>
                                                <Typography fontWeight={500} className={'body2'} color={'#64748B'}>
                                                    {tokenDetails?.token?.business?.name}
                                                </Typography>
                                            </Box>
                                        </Box>*/}
                                        {/*<Box display="flex" alignItems="center" mr={2}>
                                            <PinDropIcon className="grey-color"/>
                                            <Box ml={1}>
                                                <Typography fontWeight={500} className={'body2'} color={'#64748B'}>
                                                    {tokenDetails?.token?.business?.city}, {tokenDetails?.token?.business?.state}
                                                </Typography>
                                            </Box>
                                        </Box>*/}
                                        {/*<Box display="flex" alignItems="center" mr={2}>
                                            <MovieIcon className="grey-color"/>
                                            <Box ml={1}>
                                                <Typography fontWeight={500} className={'body2'} color={'#64748B'}>
                                                    {tokenDetails?.token?.profile?.category.name}
                                                </Typography>
                                            </Box>
                                        </Box>*/}
                                    </Box>
                                    {/* <Box display="flex" alignItems="center" justifyContent="flex-start">
                                            <Box display="flex" marginRight="15px" alignItems="center" className={classes.dark} ><PersonOutlineIcon /> {tokenDetails?.token?.business?.name} </Box>
                                            <Box display="flex" marginRight="15px" alignItems="center" className={classes.dark}><PinDropIcon />{tokenDetails?.token?.business?.state},{tokenDetails?.token?.business?.city} </Box>
                                            <Box display="flex" alignItems="center" className={classes.dark}><MovieIcon /> {tokenDetails?.token?.profile?.category.name}</Box>
                                        </Box> */}
                                </Box>
                            </Box>

                            <Box my={2}>
                                <Divider/>
                            </Box>

                            <Grid container spacing={2} direction="row"
                                  justifyContent="center"
                                  alignItems="flex-start">
                                <Grid item md={2} className={classes.white}>
                                    <Typography variant="body1" className={classes.header}>
                                        <strong>Description:</strong>
                                    </Typography>
                                </Grid>
                                <Grid item md={10}>
                                    <Typography className={`${classes.dark} wrapword`} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(tokenDetails?.token?.profile?.description || '' )}}>
                                        {/*{tokenDetails?.token?.profile?.description}*/}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Box my={2}>
                                <Divider/>
                            </Box>

                            <Grid container spacing={2} direction="row"
                                  justifyContent="center"
                                  alignItems="flex-start">
                                <Grid item md={2} className={classes.white}>
                                    <Typography variant="body1" className={classes.header}>
                                        <strong>Terms and Conditions:</strong>
                                    </Typography>
                                </Grid>
                                <Grid item md={10}>
                                    <Typography className={`${classes.dark} wrapword`}>
                                        Please find all the specific terms and conditions for this membership at the
                                        following link:
                                    </Typography>
                                    <Typography gutterBottom variant="body1">

                                            <a
                                                href="https://reserve-dining-exp-legal.s3.amazonaws.com/membership-terms-and-conditions.html" target="_blank"
                                                rel="noopener noreferrer"> Membership Terms and Conditions</a>

                                    </Typography>
                                </Grid>
                            </Grid>

                        </Paper>
                    </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                    {!toCheckout && (
                        <Paper elevation={1} className={classes.price}>
                            <div className={classes.dark}>
                                Price
                            </div>
                            <br/>
                            <Typography gutterBottom variant="h6" component="div">
                                <strong style={{fontSize: 'small'}}> </strong>
                                <strong>{getCurrencySymbol(tokenDetails?.token?.profile?.currency)}{formatPrice(tokenPrice, tokenDetails?.token?.profile?.currency) || ''}</strong>
                            </Typography>

                            <br/>
                            <div className={classes.dark}>
                                Select Payment Method
                            </div>
                            <br/>
                            <Box mb={2}>
                                <Button variant="contained" className="w-100" onClick={() => {
                                    setToCheckout(true);
                                    setPayWithCrypto(false)
                                }}
                                        size="small" color="primary">Pay using Credit Card
                                    <KeyboardArrowRightIcon
                                        fontSize="small"/></Button>
                            </Box>
                            {/*<Box>
                                <Button variant="contained" className="w-100" onClick={() => {
                                    setToCheckout(true);
                                    setPayWithCrypto(true)
                                }}
                                        size="small" color="primary">Pay using Cryptocurrency
                                    <KeyboardArrowRightIcon
                                        fontSize="small"/></Button>
                            </Box>*/}
                        </Paper>

                    )}
                    {toCheckout && (

                        <Paper elevation={1} className={classes.price}>

                            <br/>
                            <Typography variant="h6" className={classes.header}>
                                <strong>Order Summary</strong>
                            </Typography>

                            <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
                                <div className={classes.dark}>Total</div>
                                <Box my={2} display="flex" flexDirection="column">
                                    <Typography variant="h6" component="div">
                                        <strong style={{fontSize: 'small'}}> </strong>
                                        {/*<strong> {getCurrencySymbol(tokenDetails?.token?.profile?.currency)}{parseFloat( (tokenDetails?.token?.profile?.price + tokenDetails?.token?.transaction_amount).toString() )}</strong>*/}
                                        <strong> {getCurrencySymbol(tokenDetails?.token?.profile?.currency)}{formatPrice(tokenDetails?.token?.profile?.price, tokenDetails?.token?.profile?.currency) || ''}</strong>
                                    </Typography>
                                </Box>
                            </Box>

                            <Box my={2} display="flex" alignItems="center" justifyContent="flex-start">
                                <FormControlLabel
                                    label=""
                                    control={
                                        <Checkbox
                                            onChange={(e: InputEvent) => onChange(e)}
                                            checked={termsChecked}
                                            size="small"
                                        />
                                    }
                                />
                                <Typography gutterBottom variant="body1">
                                    I accept the &nbsp;
                                    <Link variant="body1" className="hover"
                                          onClick={() => setTermsConditionOpen(true)}>
                                        terms and conditions
                                    </Link>
                                </Typography>

                            </Box>
                            {/*<Button disabled={!termsChecked || !walletConnected} variant="contained" className="w-100"*/}
                            <Button disabled={!termsChecked} variant="contained" className="w-100"
                                     onClick={() => activateStripe()} size="small" color="primary">

                                {tokenDetails?.token?.owner_info?.status === 'EXPIRED' ? 'Renew Now' : 'Buy Now'}
                                <KeyboardArrowRightIcon fontSize="small"/>
                            </Button>
                        </Paper>
                    )}

                </Grid>
            </Grid>

            <br/>
        </AppContainer>
    )
}
