import React, { useEffect, useState } from 'react';

import { Box, Button, Grid, Paper, Theme, Typography } from '@mui/material'

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PinDropIcon from '@mui/icons-material/PinDrop';
import MovieIcon from '@mui/icons-material/Movie';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import { makeStyles } from '@mui/styles';
import QRCode from "react-qr-code";
import { Link as RouterLink, useParams } from 'react-router-dom';
import { AppContainer } from '@shared/components/AppContainer';
import { getTokenAction, tokenSelector } from '@store/slices/token.slice';
import {appSelector, appUserSelector} from '@store/slices/app.slice';
import { TokenScanSkeleton } from '@shared/skeletons/TokenScanSkeleton';
import PeopleImage from '@assets/images/people.jpg';
import { environment } from '@env/environment';
import { tokenService } from '@services/token.service';
import { TOKEN_STATUS } from '@shared/constants/app.constant';
import Moment from 'react-moment';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ITokenActivation } from '@interfaces/token';
import {CCLogo} from "@shared/components/CCLogo";


const useStyles = makeStyles((theme: Theme) => ({
    white: {
        backgroundColor: theme.palette.background.paper,
    },
    dark: {
        color: '#64748B'
    },
    header: {
        marginBottom: '8px'
    },
    green: {
        color: 'green'
    },
    chip: {
        height: '20px !important', width: '100px',
        background: 'rgba(0, 159, 77, 0.1) !important',
        color: '#009F4D !important'
    },
    ccFont: {
        fontFamily: "'Share Tech Mono', monospace",
        fontSize: '28px',
        textTransform: 'uppercase'
    },
    ccFontSmall: {
        fontFamily: "'Share Tech Mono', monospace",
        fontSize: '16px',
        textTransform: 'uppercase'
    },

    // Animated background code from Manuel Pinto: https://codepen.io/P1N2O/pen/pyBNzX
    // -----
    paper: {
        minHeight: '400px',
        padding: '15px',
        // background: 'rgb(255,255,255) radial-gradient(circle, rgba(255,255,255,1) 75%, rgba(4,30,66,1) 100%)',
        // backgroundColor: '#041E42 !important',
        // color: '#041E42 !important',
        color: `${theme.palette.background.paper} !important`,
        // backgroundImage: `url(${PeopleImage})`,
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover'
        // background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
        background: 'linear-gradient(-45deg, #D8CEBC, #63000a, #D8CEBC)',
        backgroundSize: '250% 100%',
        animation: `$gradient 7500ms ${theme.transitions.easing.easeInOut} infinite`
        // height: '100vh'
    },

    '@keyframes gradient': {
        '0%': {
            backgroundPosition: '0% 50%'
        },
        '50%': {
            backgroundPosition: '100% 50%'
        },
        '100%': {
            backgroundPosition: '0% 50%'
        }
    },

    // ------

    qr: {
        padding: '15px'
    },
    icon: {
        fontSize: '75px !important'
    },
    container: {
        display: 'flex',
        minHeight: '300px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: "center",
        textAlign: 'center',
        margin: '10px 2px',
    }
}));

export const CustomerScanToken = () => {
    const classes = useStyles();
    const appDetail = useAppSelector(appSelector);
    let { id } = useParams<{ id: string }>();
    const [scanContent, setScanContent] = useState('')
    const tokenDetails = useAppSelector(tokenSelector);
    const user = useAppSelector(appUserSelector);
    const [isActivated, setIsActivated] = useState(false)
    const [state, setState] = useState({} as ITokenActivation)
    const dispatch = useAppDispatch();

    const setData = async () => {
        console.log(tokenDetails);
        if (id) {
            const response = await tokenService.findActivationDetail(id);
            if (response?.data?.data) {
                dispatch(getTokenAction(response?.data?.data?.token_id))
            }
            if (response?.data?.data?.status === TOKEN_STATUS.ACTIVE) {
                setIsActivated(true);
                setState(response?.data?.data)
                return
            }
            setIsActivated(false)
            setScanContent(`${environment.APP_BUSINESS_URL}/tokens/${id}/activate`)
        }
    }
    useEffect(() => {
        setData()

    }, [])

    const breadcrumbs = [
        <Link key="1" component={RouterLink} to="/tokens" color="inherit" href="/" >
            My Memberships
        </Link>,
        <Link underline="hover" key="1" color="inherit"  >
            {tokenDetails?.token?.profile?.title}
        </Link>,
    ];

    /*const dateFormatter = (announcementDate: any) => {
        Moment.locale('en');
        return (Moment(announcementDate).format('MMMM Do YYYY, h:mm a'))
    };*/

    return (
        <AppContainer>
            {appDetail?.loading && (
                <TokenScanSkeleton />
            )}
            {!appDetail?.loading && (
                <>
                    <Grid container justifyContent="center" direction="row" sx={{ padding: '20px 0px' }} >
                        <Grid item md={8} xs={12}>
                            <Paper elevation={8} className={classes.paper}>
                                <Box my={2} display="flex" justifyContent="center">
                                    {/*<Typography variant="h5" className={classes.ccFont}>
                                        <strong>{tokenDetails?.token?.profile?.title}</strong>
                                    </Typography>*/}
                                </Box>
                                <Box display="flex"
                                    sx={{
                                        flexDirection: {
                                            xs: 'column',
                                            sm: 'row'
                                        },
                                        alignItems: {
                                            xs: 'flexStart',
                                            sm: 'center'
                                        },

                                    }}
                                    justifyContent="center"
                                >
                                    {/*<Box className={classes.ccFont} display="flex" alignItems="center" sx={{ mb: { xs: 1, sm: 0 } }} mr={2}>{user?.first_name} {user?.last_name}</Box>*/}
                                    {/*<Box display="flex" alignItems="center" sx={{ mb: { xs: 1, sm: 0 } }} mr={2}><PinDropIcon /> &nbsp;{tokenDetails?.token?.created_at} </Box>*/}
                                    {/*<Box display="flex" alignItems="center"><MovieIcon /> &nbsp; {tokenDetails?.token?.profile?.category?.name}</Box>*/}
                                </Box>
                                {isActivated && (
                                    <Box my={2}>
                                        <Grid container justifyContent="center" direction="row">
                                            <Grid item md={6} xs={12}>
                                                <Paper elevation={8} className={classes.container} >
                                                    <CheckCircleIcon className={classes.icon} />

                                                    <br /><br />
                                                    <Typography variant="h6" className={`${classes.header}`}>
                                                        <> <strong>{`${tokenDetails?.token?.profile?.title} `}</strong> has been activated   </>
                                                    </Typography>
                                                    {isActivated && (
                                                        <>
                                                            <br />
                                                            <Typography variant="caption" >
                                                                <strong>Activated at:</strong>   <Moment format="MMM Do, y hh:mm:ss">{state?.activated_at}</Moment>
                                                            </Typography>
                                                            {/*<Typography variant="caption" >
                                                                <strong>Activation End date:</strong>   <Moment format="MMM Do, y hh:mm:ss">{state?.activation_end_at}</Moment>
                                                            </Typography><br />*/}
                                                        </>
                                                    )}
                                                </Paper>
                                                <br />
                                                <Box display="flex" justifyContent="center" width={1}>
                                                    <Button variant="outlined" style={{ color: 'white', borderColor: 'white' }} className="w-100" component={RouterLink} to="/tokens" color="primary" >
                                                        Back to dashboard
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                )}
                                {!isActivated && (
                                    <Box>
                                        <Box my={4} display="flex" alignItems="center" justifyContent="center">
                                            <Paper elevation={8} className={classes.qr}>
                                                <QRCode value={scanContent} />
                                            </Paper>

                                        </Box>
                                        {/*<Box display="flex" justifyContent="center">
                                            <Typography variant="h5" className={classes.header}>
                                                <strong>Scan to activate Membership</strong>
                                            </Typography>
                                        </Box>*/}
                                        <Grid container justifyContent="center" direction="row" sx={{ padding: '20px 0px' }} >
                                            <Grid item md={6} xs={6}>
                                                <Box className={classes.ccFont} display="flex" alignItems="center" sx={{ mb: { xs: 1, sm: 0 } }} ml={2}>{user?.first_name} {user?.last_name}</Box>
                                                <Box className={classes.ccFontSmall} display="flex" alignItems="center" sx={{ mb: { xs: 1, sm: 0 } }} ml={2}>
                                                    Member Since: &nbsp;<Moment format="MM/DD/YYYY">{tokenDetails?.token?.created_at}</Moment>
                                                </Box>
                                            </Grid>
                                            <Grid item md={6} xs={6} >
                                                <Box className={classes.ccFont} display="flex" justifyContent="right" sx={{ mb: { xs: 1, sm: 0 } }} ml={2}>
                                                    <CCLogo />
                                                </Box>
                                            </Grid>
                                        </Grid>

                                        <Box display="flex" justifyContent="center">
                                            <Typography variant="caption" >
                                                <strong>Generated at:</strong>   <Moment format="MMM Do, y hh:mm:ss">{new Date()}</Moment>
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </>
            )}

        </AppContainer>
    )
}
