import { Checkbox, FormControlLabel } from '@mui/material'
import { Controller } from 'react-hook-form'

type FieldProps = {
    control: any;
    name: any;
    disabled?: boolean;
    checked?: boolean;
    onChange?: any;
    onBlur?: any;
    label?: string;
    value?: any;
}
export const UseFormCheckBox = ({ control, name, disabled = false, onBlur, onChange, label, checked, value  }: FieldProps) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error } }) => (
                <FormControlLabel
                    label={label}
                    control={
                        <Checkbox
                        onChange={onChange}
                            onBlur={onBlur} 
                            checked={checked}
                            value={value}
                        />
                    }
                />


            )}
        />
    )
}
