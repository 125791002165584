const styles = (theme) => {
    return {
        grow: {
            flexGrow: 1,
            [theme.breakpoints.down('md')]: {//places navbar to bottom on breakpoint
                position: 'fixed',
                zIndex: 2,
                bottom: "0%",
                opacity: 1,
                width: "100%",

            }
        },
        toolbar: {
            display: 'flex',
            padding: '0px',
            justifyContent: ' space-between',
            width: '100%',
            margin: '0 auto',
            maxWidth: '1440px',
            [theme.breakpoints.down('md')]: {//places navbar to bottom on breakpoint
                justifyContent: 'center'
            }
        },
        mobilenav: {
          
            [theme.breakpoints.down('md')]: {//places navbar to bottom on breakpoint
                // width: '100%',
                overflowX: 'auto'
            },
            '& a > div': {
                paddingLeft: '12px !important',
                paddingRight: '12px !important'
            }
        },
        menu: {
            // fontSize: '14px !important',
            // fontSize: '16px !important',

            [theme.breakpoints.down('md')]: {
                fontSize: '8px !important',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '12px !important',
            },

            lineHeight: '18px !important',
            // color: '#64748B !important',
            color: '#3B2316 !important',
            letterSpacing: '0.0168em !important',
            marginLeft: '0 !important'
        },
        appbar: {
            // color: '#041E42',
            color: '#3B2316',
            // backgroundColor: '#fff !important'
            backgroundColor: '#D8CEBC !important'
        },
        link: {
            color: 'inherit',
            textDecoration: 'inherit',
            marginLeft: '10px',
        },
        userName: {
            color: '#64748B !important',
            fontStyle: 'normal',
            fontSize: '14px !important',
            lineHeight: '18px !important',
            letterSpacing: '0.005em'
        },
        sectionDesktop: {
            alignItems: 'center',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
            color: 'grey'
        },
        typography: {
            minHeight: '4rem',
            alignItems: 'center',
            display: 'flex',
            textDecoration: 'none',
            color: 'grey'
        },
        selected: {
            borderBottom: '2px solid #009F4D',
            color: theme.palette.primary.dark
        },
        grey: {
            color: 'grey'
        }
    }

}

export default styles;
