import { Box, Grid, Typography } from '@mui/material'

export const AppNoToken = ({ icon, title, body, children, height='300px' }: { height?: string, title: string, body: string, icon?: React.ReactNode, children: React.ReactNode }) => {
    return (
        <Box display="flex" flexDirection="column" maxHeight="400px" height={height} textAlign="center" alignItems="center" justifyContent="center">
            
            <br/>
            {icon}
            <Typography variant="h5" >
                {title}
            </Typography>
            <Typography variant="body1" >
                {body}
            </Typography>
           
            <Box mt={2}>
                {children}
            </Box>
        </Box>
    )
}
