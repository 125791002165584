import {Redirect, Route, useHistory} from "react-router-dom";
import React from 'react'
import { useAppSelector } from "@store/hooks";
import { appUserSelector } from "@store/slices/app.slice";

export const CommonPrivateRoute = ({component, isAuthenticated, ...rest}: any) => {
    const appUserDetails = useAppSelector(appUserSelector)
    const history = useHistory();

    const routeComponent = (props: any) => (
        appUserDetails && Object.keys(appUserDetails).length > 0
            ? React.createElement(component, props)
             : <Redirect to={{pathname: `/auth/login/?returnUrl=${window.location.pathname}`}}/>
            // : history.push(`/auth/login/?returnUrl=${window.location.pathname}`)
    );
    return <Route {...rest} render={routeComponent}/>;
};