import {
    Switch,
    Route,
    Redirect,
    Link,
} from "react-router-dom";
import Navbar from '@layout/navbar/Navbar';

import { MarketPlace } from './market-place/MarketPlace';

import { Announcement } from './announcement/Announcement';
import { ViewAnnouncement } from './announcement/ViewAnnouncement';
import { CreateAnnouncement } from './announcement/CreateAnnouncement';

import Footer from '@layout/footer/Footer';
import { BusinessCreateToken } from './business/tokens/views/BusinessCreateToken';

import { Settings } from './settings/Settings';

import { CustomerRoute } from '@shared/components/CustomerRoute';
import { BusinessRoute } from '@shared/components/BusinessRoute';

import { SearchTokens } from './search-token/SearchToken';
import { BrowseToken } from './market-place/BrowseToken';
import { CustomerScanToken } from "./customer/tokens/views/ScanToken";
import { BusinessTokens } from "./business/tokens/views/BusinessTokens";
import { CustomerTokens } from "./customer/tokens/views/CustomerTokens";
import { CustomerBuyToken } from "./customer/tokens/views/BuyToken";
import { isBusiness, isCustomer } from "@shared/helpers/global.helper";
import { BusinessToken } from "./business/tokens/views/BusinessToken";
import { BusinessTokenPreview } from "./business/tokens/views/CreateTokenPreview";
import { CustomerToken } from "./customer/tokens/views/CustomerToken";
import { BusinessPage } from "./common/views/BusinessPage";
import { CommonPrivateRoute } from "@shared/components/CommonPrivateRoute";
import { PublicTokenOverview } from "./common/views/PublicTokenOverview";
import { HelpCenter } from "./common/views/HelpCenter";
import { Box } from "@mui/system";
import { BusinessActivateToken } from "./business/tokens/views/ActivateToken";
/*
import { PaymentAlert } from "@shared/components/PaymentAlert";
*/
import { useAppSelector } from '@store/hooks';
import { appUserSelector } from '@store/slices/app.slice';
import { VerificationAlert } from "@shared/components/VerificationAlert";
import {RestaurantOverview} from "./common/views/RestaurantOverview";
import {RestaurantList} from "./customer/tokens/views/RestaurantList";

export const Features = () => {
    const appUser = useAppSelector(appUserSelector);
    const isVerified = false
    return (
        <>
            <Navbar />

            <Box minHeight="650px">
                <Switch>
                    <Route path="/" exact render={() => (
                        <Redirect
                            to={{
                                pathname: "/membership",
                            }}
                        />
                    )} />
                    <Route path="/restaurant" component={RestaurantOverview} />
                    <Route path="/membership" exact={true} component={PublicTokenOverview} />
                    <Route path="/restaurant-list" exact={true} component={RestaurantList} />

                    <CommonPrivateRoute path="/announcements" component={Announcement} />
                    <CommonPrivateRoute path="/view-announcement/:announcementId" component={ViewAnnouncement} />
                    <CommonPrivateRoute path="/announcement/create" component={CreateAnnouncement} />
                    <CommonPrivateRoute path="/announcement/edit/:announcementId" component={CreateAnnouncement} />
                    <CommonPrivateRoute path="/settings" component={Settings} />
                    
                    {/*<CommonPrivateRoute path="/help-center" component={HelpCenter} />*/}
                    {/*<CommonPrivateRoute path="/business-page/:id" component={BusinessPage} />*/}
                    {isBusiness() && (
                        <>
                            <Switch>
                                <BusinessRoute path="/tokens" exact={true} component={BusinessTokens} />
                                {/*<BusinessRoute path="/public/token/:id" exact={true} component={BusinessToken} />*/}
                                {/*<BusinessRoute path="/tokens/:id" exact={true} component={BusinessToken} />*/}
                                <BusinessRoute path="/token/create" exact={true} component={BusinessCreateToken} />
                                <BusinessRoute path="/token/edit/:id" exact={true} component={BusinessCreateToken} />
                                <BusinessRoute path="/token/confirmation/:id" component={BusinessTokenPreview} />
                                <BusinessRoute path="/tokens/:id/activate" component={BusinessActivateToken} />
                                <Route path="*" render={() => (
                                    <Redirect
                                        to={{
                                            pathname: "/auth/login",
                                        }}
                                    />
                                )} />
                            </Switch>
                        </>
                    )}

                    {isCustomer() && (
                        <>
                            <Switch>

                                {/*<Route path="/marketplace" component={MarketPlace} />*/}
                                {/*<Route path="/browse-token/:id" component={BrowseToken} />*/}
                                {/*<Route path="/public/token/:id" exact={true} component={PublicTokenOverview} />*/}
                                {/*<Route path="/membership/buy" exact={true} component={CustomerBuyToken} />*/}
                                {/*<Route path="/tokens/:id/buy" exact={true} component={CustomerBuyToken} />*/}
                                {/*<Route path="/search" component={SearchTokens} />*/}
                                {/*<CustomerRoute path="/tokens" exact={true} component={CustomerTokens} />*/}
                                {/*<CustomerRoute path="/tokens/:id/sell" component={CustomerSellToken} />*/}
                                <CustomerRoute path="/tokens/:id/scan" component={CustomerScanToken} />
                                {/*<CustomerRoute path="/tokens/:id" exact={true} component={CustomerToken} />*/}
                                {/*<CustomerRoute path="/tokens/:id/buy" exact={true} component={CustomerBuyToken} />*/}
                                <CustomerRoute path="/membership/buy" exact={true} component={CustomerBuyToken} />
                                <Route path="*" render={() => (
                                    <Redirect
                                        to={{
                                            pathname: "/membership",
                                            // pathname: "/marketplace",
                                            // pathname: "/tokens",
                                        }}
                                    />
                                )} />
                            </Switch>
                        </>
                    )}

                </Switch>
            </Box>
            <Footer />
        </>
    )
}
