import { Box, Button} from '@mui/material'
import { Link, useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {saveDetail, signUpAction, signupUserSelector} from '@store/slices/auth.slice';
import { BusinessProfile } from '@shared/components/BusinessProfile';
import { Business } from '@interfaces/register';
import {useState} from "react";
import {NotificationModel} from "@interfaces/notifications";
import {useQuery} from "@shared/helpers/global.helper";

export const RegisterBusinessProfile = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const user = useAppSelector(signupUserSelector)
    const query = useQuery();
    let returnUrl = query.get("returnUrl") ? `/?returnUrl=${query.get("returnUrl")}` : '';

    // Hack to get around the fact that we're skipping the notification section
    const [notificationForm, setProfileForm] = useState<NotificationModel>({
        is_sold_token_email_notify: false,
        is_purchased_token_email_notify: false,
        is_redeemed_token_email_notify: false,
    })

    const onSubmit = (data: any) => {
        delete data.cover_updated;
        delete data.logo_updated;
        let business = { ...user.business, ...data };
        dispatch(saveDetail({ business: business }));
        dispatch(saveDetail({ ...user/*, paymentSkipped: false*/, ...notificationForm }));
        // console.log({...user, ...{business: business}, ...notificationForm});
        history.push('/auth/signup/profile');
        dispatch(signUpAction({ model: {...user, ...{business: business}, ...notificationForm}, history, path: `/auth/signup/success${returnUrl}` }));
    }

    const saveBusiness = async (data: any) => {
        await dispatch(saveDetail({ business: { ...user.business, ...data } }));
    }

    const saveNotification = async (notificationForm: any) => {
        await dispatch(saveDetail({ ...user/*, paymentSkipped: false*/, ...notificationForm }));
    }

    return (
        <Box sx={{ minWidth: { md: '600px' } }}>
            <BusinessProfile profile={user.business as Business} submit={onSubmit}>
                <Box width={1} py={2} className="flex-justify-center" sx={{ flexDirection: { xs: 'column', md: 'row-reverse' } }}>
                    <Button variant="contained" className="w-100" color="primary" type="submit">
                        Continue
                    </Button>&nbsp;&nbsp;
                    <Button color="primary" variant="outlined" className="w-100" component={Link} to={`/auth/signup/phone`}>
                        Back
                    </Button>
                </Box>
            </BusinessProfile>
        </Box>
    )
}
