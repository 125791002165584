import { Box, Button, Grid, Paper, Typography, } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@store/hooks';
import { saveDetail, signupUserSelector } from '@store/slices/auth.slice';
import { adressSchema } from '@auth/auth.schema';
import { UseFormTextField } from '@shared/components/UseFormTextField';
import {showContentByENV, useQuery} from '@shared/helpers/global.helper';
import styles from '@auth/styles';
import { UseFormSelect } from '@shared/components/UseFormSelect';
import { STATES_LIST } from '@shared/constants/app.constant';

const useStyles = makeStyles(styles as any);

export const Address = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const user = useAppSelector(signupUserSelector)
    const history = useHistory();
    const query = useQuery();
    let returnUrl = query.get("returnUrl") ? `/?returnUrl=${query.get("returnUrl")}` : '';

    const { control, handleSubmit } = useForm({
        resolver: yupResolver(adressSchema),
    });

    const onSubmit = (event: any) => {
        dispatch(saveDetail({ address1: event.address1, address2: event.address2, state: event.state, city: event.city, zip: event.zip }));
        history.push(`/auth/signup/phone${returnUrl}`)
    }

    return (
        <div className={classes.boxDimension}>
            <Typography variant="h6" className={classes.title} gutterBottom>
                Please add your {showContentByENV('residential', 'business')}  address
            </Typography>
            <Box display="flex" width="100%" justifyContent="center">
                <Typography variant="caption" className={classes.subtitle} >
                    We’re required by law to collect this information
                </Typography>

            </Box>
            <Box mt={4} width="100%">
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <Grid container justifyContent="center" direction="column" spacing={2}>
                        <Grid item>
                            <UseFormTextField control={control} name="address1" placeholder="Address 1" defaultValue={user.address1} />
                        </Grid>
                        <Grid item>
                            <UseFormTextField control={control} name="address2" placeholder="Address 2" defaultValue={user.address2} />
                        </Grid>
                        <Grid item>
                            <UseFormTextField control={control} name="city" placeholder="City" defaultValue={user.city} />
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item md={6}>
                                <UseFormSelect defaultValue={user.state} control={control} name="state" placeholder="State" items={STATES_LIST} />
                            </Grid>
                            <Grid item md={6}>
                                <UseFormTextField control={control} name="zip" placeholder="Zip" defaultValue={user.zip} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box width={1} py={2} className="flex-justify-center" sx={{ flexDirection: { xs: 'column', md: 'row-reverse' } }}>
                        <Button className="w-100" variant="contained" color="primary" type="submit">
                            Continue
                        </Button> &nbsp;&nbsp;
                        <Button className="w-100" color="primary" variant="outlined" component={Link} to={`/auth/signup/fullname`}>
                            Back
                        </Button>
                    </Box>
                </form>
            </Box>
        </div>
    )
}
