import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useAppSelector } from '@store/hooks';
import { signupUserSelector } from '@store/slices/auth.slice';
import { AppLogo } from '@shared/components/Logo';
import {isCustomer, isBusiness, useQuery} from '@shared/helpers/global.helper';
import { Phone } from './Phone';
//import { PaymentMethod } from './PaymentMethod';
import { Address } from './Address';
//import { PaymentDetails } from './PaymentDetails';
import { FullName } from './FullName';
import { Success } from './Success';
import { Register } from './Register';
import styles from '@auth/styles';
import { useEffect } from 'react';
import { RegisterBusinessProfile } from './BusinessProfile';
import { Notifications } from './Notifications';

const useStyles = makeStyles(styles as any);

export const RegisterHome = () => {
  const classes = useStyles();
  let { url } = useRouteMatch();
  const user = useAppSelector(signupUserSelector)
  const history = useHistory();
  const query = useQuery();
  let returnUrl = query.get("returnUrl") ? `/?returnUrl=${query.get("returnUrl")}` : '';


  useEffect(() => {
    if (!user || Object.keys(user).length == 0) {
      // history.push(returnUrl ? `/auth/signup/?returnUrl=${returnUrl}` : '/auth/signup/' );
      history.push(`/auth/signup${returnUrl}`);
    }
  }, [])

  return (
    <div>
      <Box display="flex" sx={{ justifyContent: { xs: 'center', md: 'space-between' } }} px={4} pt={3}>
        <AppLogo />
      </Box>
      <div className={classes.root}>
        <Box className={classes.paper}
          sx={{
            bgcolor: 'background.paper',
            boxShadow: { xs: 0, md: 10 },
            borderRadius: 1,
            textAlign: 'center'
          }} >

          <Switch>
            <Route exact path={`${url}`}>
              <Register />
            </Route>
            <Route path={`${url}/phone`}>
              <Phone />
            </Route>
            <Route path={`${url}/success`}>
              <Success />
            </Route>
            <Route path={`${url}/fullname`}>
              <FullName />
            </Route>
            <Route path={`${url}/address`}>
              <Address />
            </Route>
            <Route path={`${url}/notifications`}>
              <Notifications />
            </Route>

            {isBusiness() && (
              <>
                {/* <Route path={`${url}/business-name`}>
              <BusinessName />
            </Route> */}
                <Route path={`${url}/profile`}>
                  <RegisterBusinessProfile />
                </Route>

              </>
            )}
            {/*{isCustomer() && (
              <>
                <Route path={`${url}/payment-method`}>
                  <PaymentMethod />
                </Route>
                <Route path={`${url}/payment-details`}>
                  <PaymentDetails />
                </Route>
              </>
            )}*/}
          </Switch>
        </Box>
      </div>
    </div>
  )
}
