import { Button, Fade, Menu, MenuItem } from '@mui/material';
import React from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export const TextFieldAdornment = ({ value, select, items, disabled = false }: { value: string, select: Function, items: Array<String>, disabled?: boolean }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openDropdown = Boolean(anchorEl);

    const openMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <div>
            <Button
                disabled={disabled}
                id="fade-button"
                aria-controls="fade-menu"
                aria-haspopup="true"
                aria-expanded={openDropdown ? 'true' : undefined}
                onClick={openMenu}
            >
                {value} {openDropdown ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Button>
            <Menu
                id="fade-menu-fp"
                anchorEl={anchorEl}
                open={openDropdown}
                onClose={(event: any) => setAnchorEl(null)}
                TransitionComponent={Fade}
            >
                {items.map(value => (
                    <div key={Math.random()}><MenuItem onClick={() => { select(value); setAnchorEl(null) }}>{value}</MenuItem></div>
                ))}
            </Menu>
        </div>
    )
}
