import { environment } from "@env/environment";
import { AppType, CURRENCIES, CURRENCY, MESSAGES } from "../constants/app.constant"
import getSymbolFromCurrency from 'currency-symbol-map'
import {useLocation} from "react-router-dom";

export const ExtractErrorMessage = (error: any) => {
    if (Array.isArray(error) && error.length > 0) {
        return error[0].message;
    } else if (error?.message) {
        return error.message
    } else {
        return MESSAGES.DEFAULT_ERROR;
    }
}

export const isCustomer = () => {
    return environment.APP_TYPE === AppType.Customer;
}
export const isBusiness = () => {
    return environment.APP_TYPE === AppType.Business;
}
export const appIsMobile = () => {
    return environment.APP_IS_MOBILE;
}

export const showContentByENV = (customerContent: string, businessContent: string) => {
    if (isCustomer()) return customerContent;
    if (isBusiness()) return businessContent;
}

export const formatPrice = (price: number, currency: string) => {
    if(price) {
        if (currency === CURRENCY.USD) {
            return parseFloat(price.toString()).toFixed(2)
        }
        return parseFloat(price.toString());
    } else {
        return null;
    }

}

export const readFileAsDataURL = async (file: any) => {
    let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
    });
    return result_base64;
}

export const parseJSON = (data: string) => {
    try {
        if(data) {
            return JSON.parse(data)
        } else {
            return [];
        }
    } catch (e) {
        if (e instanceof SyntaxError) {
            console.log(e)
        } else {
            console.log(e)
        }
        return []
    }
}

export const getCurrencySymbol = (currency: string) => {
    return getSymbolFromCurrency(currency)
}

export const getOtherCurrency = (currency: string) => {
    const currencies = CURRENCIES.filter(x => x !== currency);
    return currencies && currencies.length > 0 ? currencies[0] : '';
}

export const validateFileSize = (files: Array<any>) => {
    let flag = true;
    Array.from(files).forEach((item: any) => {
        const fileSize = item.size / 1024 / 1024;
        if (fileSize > 10 && flag) {
            flag = false;
        }
    })
    return flag;
}

export const ImageToDataUri = (base64Str: any, maxWidth = 400, maxHeight = 350) => {
    return new Promise((resolve) => {
        let img = new Image()
        img.src = base64Str
        img.onload = () => {
            let canvas = document.createElement('canvas')
            const MAX_WIDTH = maxWidth
            const MAX_HEIGHT = maxHeight
            let width = img.width
            let height = img.height

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width
                    width = MAX_WIDTH
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height
                    height = MAX_HEIGHT
                }
            }
            canvas.width = width
            canvas.height = height
            let ctx = canvas.getContext('2d')
            ctx?.drawImage(img, 0, 0, width, height)
            resolve(canvas.toDataURL())
        }
    })
}

export const greetUser = () => {
    var today = new Date()
    var curHr = today.getHours()
    if (curHr < 12) {
        return 'Good Morning'
    } else if (curHr < 18) {
        return 'Good Afternoon'
    } else {
        return 'Good Evening'
    }
}

export const deepClone = (data: any) => {
    return JSON.parse(JSON.stringify(data));
}
export const unmaskMobile = (value: string) => {
    return value.replaceAll(' ', '').replace('(', '').replace(')', '').replaceAll('_', '')
}

export const validateURL = (url?: string) => {
    return url?.replaceAll("https://", "");
}
export const prependHTTPS = (url: string) => {
    return `https://${validateURL(url)}`
}

export const currencyConversion = (type: string, value: number, baseValue: number) => {
    value = isNaN(value) ? 0 : value;
    if (type == CURRENCY.USD)
        return (Number(value) / Number(baseValue)).toFixed(3)
    else
        return (Number(value) * Number(baseValue)).toFixed(3)
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}
