import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button, Divider,
    FormControl, FormHelperText,
    Grid, InputLabel,
    Link, ListItem, MenuItem, Select, TextField,
    Typography
} from '@mui/material'
import { Link as RouterLink, useParams } from 'react-router-dom';
import { AppContainer } from '@shared/components/AppContainer';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getMyTokensAction, getTokenAction, resetToken, tokenSelector } from '@store/slices/token.slice';
import React, { useEffect, useState } from 'react';
import {environment} from "@env/environment";
import DOMPurify from "dompurify";
import {parseJSON} from "@shared/helpers/global.helper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from "@mui/material/styles";
import {commonSelector, getAnnouncement, getRestaurantList} from "@store/slices/common.slice";
import { commonService} from "@services/common.service";
import moment from "moment";
import {appUserProfileSelector, getProfileAction} from "@store/slices/app.slice";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import Stack from '@mui/material/Stack';

export const RestaurantList = () => {

    const dispatch = useAppDispatch();
    const tokenDetails = useAppSelector(tokenSelector);
    const theme = useTheme();
    const [buttonSize, setButtonSize] = useState('medium');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [size, setSize] = useState('2');
    const [reservationMap, setReservationMap] = useState({});
    const [hasSearched, setHasSearched] = useState(false);
    const userProfile = useAppSelector(appUserProfileSelector);
    const [userDateError, setUserDateError] = useState(false)
    const [userTimeError, setUserTimeError] = useState(false)

    const commonSlice = useAppSelector(commonSelector);

    useEffect(() => {
        dispatch(getRestaurantList())
        if (!userProfile || Object.keys(userProfile).length === 0) {
            dispatch(getProfileAction())
        }
    }, [])

    const isMember = (tokenId: string) => {
        let isOwner = false;
        isOwner = tokenDetails?.myTokens?.data?.some((userToken) => {
            return parseInt(userToken.parent_id) === parseInt(tokenId);
        });

        return isOwner;
    }

    const onChangeVal = (e: any) => {
       if(e.id === 'res-date') {
           setDate(e.value);
           console.log(date);
       }

    }

    const onChangeSelect = (element: string, e: any) => {
        // console.log(element, "", e)
        if(element === 'res-time') {
            setTime(e.value);
        }

        if(element === 'res-party-size') {
            setSize(e.value);
        }
        // console.log(date, "|", time, "|", size)
    }

    const fetchReservations = async () => {
        if(isMember(environment.DEFAULT_RESERVEAT_TOKEN_ID)){
            let allValuesSupplied = true;
            if( date.length < 1) {
                setUserDateError(true);
                allValuesSupplied = false;
            }  else if(userDateError) {
                setUserDateError(false);
            }

            if( time.length < 1) {
                setUserTimeError(true);
                allValuesSupplied = false;
            } else if(userTimeError) {
                setUserTimeError(false);
            }
            
            if(allValuesSupplied) {
                let dateTime = `${date}T${time}`
                let response = await commonService.getReservations({date_time: dateTime, party_size: size});
                let map = response.data;
                setReservationMap(map.data);
                setHasSearched(true);
                setUserDateError(false);
                setUserTimeError(false);
            }

        }
        // console.log(reservationMap)
    };

    const displayTimes = (timeMap: {string: string}, restaurantId: string ) => {
        const elements = [];

        for(let time in timeMap) {
            elements.push( <Button sx={{marginLeft: '2px', marginRight: '2px', marginBottom: '2px'}} variant="contained" onClick={() => clickOut(restaurantId, time)} href={timeMap[time as keyof typeof timeMap]} target={"_blank"}>{time}</Button> );
        }

        return elements;
    };

    const displayDefaultButton = (restaurant: any ) => {
        let element = null;

        // TODO: move this to an ENUM
        if(restaurant.platform === 'OPENTABLE') {
            let url = `${restaurant.reservation_link}&partysize=${size}&datetime=${date}T${time}&lang=en-US&r3uid=`
            element = <Button sx={{marginLeft: '2px', marginRight: '2px', marginBottom: '2px'}} variant="contained" onClick={() => clickOut(restaurant.id, time)} href={url} target={"_blank"}>View Available Times</Button>
        }

        //TODO: check that Tock URL ends in '/'
        if(restaurant.platform === 'TOCK') {
            let url = `${restaurant.reservation_link}search?date=${date}&size=${size}&time=${time}`
            element = <Button sx={{marginLeft: '2px', marginRight: '2px', marginBottom: '2px'}} variant="contained" onClick={() => clickOut(restaurant.id, time)} href={url} target={"_blank"}>View Available Times</Button>
        }

        if(restaurant.platform === 'SEVENROOMS') {
            let url = `${restaurant.reservation_link}`
            element = <Button sx={{marginLeft: '2px', marginRight: '2px', marginBottom: '2px'}} variant="contained" onClick={() => clickOut(restaurant.id, time)} href={url} target={"_blank"}>View Available Times</Button>
        }

        if(restaurant.platform === 'RESY') {
            let url = `${restaurant.reservation_link}?date=${date}&seats=${size}&time=${time.replace(':', '')}`
            element = <Button sx={{marginLeft: '2px', marginRight: '2px', marginBottom: '2px'}} variant="contained" onClick={() => clickOut(restaurant.id, time)} href={url} target={"_blank"}>View Available Times</Button>
        }

        return element;
    };

    const displaySideButton = (restaurant: any) => {
        let element = null;
        let url = '';

        // TODO: move this to an ENUM
        if(restaurant.platform === 'OPENTABLE') {
            url = `${restaurant.reservation_link}&partysize=${size}&datetime=${date}T${time}&lang=en-US&r3uid=`
        }

        //TODO: check that Tock URL ends in '/'
        if(restaurant.platform === 'TOCK') {
            url = `${restaurant.reservation_link}search?date=${date}&size=${size}&time=${time}`
        }

        if(restaurant.platform === 'RESY') {
            url = `${restaurant.reservation_link}?date=${date}&seats=${size}&time=${time.replace(':', '')}`
        }

        // Use parenthesis around JSX that spans multiple lines
        element = (<Box sx={{ marginLeft: "auto", display:"flex" }}>
                        <Button sx={{borderRadius: '0px 0px 0 0', textAlign: 'center'}} size={'small'} onClick={() => clickOut(restaurant.id, time)} variant="contained" target={"_blank"} href={url} disabled={restaurant.status !== 'ACTIVE'} >
                            <Box sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}> View Times </Box>
                            <Box sx={{display: {xs: 'block', sm: 'block', md: 'none'}}}> View Times </Box>
                        </Button>
                    </Box>);

        return element;
    }

    const clickOut = (restaurantId: string, time: string) => {
        // console.log(restaurantId, " ", time)
        const response = commonService.postReservationActivity({
            restaurant_id: restaurantId,
            date: date,
            time: time,
            user_id: userProfile.id,
            party_size: size
        });

        // console.log(response)
    }

    const useMobileStack = () => {
        const theme = useTheme();
        return useMediaQuery(theme.breakpoints.down('sm'));
    }

    type tPlatformType = {
        [key: string]: string
    }

    const REST_RES_PLATFORM:tPlatformType = {TOCK: 'Tock', RESY: 'Resy', OPENTABLE: 'OpenTable'};

    useEffect(() => {
        dispatch(getTokenAction(environment.DEFAULT_RESERVEAT_TOKEN_ID));
    }, []);

    return (
        <AppContainer>
            <Grid container mt={0} columnSpacing={0} sx={{padding: '1px', flexDirection: {md: 'row', xs: 'column-reverse'}}}>
                <Box style={{ padding: '38px 44px', marginBottom: '20px', border: '1px solid #CBD5E1', borderRadius: '0px', background: '#FFF' }}>
                    <Box mt={2} mb={4} width={'100%'}>
                        <Typography className={'title5 text-center'} mb={4} sx={{fontSize: {xs: '24px !important', md: '32px !important'}}}>
                            Reserve Restaurants List
                        </Typography>

                        <Typography className={'body1 text-center'} mb={4}>
                            Please submit your reservation date, time, and party size and then click on the Make Reservation link next to the restaurant.
                        </Typography>

                            <Stack
                                direction={!useMobileStack() ? "row" : "column"}
                                 divider={!useMobileStack() ? <Divider orientation="vertical" flexItem /> : ''}
                                spacing={0}
                                mb={5}
                                hidden={!isMember(environment.DEFAULT_RESERVEAT_TOKEN_ID)}
                            >
                                <ListItem key={1}>
                                <TextField name="date" fullWidth
                                   disabled={!isMember(environment.DEFAULT_RESERVEAT_TOKEN_ID)}
                                    id="res-date"
                                    label="Reservation Date"
                                    type="date"
                                    required
                                    error={userDateError}
                                   helperText={userDateError ? 'Reservation Date Required' : userTimeError ? '' : ''}
                                    // defaultValue={date}
                                    // sx={{ width: 220 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => {onChangeVal(e.target);}}
                                />
                                </ListItem>

                                <ListItem key={2}>
                                    <FormControl fullWidth error={userTimeError} required>
                                    <InputLabel id="time-select-label">Preferred Time</InputLabel>
                                    <Select
                                        disabled={!isMember(environment.DEFAULT_RESERVEAT_TOKEN_ID)}
                                        labelId="time-select-label"
                                        id="res-time"
                                        label="Preferred Time"

                                        defaultValue={""}
                                        onChange={(e) => {onChangeSelect('res-time', e.target);}}
                                    >
                                            <MenuItem key={"-1"} value={""}>&nbsp;</MenuItem>
                                            {/*<MenuItem key={"0100"} value={"01:00"}>1:00 AM</MenuItem>
                                            <MenuItem key={"0130"} value={"01:30"}>1:30 AM</MenuItem>
                                            <MenuItem key={"0200"} value={"02:00"}>2:00 AM</MenuItem>
                                            <MenuItem key={"0230"} value={"02:30"}>2:30 AM</MenuItem>
                                            <MenuItem key={"0300"} value={"03:00"}>3:00 AM</MenuItem>
                                            <MenuItem key={"0330"} value={"03:30"}>3:30 AM</MenuItem>
                                            <MenuItem key={"0400"} value={"04:00"}>4:00 AM</MenuItem>
                                            <MenuItem key={"0430"} value={"04:30"}>4:30 AM</MenuItem>
                                            <MenuItem key={"0500"} value={"05:00"}>5:00 AM</MenuItem>
                                            <MenuItem key={"0530"} value={"05:30"}>5:30 AM</MenuItem>
                                            <MenuItem key={"0600"} value={"06:00"}>6:00 AM</MenuItem>
                                            <MenuItem key={"0630"} value={"06:30"}>6:30 AM</MenuItem>
                                            <MenuItem key={"0700"} value={"07:00"}>7:00 AM</MenuItem>
                                            <MenuItem key={"0730"} value={"07:30"}>7:30 AM</MenuItem>
                                            <MenuItem key={"0800"} value={"08:00"}>8:00 AM</MenuItem>
                                            <MenuItem key={"0830"} value={"08:30"}>8:30 AM</MenuItem>
                                            <MenuItem key={"0900"} value={"09:00"}>9:00 AM</MenuItem>
                                            <MenuItem key={"0930"} value={"09:30"}>9:30 AM</MenuItem>
                                            <MenuItem key={"1000"} value={"10:00"}>10:00 AM</MenuItem>
                                            <MenuItem key={"1030"} value={"10:30"}>10:30 AM</MenuItem>
                                            <MenuItem key={"1100"} value={"11:00"}>11:00 AM</MenuItem>
                                            <MenuItem key={"1130"} value={"11:30"}>11:30 AM</MenuItem>
                                            <MenuItem key={"1200"} value={"12:00"}>12:00 PM</MenuItem>
                                            <MenuItem key={"1230"} value={"12:30"}>12:30 PM</MenuItem>
                                            <MenuItem key={"1300"} value={"13:00"}>1:00 PM</MenuItem>
                                            <MenuItem key={"1330"} value={"13:30"}>1:30 PM</MenuItem>
                                            <MenuItem key={"1400"} value={"14:00"}>2:00 PM</MenuItem>
                                            <MenuItem key={"1430"} value={"14:30"}>2:30 PM</MenuItem>
                                            <MenuItem key={"1500"} value={"15:00"}>3:00 PM</MenuItem>
                                            <MenuItem key={"1530"} value={"15:30"}>3:30 PM</MenuItem>*/}
                                            <MenuItem key={"1600"} value={"16:00"}>4:00 PM</MenuItem>
                                            <MenuItem key={"1630"} value={"16:30"}>4:30 PM</MenuItem>
                                            <MenuItem key={"1700"} value={"17:00"}>5:00 PM</MenuItem>
                                            <MenuItem key={"1730"} value={"17:30"}>5:30 PM</MenuItem>
                                            <MenuItem key={"1800"} value={"18:00"}>6:00 PM</MenuItem>
                                            <MenuItem key={"1830"} value={"18:30"}>6:30 PM</MenuItem>
                                            <MenuItem key={"1900"} value={"19:00"}>7:00 PM</MenuItem>
                                            <MenuItem key={"1930"} value={"19:30"}>7:30 PM</MenuItem>
                                            <MenuItem key={"2000"} value={"20:00"}>8:00 PM</MenuItem>
                                            <MenuItem key={"2030"} value={"20:30"}>8:30 PM</MenuItem>
                                            <MenuItem key={"2100"} value={"21:00"}>9:00 PM</MenuItem>
                                            <MenuItem key={"2130"} value={"21:30"}>9:30 PM</MenuItem>
                                            <MenuItem key={"2200"} value={"22:00"}>10:00 PM</MenuItem>
                                            <MenuItem key={"2230"} value={"22:30"}>10:30 PM</MenuItem>
                                            <MenuItem key={"2300"} value={"23:00"}>11:00 PM</MenuItem>
                                            <MenuItem key={"2330"} value={"23:30"}>11:30 PM</MenuItem>
                                            {/*<MenuItem key={"0000"} value={"00:00"}>12:00 AM</MenuItem>
                                            <MenuItem key={"0030"} value={"00:30"}>12:30 AM</MenuItem>*/}
                                    </Select>
                                        {userTimeError && (
                                            <FormHelperText>Reservation Time Required</FormHelperText>
                                        )}
                                        {userDateError && !userTimeError && (
                                            <FormHelperText>&nbsp;</FormHelperText>
                                        )}
                                    </FormControl>
                                </ListItem>

                                <ListItem key={3}>
                                    <FormControl fullWidth>
                                    <InputLabel id="party-size-select-label">Party Size</InputLabel>
                                    <Select
                                        disabled={!isMember(environment.DEFAULT_RESERVEAT_TOKEN_ID)}
                                        labelId="party-size-select-label"
                                        id="res-party-size"
                                        label="Party Size"
                                        defaultValue={"2"}
                                        // error={userDateError}
                                        onChange={(e) => {onChangeSelect('res-party-size', e.target);}}
                                    >
                                        <MenuItem key={1} value={1}>1</MenuItem>
                                        <MenuItem key={2} value={2}>2</MenuItem>
                                        <MenuItem key={3} value={3}>3</MenuItem>
                                        <MenuItem key={4} value={4}>4</MenuItem>
                                        <MenuItem key={5} value={5}>5</MenuItem>
                                        <MenuItem key={6} value={6}>6</MenuItem>
                                        <MenuItem key={7} value={7}>7</MenuItem>
                                        <MenuItem key={8} value={8}>8</MenuItem>
                                    </Select>
                                        {(userDateError || userTimeError) && (
                                            <FormHelperText>&nbsp;</FormHelperText>
                                        )}
                                </FormControl>
                                </ListItem>

                                <ListItem key={4}>
                                    <Button variant="contained" disabled={!isMember(environment.DEFAULT_RESERVEAT_TOKEN_ID)} onClick={() => {fetchReservations()}}>Submit</Button>
                                </ListItem>
                            </Stack>

                        {commonSlice.restaurants?.data?.map((item: any, index) => {
                            // @ts-ignore
                            return (
                            <>
                                <Accordion sx={{boxShadow: '0',
                                    paddingBottom: '10px'}} key={index} >
                                    <Box sx={{ display: "flex" }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        key={index}
                                        sx={{
                                            // background: '#041E42',
                                            // background: '#3B2316',
                                            lineHeight: '0',
                                            borderRadius: '0px 0px 0 0',
                                            // boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);',
                                            // backgroundColor: '#e5e8ef',
                                            '& .MuiAccordionSummary-content': {
                                                margin: '0 0 !important'
                                            },
                                            flexGrow: 1,
                                            flexBasis: '200px'
                                        }}
                                    >
                                        <Typography component={'span'}>
                                            <h4>{item.name} {item.status === 'PENDING' ? ' (Coming Soon) ' : ''}</h4>
                                        </Typography>
                                        {/*<Box sx={{display: {xs: 'none', sm: 'none', md: 'block'}, paddingLeft: '25px'}}>*/}
                                        <Box sx={{paddingLeft: '25px'}}>

                                            {(hasSearched && item.platform === 'RESY' && (reservationMap.hasOwnProperty(item.id) ) &&
                                            Object.keys(reservationMap[item.id as keyof typeof reservationMap]).length > 0) ? (

                                                <Typography component={'span'}>
                                                    <h6>[{Object.keys(reservationMap[item.id as keyof typeof reservationMap]).length} Reservations Available]</h6>
                                                </Typography>

                                        /*) : hasSearched && item.platform === 'RESY' ? (
                                            <Typography component={'span'}> <h6>[No Reservations available]</h6> </Typography>*/
                                        // ) : hasSearched && item.platform !== 'RESY' ? (
                                        ) : hasSearched ? (
                                            // <Typography component={'span'}> <h6>[Click to see available times]</h6> </Typography>
                                            <></>
                                        )
                                        : (<></>)}
                                            
                                        </Box>
                                    </AccordionSummary>

                                        {(hasSearched && item.platform === 'RESY' && (reservationMap.hasOwnProperty(item.id) ) &&
                                            Object.keys(reservationMap[item.id as keyof typeof reservationMap]).length > 0) ? (
                                                <></>
                                            /*) : hasSearched && item.platform === 'RESY' && (!reservationMap.hasOwnProperty(item.id) ) ? (
                                                <Box mb={5}><Typography> No Reservations available </Typography></Box>*/
                                            // ) : hasSearched && item.platform !== 'RESY' && (!reservationMap.hasOwnProperty(item.id) ) ? (
                                        ) : hasSearched && (!reservationMap.hasOwnProperty(item.id) ) ? (
                                                displaySideButton(item)
                                        ) : (<></>)}

                                    </Box>
                                    <AccordionDetails sx={{ paddingTop: '28px', background: '#fff', borderRadius: '0 0 4px 4px' }}>

                                        {(hasSearched && item.platform === 'RESY' && (reservationMap.hasOwnProperty(item.id) ) &&
                                        Object.keys(reservationMap[item.id as keyof typeof reservationMap]).length > 0) ? (
                                            <Box mb={5}>
                                                {displayTimes(reservationMap[item.id as keyof typeof reservationMap], item.id)}
                                            </Box>
                                        /*) : hasSearched && item.platform === 'RESY' && (!reservationMap.hasOwnProperty(item.id) ) ? (
                                            <Box mb={5}><Typography> No Reservations available </Typography></Box>*/
                                        // ) : hasSearched && item.platform !== 'RESY' && (!reservationMap.hasOwnProperty(item.id) ) ? (
                                        ) : hasSearched && (!reservationMap.hasOwnProperty(item.id) ) ? (
                                            <Box mb={5}>
                                                {displayDefaultButton(item)}
                                            </Box>
                                        ) : (<></>)}

                                        {/*{!(reservationMap.hasOwnProperty(item.id)) ||
                                        Object.keys(reservationMap[item.id as keyof typeof reservationMap]).length === 0 && }*/}



                                        <Typography mb={5} fontWeight={300} className={'body1'} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.description || '') }}>
                                        </Typography>

                                        {/*{(isMember(environment.DEFAULT_RESERVEAT_TOKEN_ID)) && (
                                            <Typography className={'body1'}>
                                                <h3>Reserve Your Table for {item.name} Now - <a href={item.reservation_link} target={"_blank"}>{REST_RES_PLATFORM[item.platform]} {item.go_live_date !== null ? `(Launching ${item.go_live_date})` : ''}</a> </h3>
                                            </Typography>
                                        )}*/}

                                        {(!isMember(environment.DEFAULT_RESERVEAT_TOKEN_ID)) && (
                                            <Typography className={'body1'}>
                                                <h3>Join Reserve to view the reservation link </h3>
                                            </Typography>
                                        )}

                                        {(item.platform === 'TOCK') && (
                                            <Typography component={'span'} className={'title3a'}>
                                                <h5>Note: Please alert the host that you are a Reserve member upon arrival</h5>
                                            </Typography>
                                        )}

                                        <Typography className={'body1'}>
                                            {/*Google Maps - <a href={REST_MAPS[index]} target={"_blank"}>{item}</a>*/}
                                            Google Maps - <a href={"#"} target={"_blank"}>{item.name}</a>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                {/*<Typography fontWeight={300} className={'body1'} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(REST_DESCRIP[index] || '') }}>*/}
                                {/*<Typography fontWeight={300} className={'body1'} >
                                    <p><h2>{item}</h2></p>
                                    <p>{REST_DESCRIP[index]}</p>
                                </Typography>*/}
                            </>
                        )
                        })
                        }
                        
                    </Box>
                </Box>
            </Grid>

        </AppContainer>
    )
}
