import { InputAdornment, TextareaAutosize } from '@mui/material'
import { Controller } from 'react-hook-form'

type FieldProps = {
    control: any;
    name: any;
    placeholder?: string;
    defaultValue?: string;
    disabled?: boolean;
}
export const UseFormTextArea = ({ control, name, disabled = false, placeholder, defaultValue = '' }: FieldProps) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextareaAutosize
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    className={'body2'}
                    style={{ width: '100%', borderRadius: '9px', border: '2px solid #CBD5E1', padding: '12px', fontFamily: 'SF Pro Display', minHeight: '177px' }}
                />

            )}
        />
    )
}
