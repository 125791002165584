import {
    Box,
    Button,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from '@auth/styles';
import { saveDetail, signUpAction, signupUserSelector } from '@store/slices/auth.slice';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useHistory } from 'react-router-dom';
import { NotificationModel } from '@interfaces/notifications';
import { useState } from 'react';
import { InputEvent } from '@shared/models/input.model';
import { Notification as SharedNotifications } from '@shared/components/Notification'
import {showContentByENV, useQuery} from '@shared/helpers/global.helper';
import { environment } from "@env/environment";
import { AppType } from "@shared/constants/app.constant";
import {settingService} from "@services/setting.service";

const useStyles = makeStyles(styles as any);


export const Notifications = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const user = useAppSelector(signupUserSelector)
    const history = useHistory();
    const query = useQuery();
    let returnUrl = query.get("returnUrl") ? `/?returnUrl=${query.get("returnUrl")}` : '';
    const APP_TYPE = environment.APP_TYPE || 'CONSUMER';

    const [notificationForm, setProfileForm] = useState<NotificationModel>({
        is_sold_token_email_notify: true,
        is_purchased_token_email_notify: true,
        is_redeemed_token_email_notify: true,
    })

    const onChange = (event: InputEvent, field: string) => {
        setProfileForm((prevState: any) => ({ ...prevState, [field]: event.target.checked }));
    }

    const onToggle = (action: boolean) => {
        if(APP_TYPE === AppType.Business) {
            setProfileForm({
                ...notificationForm,
                is_sold_token_email_notify: action
            });
        } else {
            setProfileForm({
                ...notificationForm,
                is_purchased_token_email_notify: action,
                is_redeemed_token_email_notify: action
            });
        }
    }

    const notificationStatus = () => {
        if(APP_TYPE === AppType.Business) {
            return notificationForm.is_sold_token_email_notify;
        } else if(APP_TYPE === AppType.Customer){
            return notificationForm.is_purchased_token_email_notify &&
                notificationForm.is_redeemed_token_email_notify;
        }
    }
    const onSubmit = () => {
        dispatch(saveDetail({ ...user/*, paymentSkipped: false*/, ...notificationForm }));
        dispatch(signUpAction({ model: { ...user, ...notificationForm }, history, path: `/auth/signup/success${returnUrl}` }));
    }
    /*const onBack = () => {
        if (user.paymentSkipped) {
            history.push('/auth/signup/payment-method');
            return;
        }
        history.push(`/auth/signup/${showContentByENV('payment-details', 'profile')}`);
    }
    */
    return <>
        {/*<div className={classes.boxDimension}>
            <Box mb={4} className="flex-space-between">
                <Typography variant="h6"> Notifications </Typography>
                <Button variant='outlined' onClick={() => onToggle(!notificationStatus())} >{notificationStatus() ? 'Disable' : 'Enable'} All</Button>
            </Box>
            <SharedNotifications notifications={notificationForm} handleChange={onChange} role={APP_TYPE} />
            <Box width={1} py={2} className="flex-justify-center" sx={{ flexDirection: { xs: 'column', md: 'row-reverse' } }}>
                <Button className="w-100" onClick={onSubmit} variant="contained" color="primary" type="submit" >
                    Continue
                </Button>&nbsp;&nbsp;
                <Button className="w-100" color="primary" variant="outlined" onClick={onBack}   >
                    Back
                </Button>
            </Box>
        </div>*/}
    </>
}
