import { networkService } from "./network-service";

interface UpdatePassword {
    old_password: string;
    new_password: string;
}


class SettingService {
    async updatePassword(model: UpdatePassword) {
        return await networkService.put('/auth/me/send-change-password-instructions', model)
    }
    async sendVerification(model: UpdatePassword) {
        return await networkService.post('/auth/me/send-change-password-instructions', model)
    }
    async deleteAccount() {
        await networkService.delete('/auth/me/archive');
    }
    async updateNotification(model: any) {
        return await networkService.put('/auth/me', model);
    }
    async updateBusinessProfile(model: any) {
        return await networkService.put('/auth/business-profile', model);
    }
}

export const settingService = new SettingService();