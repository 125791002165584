import React from 'react'
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const LoadingModal = ({msg} : {msg : string}) => {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Modal
                open={true}>
                <Box sx={style}>
                    <Typography id="modal-modal-description" sx={{mt: 2}}>
                        {msg}
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}