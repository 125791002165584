import {Box, Button, Typography} from '@mui/material'
import { Link as RouterLink, useParams } from 'react-router-dom';
import { AppContainer } from '@shared/components/AppContainer';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getMyTokensAction, getTokenAction, resetToken, tokenSelector } from '@store/slices/token.slice';
import { useEffect, useState } from 'react';
import { TokenOverview } from '@shared/components/TokenOverview';
import { appUserProfileSelector } from '@store/slices/app.slice';
import ImportTokenDialog from '@shared/components/ImportTokenDialog';
import {environment} from "@env/environment";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Chair} from "@mui/icons-material";

export const PublicTokenOverview = () => {
    const dispatch = useAppDispatch();
    let { id } = useParams<{ id: string }>();
    const userProfile = useAppSelector(appUserProfileSelector);
    const tokenDetails = useAppSelector(tokenSelector);
    const [importTokenOpen, setImportTokenOpen] = useState(false)


    useEffect(() => {
        dispatch(resetToken())
        if (id) {
            dispatch(getTokenAction(id));
        } else {
            dispatch(getTokenAction(environment.DEFAULT_RESERVEAT_TOKEN_ID));
        }
    }, []);

    const checkForOwnership = (tokenId: string) => {
        let isOwner = false;
        isOwner = tokenDetails?.myTokens?.data?.some((userToken) => {
            return parseInt(userToken.parent_id) === parseInt(tokenId);
        });

        return isOwner;
    }

    const checkUserLoggedIn = () => {
        return (userProfile.id !== undefined && userProfile.id !== null);
    }

    return (
            <TokenOverview token={tokenDetails.token} isOwned={checkForOwnership(environment.DEFAULT_RESERVEAT_TOKEN_ID)}>

            </TokenOverview>
    )
}
