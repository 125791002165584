import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Badge, Box, Button, Table, TableHead, TableBody, TableRow, Menu, MenuItem, TableCell, ListItem, ListItemAvatar, ListItemText, Avatar, TableContainer, Chip, Typography } from '@mui/material';
import { AppContainer } from '@shared/components/AppContainer';
import { isBusiness, isCustomer } from '@shared/helpers/global.helper';
import { KebabIcon } from '@shared/components/KebabIcon';
import AnPlaceholder from '@assets/images/an-placeholder.svg';
import { Link, useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
    archiveAnnouncement,
    commonSelector,
    getAllAnnouncement,
    publishAnnouncement,
    readAnnouncement
} from '@store/slices/common.slice';
import {appUserProfileSelector, getProfileAction} from '@store/slices/app.slice';
import Moment from 'moment';
const useStyles = makeStyles((theme) => ({
    settingsRow: {
        margin: '0 auto',
        maxWidth: '780px'
    },
    tableResponsiveBox: {
        overflow: 'auto',
        paddingBottom: '25px;'
    },
    imgContainer: {
        '& > div': {
            borderRadius: '4px !important'
        }
    },
    customTable: {
        wordWrap: 'break-word',
        // tableLayout: 'fixed',
        '& td': {
            // paddingTop: '0',
            borderBottom: '0',
        },
        '& tr:hover': {
            backgroundColor: '#EEE !important',
            cursor: 'pointer'
        },
        '& th': {
            fontFamily: 'SF Pro Display !important',
            fontSize: '10px !important',
            lineHeight: '16px !important',
            letterSpacing: '0em !important',
            textAlign: 'left !important',
            color: '#000 !important',
            borderBottom: '0 !important',
            padding: '8px 16px 16px 16px'
        }
    },
}));

export const Announcement = () => {
    const classes = useStyles();
    const [tableOptionAnchorEl, setTableOptionAnchorEl] = React.useState<null | HTMLElement>(null);
    const openTableOption = Boolean(tableOptionAnchorEl);
    const [openElem, setOpenElem] = React.useState(null);
    const commonSlice = useAppSelector(commonSelector);
    const userProfile = useAppSelector(appUserProfileSelector);
    const dispatch = useAppDispatch();
    let history = useHistory();

    const handleTableOptionClick = (elem: any) => (event: React.MouseEvent<HTMLButtonElement>) => {
        setTableOptionAnchorEl(event.currentTarget);
        setOpenElem(elem);
    };
    const handleClose = () => {
        setTableOptionAnchorEl(null);
        setOpenElem(null);
    };
    const handleOpenAnnouncement = (announcementId: any) => {
        if(isCustomer()) {
            dispatch(readAnnouncement({ announcementId, history }));
        } else {
            history.push(`/view-announcement/${announcementId}`)
        }
        
        handleClose();
    }
    useEffect(() => {
        dispatch(getAllAnnouncement());
        if (!userProfile || Object.keys(userProfile).length === 0) {
            dispatch(getProfileAction())
        }
    }, []);

    const dateFormatter = (announcementDate: any) => {
        Moment.locale('en');
        return (Moment(announcementDate).format('MMMM Do YYYY, h:mm a'))
    };

    const postNow = (announcement_id: string, token_profile_id?: string) => {
        dispatch(publishAnnouncement({
            model: {
                announcement_id,
                token_profile_id
            },
            history
        })).then(() => {
            dispatch(getAllAnnouncement());
        })
        handleClose();
    };

    const archiveNow = (announcement_id: string) => {
        dispatch(archiveAnnouncement({
            model: {
                announcement_id
            },
            history
        })).then(() => {
            dispatch(getAllAnnouncement());
        })
        handleClose();
    }

    const announcementList = commonSlice.announcements?.data?.map(val => (

        // <TableRow key={val.id} className={val.announcement_user ? val.announcement_user[0].is_read ? '' : 'unread' : ''} >
        <TableRow key={val.id} >
            <TableCell scope="row" onClick={() => handleOpenAnnouncement(val.id)}>
                <ListItem alignItems="flex-start" sx={{ padding: 0 }}>
                    <ListItemAvatar className={classes.imgContainer}>
                        {val.announcement_user && !val.announcement_user[0].is_read
                            ?
                            <Badge color="secondary" variant="dot" anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                                   badgeContent=" ">
                                <Avatar alt="AnPlaceholder" src={val.token_profile?.thumbnail?.file}/>
                            </Badge>
                            :
                            <Avatar alt="AnPlaceholder" src={val.token_profile?.thumbnail?.file}/>
                        }
                    </ListItemAvatar>
                    <ListItemText primary={val.subject} />
                </ListItem>
            </TableCell>

            <TableCell align="left" onClick={() => handleOpenAnnouncement(val.id)}>
                {isCustomer() ? (
                    <>
                        {val.business?.name}
                    </>
                ) : (
                    <>
                        <ListItemText
                            primary={val.status}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        className={'body2'}
                                    >
                                        {dateFormatter(val.created_at)}
                                    </Typography>
                                    <Typography
                                        className={'body2'}
                                    >
                                        Created by {val.business?.name}
                                    </Typography>
                                </React.Fragment>
                            }
                        />
                    </>
                )}
            </TableCell>

            <TableCell align="left" onClick={() => handleOpenAnnouncement(val.id)}> {isCustomer() ? dateFormatter(val.created_at) : val.user_count} </TableCell>

            <TableCell align="center" >
                <div>
                    <Button type="button" color="primary" id="notification-dropdown"
                        style={{ display: 'inline' }}
                        aria-controls={"table-option" + val.id}
                        aria-haspopup="true"
                        aria-expanded={openTableOption ? 'true' : undefined}
                        onClick={handleTableOptionClick(val.id)}
                    >
                        <KebabIcon />
                    </Button>
                    <Menu
                        id={"table-option" + val.id}
                        anchorEl={tableOptionAnchorEl}
                        open={openElem === val.id}
                        // open={openTableOption}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'notification-dropdown',
                        }}

                    >
                        {isCustomer() ?

                            [
                                // <MenuItem sx={{ paddingBottom: '12px' }}  onClick={() => handleOpenAnnouncement(val.id)}>
                                <MenuItem sx={{ paddingBottom: '12px' }}  onClick={() => archiveNow(val.id)}>

                                    <Typography
                                        className={'body3'}
                                    >

                                        Archive Announcement
                                    </Typography>

                                </MenuItem>
                            ]

                            :
                            [
                                <div>
                                    {val.status === "ACTIVE" ?
                                        ''
                                        :
                                        <MenuItem sx={{ paddingBottom: '12px' }} onClick={() => postNow(val.id, val.token_profile_id)}>
                                            <Typography
                                                className={'body3'}

                                            >
                                                Send now
                                            </Typography>

                                        </MenuItem>
                                    }
                                    <MenuItem sx={{ paddingBottom: '12px' }} component={Link} to={`/view-announcement/${val.id}`} onClick={handleClose}>
                                        <Typography
                                            className={'body3'}
                                        >
                                            View Stats
                                        </Typography>
                                    </MenuItem>
                                    {val.status === "ACTIVE" ?
                                        ''
                                        :
                                        <MenuItem sx={{paddingBottom: '12px'}} component={Link}
                                                  to={`/announcement/edit/${val.id}`} onClick={handleClose}>
                                            <Typography
                                                className={'body3'}
                                            >
                                                Edit
                                            </Typography>
                                        </MenuItem>
                                    }
                                    <MenuItem sx={{ paddingBottom: '12px' }} onClick={() => archiveNow(val.id)}>
                                        <Typography
                                            className={'body3'}
                                        >
                                            Archive
                                        </Typography>
                                    </MenuItem>
                                    {/*<MenuItem sx={{ paddingBottom: '12px' }} onClick={handleClose}>
                                        <Typography
                                            className={'body3'}
                                        >
                                            Clone
                                        </Typography>
                                    </MenuItem>*/}
                                </div>
                            ]
                        }


                    </Menu>
                </div>
            </TableCell>
        </TableRow >
    ));
    return <AppContainer>
        <Box my={5} pt={4} className="flex-space-between">
            <Typography variant="h6">Announcement</Typography>
            {isBusiness() && (
                <Link to="/announcement/create" style={{ textDecoration: 'none' }}>
                    <Button variant='outlined'>Create Announcement</Button>
                </Link>
            )}
        </Box>
        <Box width={1} >
            <Box p={1} className={`flex-space-between border-bottom`} style={{ backgroundColor: '#fff', marginBottom: '40px' }}>
                <Box className={classes.tableResponsiveBox}>
                    <TableContainer>
                        <Table className={classes.customTable}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ minWidth: '567px' }}>
                                        <Typography className={'body3'}>
                                            ANNOUNCEMENT
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" style={{ minWidth: '233px' }}>
                                        <Typography className={'body3'}>
                                            {isCustomer() ? 'CREATED BY' : 'STATUS'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left" style={{ minWidth: '100px' }}>
                                        <Typography className={'body3'}>
                                            {isCustomer() ? 'DATE POSTED' : 'USER'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {announcementList}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box >
        </Box >
    </AppContainer >
}
