import { useState } from 'react';

import { General } from './tabs/General';
import { Security } from './tabs/Security';
import { Notifications } from './tabs/Notifications';
import { makeStyles } from '@mui/styles';
import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import { AppContainer } from '@shared/components/AppContainer';
import { isBusiness, isCustomer, validateURL } from '@shared/helpers/global.helper';
/*
import { Payment } from './tabs/Payment';
*/
import { BusinessProfile } from '@shared/components/BusinessProfile';
import { Business } from '@interfaces/register';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { appUserProfileSelector, getProfileAction, MessageType, setMessage } from '@store/slices/app.slice';
import { settingService } from '@services/setting.service';
import { toasterService } from '@services/toaster.service';
// import { UserManagement } from './tabs/UserManagement';

const useStyles = makeStyles((theme) => ({
    settingsRow: {
        margin: '0 auto',
        maxWidth: '780px'
    }
}));

export const Settings = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [tabValue, setTabValue] = useState(1);
    let userProfile = useAppSelector(appUserProfileSelector);

    const handleTabValueChange = (event: any, newTabValue: number) => {
        setTabValue(newTabValue)
    };

    const onProfileSubmit = async (event: any) => {
        if (!event.logo_updated) {
            delete event.logo
        }
        if (!event.cover_updated) {
            delete event.cover
        }
        delete event.cover_updated
        delete event.logo_updated
        const response = await settingService.updateBusinessProfile(event);
        toasterService.success('Business profile updated successfully.')
        dispatch(getProfileAction())
    };

    const profile = {
        ...userProfile.business,
        logo: userProfile.business?.logo?.file,
        cover: userProfile.business?.cover?.file,
        facebook: validateURL(userProfile.business?.facebook),
        instagram: validateURL(userProfile.business?.instagram),
        tiktok: validateURL(userProfile.business?.tiktok),
        website: validateURL(userProfile.business?.website),
        twitter: validateURL(userProfile.business?.twitter),
        twitch: validateURL(userProfile.business?.twitch),
        youtube: validateURL(userProfile.business?.youtube),
    }
    const tabsArray = [<General />, <Security />, <Notifications />];
    if (isCustomer()) {
        //tabsArray.push(<Payment />)
    }
    if (isBusiness()) {
        tabsArray.push(<div style={{ padding: '15px' }} className="white">
            <BusinessProfile profile={profile as Business} submit={onProfileSubmit}>
                <Grid container spacing={2} justifyContent="center" direction="column" >
                    <Grid item>
                        <Button variant="contained" color="primary" type="submit" >
                            Save Changes
                        </Button>
                    </Grid>
                </Grid>
            </BusinessProfile>

        </div>)
        // tabsArray.push(<UserManagement />)
    }
    return <AppContainer>
        <Box className={classes.settingsRow}>
            <Typography variant='h4'>{isBusiness() ? 'Business' : 'User'} Settings</Typography>

            <Box my={4}>
                <Tabs
                    className="hover"
                    style={{ cursor: 'pointer' }}
                    value={tabValue}
                    onChange={handleTabValueChange}
                    textColor='primary'
                    indicatorColor='primary'
                    aria-label='User Settings'
                >
                    <Tab value={1} label='General' style={{ cursor: 'pointer' }} />
                    <Tab value={2} label='Security' />
                    <Tab value={3} label='Notifications' />

                    {/*{isCustomer() && (
                        <Tab value={4} label='Payment' />
                    )}*/}
                    {isBusiness() && (
                        [
                            <Tab value={4} label='Profile' />
                            // <Tab value={5} label='User Management' />
                        ]

                    )}
                </Tabs>
                <Box my={2}>
                    {tabsArray[tabValue - 1]}
                </Box>
            </Box>
        </Box>
    </AppContainer>
}
