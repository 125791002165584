import React, { useEffect, useState } from 'react';

import { Box, Button, Divider, Grid, Paper, Theme, Typography } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PinDropIcon from '@mui/icons-material/PinDrop';
import MovieIcon from '@mui/icons-material/Movie';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { AppContainer } from '@shared/components/AppContainer';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getTokenAction, getTokenActivationDetailAction, tokenSelector } from '@store/slices/token.slice';
import { tokenService } from '@services/token.service';
import { appSelector, } from '@store/slices/app.slice';
import { TokenScanSkeleton } from '@shared/skeletons/TokenScanSkeleton';
import { TOKEN_STATUS } from '@shared/constants/app.constant';
import { ITokenActivation } from '@interfaces/token';
import Moment from 'react-moment';
import PeopleImage from '@assets/images/people.jpg';

const useStyles = makeStyles((theme: Theme) => ({
    white: {
        backgroundColor: theme.palette.background.paper,
    },
    dark: {
        color: '#64748B'
    },
    header: {
        marginBottom: '8px'
    },
    green: {
        color: 'green'
    },
    chip: {
        height: '20px !important', width: '100px',
        background: 'rgba(0, 159, 77, 0.1) !important',
        color: '#009F4D !important'
    },
    paper: {
        minHeight: '400px',
        padding: '15px',
        color: `${theme.palette.background.paper} !important`,
        backgroundImage: `url(${PeopleImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        // filter: 'brightness(50%)'
    },

    icon: {
        fontSize: '75px !important'
    },
    container: {
        display: 'flex',
        minHeight: '300px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: "center",
        textAlign: 'center',
        margin: '10px 2px',
    }
}));

export const BusinessActivateToken = () => {
    const classes = useStyles();
    let { id } = useParams<{ id: string }>();
    const tokenDetails = useAppSelector(tokenSelector);
    const appDetail = useAppSelector(appSelector);
    const [activated, setActivated] = useState(false);
    const [failed, setFailed] = useState(false)
    const [state, setState] = useState({} as ITokenActivation)
    const dispatch = useAppDispatch();

    const activate = async (id: string) => {
        try {
            setFailed(false);
            const response = await tokenService.activateToken(id);
            findActivationDetail(id)
            setActivated(true);
        } catch (error) {
            setFailed(true);
        }
    }

    const findActivationDetail = async (id: string) => {
        const response = await tokenService.findActivationDetail(id);
        dispatch(getTokenAction(response?.data?.data?.token_id))
        setState(response?.data?.data)
        if (response?.data && response?.data?.data?.status == TOKEN_STATUS.PENDING) {
            activate(id)
        }

    }

    const checkActivation = () => {
        if (failed) {
            return <> Something went wrong while activating Membership. Please try again later.</>;
        } else if (!activated && state?.status === TOKEN_STATUS.ACTIVE) {
            return <> <strong>{`${tokenDetails?.token?.profile?.title} `}</strong> already activated   </>;
        } else if (state?.status === TOKEN_STATUS.ARCHIVED || state?.is_expired) {
            return <>  <strong>{`${tokenDetails?.token?.profile?.title} `}</strong> is invalid.  </>
        } else if (activated && state?.status === TOKEN_STATUS.ACTIVE) {
            return <> <strong>{`${tokenDetails?.token?.profile?.title} `}</strong>activated successfully.</>
        }
    }

    useEffect(() => {
        if (id) {
            findActivationDetail(id)
        }
    }, [])

    const breadcrumbs = [
        <Link  key="1" component={RouterLink} to="/tokens" color="inherit" href="/" >
            Memberships
        </Link>,
        <Link underline="hover" key="1" color="inherit"  >
            <strong> {tokenDetails?.token?.profile?.title}</strong>
        </Link>,
    ];

    return (
        <AppContainer>
            {appDetail?.loading && (
                <TokenScanSkeleton />
            )}

            {!appDetail?.loading && (
                <>
                    <Box my={4}>
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Box>
                    <Grid container justifyContent="center" direction="row" sx={{padding:'20px 0px'}}>
                        <Grid item md={8}>
                            <Paper elevation={8} className={classes.paper}>
                                <Box my={2} display="flex" justifyContent="center">
                                    <Typography variant="h5" className={classes.header}>
                                        <strong>{tokenDetails?.token?.profile?.title}</strong>
                                    </Typography>
                                </Box>
                                <Box sx={{
                                        flexDirection: {
                                            xs: 'column',
                                            sm: 'row'
                                        },
                                        alignItems: {
                                            xs: 'flexStart',
                                            sm: 'center'
                                        },
                                       
                                    }} display="flex" alignItems="center" justifyContent="center">
                                    <Box display="flex" marginRight="15px" alignItems="center" sx={{ mb: {xs: 1, sm: 0} }} ><PersonOutlineIcon />&nbsp; {tokenDetails?.token?.business?.name} </Box>
                                    <Box display="flex" marginRight="15px" alignItems="center" sx={{ mb: {xs: 1, sm: 0} }} ><PinDropIcon /> &nbsp;{tokenDetails?.token?.business?.city}, {tokenDetails?.token?.business?.state}  </Box>
                                    <Box display="flex" alignItems="center" ><MovieIcon /> &nbsp; {tokenDetails?.token?.profile?.category?.name}</Box>
                                </Box>
                                <Box my={2}>
                                    <Grid container justifyContent="center" direction="row">
                                        <Grid item md={5}>
                                            <Paper elevation={8} className={classes.container} >
                                                {(state?.status === TOKEN_STATUS.ACTIVE || activated) && (
                                                    <CheckCircleIcon className={classes.icon} />
                                                )}
                                                {(state?.status === TOKEN_STATUS.ARCHIVED || state?.is_expired || failed) && (
                                                    <CancelIcon className={classes.icon} />
                                                )}

                                                <br /><br />
                                                <Typography variant="h6" className={`${classes.header}`}>
                                                    {checkActivation()}
                                                </Typography>
                                                {!failed && (
                                                    <>
                                                        <br />
                                                        <Typography variant="caption" >
                                                            <strong>Activated at:</strong>   <Moment format="d MMM, y hh:mm:ss">{state?.activated_at}</Moment>
                                                        </Typography>
                                                        <Typography variant="caption" >
                                                            <strong>Activation End date:</strong>   <Moment format="d MMM, y hh:mm:ss">{state?.activation_end_at}</Moment>
                                                        </Typography><br />
                                                    </>
                                                )}
                                            </Paper>
                                            <br />
                                            <Box display="flex" justifyContent="center" width={1}>
                                                <Button variant="outlined" style={{ color: 'white', borderColor: 'white' }} className="w-100" component={RouterLink} to="/tokens" color="primary" >
                                                    Back to dashboard
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                            </Paper>
                        </Grid>
                    </Grid>
                </>
            )}

        </AppContainer>
    )
}
