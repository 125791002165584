import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MirabellaLogo from "@assets/images/mirabella_logo.png";
const SUB_TEXT = ["Aoko", "Mirabella", "Hutong", "Drexel", "Wabi Sabi", "Giorgina", "Michael's Genuine", "LPM Restaurant"]

export const AppSlider = ({ images }: { images: Array<string> }) => {
    return (
        <Carousel showStatus={false} autoPlay={true} interval={5000} showThumbs={false} dynamicHeight={true} infiniteLoop>
            {images && images.map((image, index) => (
                <Box key={Math.random()}>
                    {/*<img style={{ maxWidth: '698px', height: '368px',}} src={image} />*/}
                    {/*<div style={{background: `url(${image}) no-repeat scroll center top transparent`, height: '368px'}} />*/}
                    {/*{TODO: Better image sizing fix?}*/}
                    {/*<div style={{background: `url(${image}) no-repeat scroll center top transparent`, height: '368px', backgroundSize: 'auto 100%'}} />*/}
                    <Box sx={{
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${image})`,
                        backgroundSize: 'cover',
                        position: 'relative',
                        minHeight: '500px;',
                        maxWidth: 'none !important'}} />
                    <Box>
                        <Typography className={'hero-text'}>The Dining Experience</Typography>
                    </Box>
                    {/*<Box sx={{
                        position: 'absolute',
                        bottom: '0',
                        right: '0',
                        width: '150px'}}>
                        <img src={MirabellaLogo} alt='Logo'  />
                    </Box>*/}
                    <Box>
                        <Typography className={'hero-text-slider'}>{SUB_TEXT[index]}</Typography>
                    </Box>
                </Box>
            ))}
        </Carousel>
    )
}
