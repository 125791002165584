import { greetUser } from '@shared/helpers/global.helper';
import { Profile } from '@interfaces/user';
import { Box, Typography } from '@mui/material';
import { DashboardHeaderSkeleton } from '@shared/skeletons/DashboardHeaderSkeleton';


export const DashboardHeader = ({ profile }: { profile: Profile }) => {
    return (
        <>   {(!profile?.first_name || !profile?.last_name) ? <DashboardHeaderSkeleton /> : (
            <Box display="flex" flexDirection="column" py={3} justifyContent="space-between">
                <Typography fontWeight={400} className={'title2'}>
                    {greetUser()},
                </Typography>
                {profile?.first_name && profile?.last_name && (
                    <Typography fontWeight={400} className={'title2'}>
                        {`${profile?.first_name} ${profile?.last_name}`}
                    </Typography>
                )}
            </Box>
        )}
        </>
    )
}
