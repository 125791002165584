import { networkService } from "./network-service";
import {ReservationDetails} from "@interfaces/register";

class CommonService {
    async getAnnouncementById(id: string) {
        return await networkService.get(`/announcement/${id}`)
    }
    async getUnreadNotifications() {
        return await networkService.get(`/announcements/unread`)
    }

    async getReservations(reservationDetails: any ) {
        return await networkService.post(`/restaurants/reservations`, reservationDetails);
    }

    async postReservationActivity(reservation: any ) {
        return await networkService.post(`/reservation`, reservation);
    }
}

export const commonService = new CommonService();