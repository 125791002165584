import React, {useEffect, useState} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Auth } from './auth';
import { Features } from './features';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useAppSelector } from './store/hooks';
import { LoadingSpinner } from './shared/components/Loader';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ScrollToTop from "./features/common/components/ScrollToTop";
import {LoadingModal} from "@shared/components/LoadingModal";

function App() {

    const isLoading = useAppSelector(state => state.app.loading);
    const [loadingWithDelay, setLoadingWithDelay] = useState<Boolean | undefined>(false);


    useEffect(() => {
        if (!isLoading && loadingWithDelay) {
            setLoadingWithDelay(false);
        }
        else if (isLoading) {
            let timer = setTimeout(() => {
                setLoadingWithDelay(isLoading)
            }, 1500);

            return () => {
                if (timer) {
                    clearTimeout(timer);
                }
            }
        }
    },[isLoading]);

  const loadingMsg = useAppSelector(state => state.app.loadingMsg);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        {loadingWithDelay && (
          <div className="loader">
            {loadingMsg && (<LoadingModal msg={loadingMsg}/>)}
            <LoadingSpinner />
          </div>
        )}
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* Same as */}
        <ScrollToTop />
        <Switch>
          <Route path="/auth" component={Auth} />
          <Route path="/" component={Features} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
