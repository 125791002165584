import {
    Box,
    Button,
    Grid,
    List,
    ListItem,
    TextField,
    Typography
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import { useAppDispatch, useAppSelector } from '@store/hooks';
import { MessageType, setMessage } from '@store/slices/app.slice';
import { UseFormTextField } from '@shared/components/UseFormTextField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { updatePasswordSchema } from '../settings.schema';
import { settingService } from '@services/setting.service';
import { useHistory } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material'
import { useState } from 'react';
import { toasterService } from '@services/toaster.service';
import { SendCodeConfirmation } from '@shared/components/SendCodeConfirmation';

import { appUserProfileSelector } from '@store/slices/app.slice';
import { VerifySecurityCode } from '@shared/components/VerifySecurityCode';
import { VerificationState } from '@interfaces/auth';

const useStyles = makeStyles((theme) => ({
    passwordRequirements: {
        backgroundColor: '#F1F5F9',
        border: '1px solid #94A3B8',
        borderRadius: '5px',
    },
    passwordReqParagraph: {
        color: '#64748B',
    },
    listItem: {
        color: '#64748B',
        fontSize: '12px',
    }
}));


export const Security = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const userProfile = useAppSelector(appUserProfileSelector);
    const [showOldPassword, setShowOldPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [otpCode, setOtpCode] = useState('')
    const [updatePasswordModel, setUpdatePasswordModel] = useState<any>(null)
    const [verificationState, setverificationState] = useState<VerificationState>(VerificationState.INITIAL)
    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(updatePasswordSchema),
    });
    const onSubmit = (event: any) => {
        setverificationState(VerificationState.SUBMITTED)
        setUpdatePasswordModel({ new_password: event.new_password, old_password: event.old_password })
        reset({
            new_password: '',
            old_password: '',
            confirm_password: ''
        })
    }

    const resendCode = () => {
        settingService.sendVerification(updatePasswordModel)
            .then(response => {
                setverificationState(VerificationState.CONFIRMED)
                toasterService.success('Verification code has been sent to your registered phone number.')
            })
    }

    const confirmCode = () => {
        settingService.updatePassword({ ...updatePasswordModel, code: otpCode })
            .then(response => {
                setverificationState(VerificationState.INITIAL)
                reset({
                    new_password: '',
                    old_password: '',
                    confirm_password: ''

                })
                toasterService.success('Password has been updated successfully.')
            })
    }
    return <div className="white" style={{ padding: '15px' }}>
        <Typography variant='h6' >
            Change Your Password
        </Typography>
        <Typography variant="body1" >We will email you a confirmation when changing your password, so please expect that email after submitting.</Typography>
        <br />
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Grid container rowSpacing={{ xs: 4, md: 0 }} columnSpacing={{ md: 4 }}>
                <Grid item md={6} xs={12}>
                    {verificationState === VerificationState.INITIAL && (
                        <>
                            <Box mb={3}>
                                <UseFormTextField type={showOldPassword ? 'text' : 'password'} control={control} name="old_password" placeholder="Current  Password"
                                    inputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowOldPassword(!showOldPassword)}
                                                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                                                edge="end"
                                            >
                                                {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }} />
                            </Box>
                            <Box mb={3}>
                                <UseFormTextField type={showNewPassword ? 'text' : 'password'} control={control} name="new_password" placeholder="New Password"
                                    inputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowNewPassword(!showNewPassword)}
                                                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                                                edge="end"
                                            >
                                                {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            </Box>
                            <Box mb={3}>
                                <UseFormTextField type={showConfirmPassword ? 'text' : 'password'} control={control} name="confirm_password" placeholder="Confirm Password"
                                    inputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            </Box>
                            <Button type="submit" variant='contained' >Submit</Button>
                        </>

                    )}
                    {verificationState === VerificationState.SUBMITTED && (
                        <>
                            <SendCodeConfirmation email={userProfile?.email} >

                                <Box display="flex" py={2} justifyContent="flex-start" >
                                    <Button type="button" onClick={() => { resendCode() }} variant='contained' >Continue</Button>
                                    &nbsp;  &nbsp;
                                    <Button type="button" onClick={() => { setverificationState(VerificationState.INITIAL) }}  >Back</Button>
                                </Box>

                            </SendCodeConfirmation>
                        </>
                    )}
                    {verificationState === VerificationState.CONFIRMED && (
                        <>
                            <VerifySecurityCode email={userProfile?.email} phone={userProfile?.phone_number} >
                                <TextField
                                    value={otpCode}
                                    placeholder='Enter verification code here'
                                    onChange={(e) => { setOtpCode(e.target.value) }}
                                    size={'medium'}
                                    variant="outlined"
                                    className="w-100"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Verification code</InputAdornment>,
                                    }}
                                />
                                <Typography variant="body2" className="grey-color">
                                    Didn't receive the verification code ?
                                    <Button color="primary" className="hover" onClick={() => { resendCode() }} >
                                        Resend code
                                    </Button>
                                </Typography>

                                <Box display="flex" py={2} justifyContent="flex-start" >
                                    <Button type="button" onClick={() => { confirmCode() }} variant='contained' >confirm</Button>
                                    &nbsp;  &nbsp;
                                    <Button type="button" onClick={() => { setverificationState(VerificationState.SUBMITTED) }}  >Back</Button>
                                </Box>

                            </VerifySecurityCode>
                        </>
                    )}
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box p={2} className={classes.passwordRequirements}>
                        <Typography ><strong>Password requirements</strong></Typography>
                        <Typography className={classes.passwordReqParagraph}>To create a new password, you have to meet all of the following requirements:</Typography>
                        <List dense>
                            <ListItem className={classes.listItem}>• Minimum 8 character</ListItem>
                            <ListItem className={classes.listItem}>• At least one special character</ListItem>
                            <ListItem className={classes.listItem}>• At least one capital letter</ListItem>
                            <ListItem className={classes.listItem}>• At least one lowercase letter</ListItem>
                            <ListItem className={classes.listItem}>• At least one number</ListItem>
                            <ListItem className={classes.listItem}>• Cannot be the same as a previous password</ListItem>
                        </List>
                    </Box>
                </Grid>
            </Grid>
        </form>
    </div>
}
