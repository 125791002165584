import { Box, Grid, Paper, Skeleton, Typography } from '@mui/material'
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    flex: {
        display: 'flex',
        alignItems: 'center'
    }
}));

export const TokenScanSkeleton = () => {
    const classes = useStyles();
    return (
        <>
            <Box mb={6} width="20%">
                <Typography component="div" className="w-100" variant="body1"><Skeleton /></Typography>
            </Box>
            <Grid container justifyContent="center" direction="row" >
                <Grid item md={8}>
                    <Paper elevation={8} style={{padding:'10px'}} >
                        <Box mt={4} display="flex" width={1} justifyContent="center">
                            <Typography component="div" style={{ width: '30%' }} variant="body1"><Skeleton /></Typography>
                        </Box>
                        <Box width={1} className="flex-justify-center">
                            <Grid container spacing={4} justifyContent="center">
                                <Grid item md={2} className={classes.flex}>
                                    <Skeleton variant="circular" width={40} height={30} /> &nbsp;
                                    <Typography component="div" className="w-100" variant="body1"><Skeleton /></Typography>
                                </Grid>
                                <Grid item md={2} className={classes.flex}>
                                    <Skeleton variant="circular" width={40} height={30} />&nbsp;
                                    <Typography component="div" className="w-100" variant="body1"><Skeleton /></Typography>
                                </Grid>
                                <Grid item md={2} className={classes.flex}>
                                    <Skeleton variant="circular" width={40} height={30} />&nbsp;
                                    <Typography component="div" className="w-100" variant="body1"><Skeleton /></Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={4} className="flex-justify-center" width={1}>
                            <Skeleton variant="rectangular" width={400} height={250} />
                        </Box>
                        <Box mt={4} display="flex" width={1} justifyContent="center">
                            <Typography component="div" style={{ width: '30%' }} variant="body1"><Skeleton /></Typography>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}
