import { combineReducers } from "@reduxjs/toolkit";
import appReducer from './app.slice'
import { authReducer } from './auth.slice';
import { commonReducer } from "./common.slice";
import { tokenReducer } from './token.slice';
import { walletReducer } from './wallet.slice';

const rootReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    token: tokenReducer,
    common: commonReducer,
    wallet: walletReducer,
});

export default rootReducer;