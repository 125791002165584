
import {
    DECIMAL_NUMBER_REGEX,
    ETH_DECIMAL_NUMBER_REGEX, NUMBERS_GREATER_THAN_0_OR_NEG_1_REGEX,
    NUMBERS_GREATER_THAN_0_REGEX,
    NUMBERS_ONLY_REGEX
} from '@shared/constants/app.constant';
import * as yup from 'yup';

export const createTokenSchema = yup.object().shape({
    id: yup.string(),
    title: yup.string().required('Title is required.').max(50).min(5),
    description: yup.string().required('Description is required.'),
    price: yup.string().required('Price is required.').matches(ETH_DECIMAL_NUMBER_REGEX, 'Price must be a decimal with up to 7 digits or "0" for a free token.'),
    // copies: yup.number().typeError('Copies must be a number').min(1, 'Copies must be greater than 0'),
    copies: yup.string().matches(NUMBERS_GREATER_THAN_0_OR_NEG_1_REGEX, 'Copies must be a number greater than 0 or -1 for unlimited copies'),
    royalty: yup.number().typeError('Royalty must be a number').min(0, 'Royalty must be greater than or equal to 0').max(10, 'Royalty must be less than or equal to 10%'),
    lockout_period: yup.string().matches(NUMBERS_GREATER_THAN_0_OR_NEG_1_REGEX, 'Lockout period must be a number greater than 0 or -1 to turn off lockout periods'),
    expiration_duration: yup.string().matches(NUMBERS_GREATER_THAN_0_OR_NEG_1_REGEX, 'Expiration time must be a number greater than 0 or -1 if the membership should never expire'),
    renewal_price: yup.string().required('Price is required.').matches(ETH_DECIMAL_NUMBER_REGEX, 'Renewal Price must be a decimal with up to 9 digits or "0" for a free renewal.'),
    category_id: yup.string().required('Category is required.'),
    // floor_price: yup.number().typeError('Floor price must be a number').min(0, 'Floor price should be less than or equal to listing price.').max(yup.ref('price'), "Floor price should be less than or equal to listing price")
});
