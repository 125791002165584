import { useEffect } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom';
import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import styles from '@auth/styles';
import { AppLogo } from '@shared/components/Logo';
import { resetPasswordAction } from '@store/slices/auth.slice';
import { useAppDispatch } from '@store/hooks';
import { resetPasswordSchema } from '@auth/auth.schema';
import { UseFormTextField } from '@shared/components/UseFormTextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material'
import { useState } from 'react';

const useStyles = makeStyles(styles as any);

export const ResetPassword = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  let { email } = useParams<{ email: string }>();
  let history = useHistory();
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(resetPasswordSchema)
  });

  const onSubmit = (event: any) => {
    dispatch(resetPasswordAction({ email, code: event.code, password: event.password, history }))
  }

  useEffect(() => {
    if (!email) {
      history.push(`/auth/login`);
    }
  }, [])

  return (
    <>
      <Box display="flex" sx={{ justifyContent: { xs: 'center', md: 'space-between' } }} px={4} pt={3}>
        <AppLogo />
      </Box>
      <div className={classes.root}>
        <Box className={`${classes.paper} ${classes.boxDimension}`}
          sx={{
            bgcolor: 'background.paper',
            boxShadow: { xs: 0, md: 10 },
            borderRadius: 1,
            width: { xs: '100%' }
          }} >
          <Typography variant="h6" className={classes.title} gutterBottom>
            Reset Password
          </Typography>
          <Typography variant="caption" className={classes.subtitle} gutterBottom>
            Enter the details below to reset your password.
          </Typography>
          <Box mt={4} width="100%">
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} >
              <Grid container justifyContent="center" direction="column" spacing={2}>
                <Grid item>
                  <UseFormTextField control={control} name="code" placeholder="Code" />
                </Grid>
                <Grid item>
                  <UseFormTextField type={showNewPassword ? 'text' : 'password'} control={control} name="password" placeholder="New Password"
                    inputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                          edge="end"
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>,
                    }} />
                </Grid>
                <Grid item>
                  <UseFormTextField type={showConfirmPassword ? 'text' : 'password'} control={control} name="passwordConfirmation" placeholder="Confirm Password"
                    inputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                          edge="end"
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>,
                    }} />
                </Grid>
                <Grid item>
                  <Button variant="contained" className="w-100" color="primary" type="submit">
                    Reset Password
                  </Button>
                </Grid>
                <Grid item >
                  <Button color="primary" className="w-100" component={Link} to="/auth/login">
                    Back to Login
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </div>
    </>
  )
}
