import React, { useEffect, useState } from 'react';
//Components
import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
//Style
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { appUserProfileSelector, getProfileAction, MessageType, setMessage, updateProfileAction } from '@store/slices/app.slice';
import { UseFormTextField } from '@shared/components/UseFormTextField';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { profileSchema } from '../settings.schema';
import { settingService } from '@services/setting.service';
import ConfirmDialog from '@shared/components/confirmDialog';
import { makeStyles } from '@mui/styles';
import styles from '../styles';
import InputMask from 'react-input-mask'
import { UseFormSelect } from '@shared/components/UseFormSelect';
import { STATES_LIST } from '@shared/constants/app.constant';
import { unmaskMobile } from '@shared/helpers/global.helper';
import { authService } from '@services/auth.service';
import { toasterService } from '@services/toaster.service';

const useStyles = makeStyles(styles as any);

export const General = () => {
    const classes = useStyles();
    const [phoneError, setPhoneError] = useState<string>('')
    const userProfile = useAppSelector(appUserProfileSelector);
    const dispatch = useAppDispatch();
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const history = useHistory();

    const { control, handleSubmit } = useForm({
        resolver: yupResolver(profileSchema),
    });

    const onSubmit = async(event: any) => {

        const phone = unmaskMobile(event.phone_number)

        if (phone && phone.length < 12) {
            setPhoneError('Please enter a valid US number');
            return;
        }
        if (phone != userProfile.phone_number) {
            const phoneExist = await authService.isAvailable({ value: phone, field: 'phone_number' });

            if (phoneExist) {
                setPhoneError('This phone number is already in use.');
                return;
            }
        }


        event.phone_number = phone;
        setPhoneError('');
        const { email, username, ...rest } = event;
        dispatch(updateProfileAction({ ...rest }));
        setTimeout(() => {
            dispatch(getProfileAction())
        }, 500);
    }

    const handleCloseDialog = (flag: boolean) => {
        if (flag && typeof flag === 'boolean') {
            settingService.deleteAccount()
                .then(response => {
                    setOpenConfirmDialog(false);
                    toasterService.success('Your account has been deactivated.')
                    history.push('/auth/login')
                })
        } else {
            setOpenConfirmDialog(false);
        }
    }

    useEffect(() => {
        if (!userProfile || Object.keys(userProfile).length === 0) {
            dispatch(getProfileAction())
        }
    }, [])

    return <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} className="white" style={{ padding: '15px' }}>
        <ConfirmDialog message="Are you sure you want to delete your account?" open={openConfirmDialog} handleClose={handleCloseDialog} />
        <Typography variant='h6' >
            Basic Information
        </Typography> <br />

        <Grid container direction="row" spacing={2}>

            <Grid item md={6} xs={12}>
                <UseFormTextField defaultValue={userProfile.first_name} control={control} name="first_name" placeholder="First Name" />
            </Grid>
            <Grid item md={6} xs={12}>
                <UseFormTextField defaultValue={userProfile.last_name} control={control} name="last_name" placeholder="Last Name" />
            </Grid>
            <Grid item md={6} xs={12}>
                <UseFormTextField disabled={true} defaultValue={userProfile.username} control={control} name="username" placeholder="Username" />
            </Grid>
            <Grid item md={6} xs={12}>
                <UseFormTextField disabled={true} defaultValue={userProfile.email} control={control} name="email" placeholder="Email" />
            </Grid>
            <Grid item md={6} xs={12}>
                <Controller
                    name="phone_number"
                    control={control}
                    defaultValue={userProfile.phone_number}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <InputMask
                            mask="+1 (999) 999 9999"
                            value={value}
                            onChange={onChange}
                        >
                            {(inputProps: any) => (
                                <TextField
                                    label={'Phone Number'}
                                    value={value}
                                    onChange={onChange}
                                    size={'medium'}
                                    type={'text'}
                                    variant="outlined"
                                    className="w-100"
                                    {...inputProps}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                />
                            )}
                        </InputMask>
                    )}
                />
                {phoneError?.length > 0 && (
                    <Box color="red" textAlign="left" py={1}>{phoneError}</Box>
                )}

                {/* <UseFormTextField defaultValue={userProfile.phone_number} control={control} name="phone_number" placeholder="Phone Number" /> */}
            </Grid>

        </Grid> <br />
        <Divider /> <br />
        <Typography variant='h6' >
            Address
        </Typography>
        <br />
        <Grid container direction="row" spacing={2}>
            <Grid item md={6} xs={12}>
                <UseFormTextField defaultValue={userProfile.address1} multiline control={control} name="address1" placeholder="Address 1" />
            </Grid>
            <Grid item md={6} xs={12}>
                <UseFormTextField defaultValue={userProfile.address2} multiline control={control} name="address2" placeholder="Address 2" />
            </Grid>
            <Grid item md={6} xs={12}>
                <UseFormTextField defaultValue={userProfile.city} control={control} name="city" placeholder="City" />
            </Grid>
            <Grid item md={6} xs={12}>
                <UseFormSelect defaultValue={userProfile.state} control={control} name="state" placeholder="State" items={STATES_LIST} />
            </Grid>


            <Grid item md={6} xs={12}>
                <UseFormTextField defaultValue={userProfile.zip} control={control} name="zip" placeholder="Zip" />
            </Grid>
        </Grid> <br />
        <Divider /> <br />
        <Box mb={2}>
            <Button type="submit" variant='contained'>Save Changes</Button>
        </Box>
        <Divider />
        <Box className={classes.deleteSection} my={2}>
            <Box className='w-100'>
                <Box className={classes.deleteNote} mb={2}>
                    <Typography variant="h6">Delete your account</Typography>
                    <Typography variant="body1" style={{ 'color': '#64748B' }}>Please note, deleting your account is a permanent action and will no be recoverable once completed.</Typography>
                </Box>
            </Box>
            <Box>
                <Button className={classes.deleteButton} onClick={() => setOpenConfirmDialog(true)} variant='outlined' >Delete</Button>
            </Box>
        </Box>
    </form>
}
