import {
    Box,
    Button,
    Typography,
} from '@mui/material';
import { settingService } from '@services/setting.service';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { appUserProfileSelector, getProfileAction, MessageType, setMessage } from '@store/slices/app.slice';
import {Notification as SharedNotifications} from '@shared/components/Notification'
import { toasterService } from '@services/toaster.service';
import { AppType } from "@shared/constants/app.constant";

export const Notifications = () => {
    const userProfile = useAppSelector(appUserProfileSelector);
    const dispatch = useAppDispatch();

    const handleChange = async (event: any, type: string) => {
        await settingService.updateNotification({
            [type]: event.target.checked
        });
        toasterService.success('Notification setting updated successfully.')
        dispatch(getProfileAction())
    }

    const onDisableAll = async (action: boolean) => {
        if(userProfile.role_name === AppType.Business) {
            await settingService.updateNotification({
                is_sold_token_email_notify: action
            });
        } else {
            await settingService.updateNotification({
                is_purchased_token_email_notify: action,
                is_redeemed_token_email_notify: action
            });
        }

        toasterService.success('Notification setting updated successfully.')
        dispatch(getProfileAction())
    }

    const notificationStatus = () => {
        if(userProfile.role_name === AppType.Business) {
            return userProfile.is_sold_token_email_notify;
        } else {
            return userProfile.is_purchased_token_email_notify &&
                userProfile.is_redeemed_token_email_notify
        }
    }

    return <div className="white" style={{padding:'15px'}}>
        <Box mb={4} className="flex-space-between">
            <Typography variant="h6"> Notifications </Typography>
            <Button variant='outlined' onClick={() => onDisableAll(!notificationStatus())} >{notificationStatus() ? 'Disable' : 'Enable'} All</Button>
        </Box>
        <SharedNotifications  notifications={userProfile} handleChange={handleChange} role={userProfile.role_name} />
    </div>
}
