import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            light: '#800020',
            // main: '#041E42',
            main: '#63000a',
            // dark: '#041E42',
            dark: '#470000',
            contrastText: '#fff',
        },
            secondary: {
            light: '#e5e8ef',
            main: '#003a89',
            dark: '#00216a',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: '\'Lato\', sans-serif',
        button: {
            textTransform: 'none'
        }
    }
});

export default theme;
