
import { Grid, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/system';

interface MediaProps {
    loading?: boolean;
}

export const TokenListSkeleton = ({ width }: { width?: string }) => {
    return (
        <Box display="flex" p={2}>
            <Skeleton animation="wave" variant="circular" width={40} height={40} />
            <Box width="100%" ml={2}>
                <Box width={width}>
                    <Typography component="div" variant="body1"><Skeleton /></Typography>
                </Box>
                <Grid container spacing={2} >
                    <Grid item md={2} className="flex-align-center">
                        <Skeleton variant="circular" width={15} height={15} /> &nbsp;
                        <Typography component="div" className="w-100" variant="body1"><Skeleton /></Typography>
                    </Grid>
                    <Grid item md={2} className="flex-align-center">
                        <Skeleton variant="circular" width={15} height={15} />&nbsp;
                        <Typography component="div" className="w-100" variant="body1"><Skeleton /></Typography>
                    </Grid>
                    <Grid item md={2} className="flex-align-center">
                        <Skeleton variant="circular" width={15} height={15} />&nbsp;
                        <Typography component="div" className="w-100" variant="body1"><Skeleton /></Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

