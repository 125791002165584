import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {networkService} from "@services/network-service";
import {RootState} from "@store/store";
import {Wallet} from "@interfaces/wallet";

const initialState: Wallet = {
    // address: "" as string
    address: [],
    selectedAddress: ''
};

//TODO: Don't pass Id because of authentication token?
export const getWalletAction = createAsyncThunk(
    'wallet/getWallet',
    async ({id}: { id: string }, {rejectWithValue, dispatch}) => {
        try {
            const response = await networkService.get(`/wallet/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

//TODO: Don't pass Id because of authentication token?
export const saveWalletAction = createAsyncThunk(
    'wallet/saveWallet',
    async ({id, address}: { id: string, address: string }, {rejectWithValue, dispatch}) => {
        try {
            const response = await networkService.post(`/wallet/${id}`, {address:address});
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const walletSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetWallet: (state: Wallet) => {
            // state.address = "";
            state.address = [];
            state.selectedAddress = '';
        },
        resetWalletSlice(state) {
            Object.assign(state, initialState)
        },
        updateSelectedAddress(state, action) {
            state.selectedAddress = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getWalletAction.fulfilled, (state: Wallet, {payload}: PayloadAction<any>) => {
            // state.address = payload.data[0]?.address;
            // console.log(state.address);

            // Map users addresses to an array
            state.address = payload.data.map((x:any) => x.address);

        });
        builder.addCase(saveWalletAction.fulfilled, (state: Wallet, {payload}: PayloadAction<any>) => {
            state.address = payload.data;
        })

    }
})

export const {reducer: walletReducer} = walletSlice;
export const {resetWallet, resetWalletSlice, updateSelectedAddress} = walletSlice.actions;

// export const walletSelector = (rootState: RootState) => rootState.wallet.address;
export const walletSelector = (rootState: RootState) => rootState.wallet;
