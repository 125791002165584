import * as React from 'react';
import {Box, Button, DialogActions} from '@mui/material';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';

const ModalContainer = styled('div')(({ theme }) => ({

    [theme.breakpoints.up("sm")]: {
        width: 400,
    },
    [theme.breakpoints.down("md")]: {
        width: '80%'
    },
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    boxShadow: '24',
    borderRadius: '10px',
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: '20px'
}));

export default function AlertDialog({ open, image, children, showClose, onClose }: { children: React.ReactNode, image: React.ReactNode,
    open: boolean, showClose?: boolean, onClose?: Function }) {
    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>
                <ModalContainer>
                    <Box>
                        {image}
                    </Box>
                    {children}
                    {showClose && onClose && (
                        <DialogActions>
                            <Button color="primary" onClick={()=>onClose()} autoFocus>
                                Close
                            </Button>
                        </DialogActions>
                    )}
                </ModalContainer>
            </>
        </Modal>
    );
}
