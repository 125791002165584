import {makeStyles} from '@mui/styles';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Typography
} from '@mui/material'
import {Link, useHistory} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';

import styles from '@auth/styles';
import {resetRegister, saveDetail} from '@store/slices/auth.slice';
import {useAppDispatch,} from '@store/hooks';
import {registerSchema} from '@auth/auth.schema';
import {UseFormTextField} from '@shared/components/UseFormTextField';
import {isBusiness, isCustomer, useQuery} from '@shared/helpers/global.helper';
import React, {useEffect, useState} from 'react';
import {authService} from '@services/auth.service';
import {MessageType, setMessage} from '@store/slices/app.slice';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {toasterService} from '@services/toaster.service';
import {InputEvent} from "@shared/models/input.model";


const useStyles = makeStyles(styles as any);

export const Register = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [termsChecked, setTermsChecked] = useState(false)
    const query = useQuery();
    let returnUrl = query.get("returnUrl") ? `/?returnUrl=${query.get("returnUrl")}` : '';

    const {control, handleSubmit} = useForm({
        resolver: yupResolver(registerSchema),
    });

    const onSubmit = async (event: any) => {
        try {

            const emailExist = await authService.isAvailable({value: event.email, field: 'email'});
            const usernameExists = await authService.isAvailable({value: event.username, field: 'username'});

            if (emailExist) {
                toasterService.warn('This email is already in use. Please use a different email.')
                return;
            }
            if (usernameExists) {
                toasterService.warn('This username is already in use. Please use a different username.')
                return;
            }
            dispatch(saveDetail({email: event.email, password: event.password, username: event.username}));
            history.push(`/auth/signup/fullname${returnUrl}`);
        } catch (error) {

        }
    }

    const onChange = (e: InputEvent) => {
        setTermsChecked(e.target.checked)
    }

    useEffect(() => {
        dispatch(resetRegister())
    }, [])

    return (
        <>

            <Typography variant="h6" className={classes.title} gutterBottom>
                {isCustomer() && (
                    <>Create an Account</>
                )}
                {isBusiness() && (
                    <>Create a Business Account</>
                )}
            </Typography>
            <Box mt={4} sx={{maxWidth: {md: "350px"}, width: {sm: "100%"}}}>
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <Grid container justifyContent="center" direction="column" spacing={2}>
                        <Grid item>
                            <UseFormTextField control={control} name="email" placeholder="Email"/>
                        </Grid>
                        <Grid item>
                            <UseFormTextField control={control} name="username" placeholder="Username"/>
                        </Grid>
                        <Grid item>
                            <UseFormTextField type={showNewPassword ? 'text' : 'password'} control={control}
                                              name="password" placeholder="Password"
                                              inputProps={{
                                                  endAdornment: <InputAdornment position="end">
                                                      <IconButton
                                                          aria-label="toggle password visibility"
                                                          onClick={() => setShowNewPassword(!showNewPassword)}
                                                          onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                                                          edge="end"
                                                      >
                                                          {showNewPassword ? <Visibility/> : <VisibilityOff/>}
                                                      </IconButton>
                                                  </InputAdornment>,
                                              }}/>
                        </Grid>
                        <Grid item>
                            <UseFormTextField type={showConfirmPassword ? 'text' : 'password'} control={control}
                                              name="confirmPassword" placeholder="Confirm Password"
                                              inputProps={{
                                                  endAdornment: <InputAdornment position="end">
                                                      <IconButton
                                                          aria-label="toggle password visibility"
                                                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                          onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                                                          edge="end"
                                                      >
                                                          {showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                                                      </IconButton>
                                                  </InputAdornment>,
                                              }}
                            />
                        </Grid>
                        <Grid item>
                            <Box display="flex" width="100%" justifyContent="left">
                                <FormControlLabel
                                    label=""
                                    control={
                                        <Checkbox
                                            onChange={(e: InputEvent) => onChange(e)}
                                            checked={termsChecked}
                                            size="small"
                                        />
                                    }
                                />
                                {isCustomer() && (
                                    <span className={classes.subtitleLeft}>
                                        By checking this box, I agree to the <strong>
                                        <a
                                        href="https://reserve-dining-exp-legal.s3.amazonaws.com/reserve-terms-of-use_consumers.html" target="_blank"
                                        rel="noopener noreferrer"> Terms of Use </a></strong>
                                        and <strong>
                                        <a
                                            href="https://reserve-dining-exp-legal.s3.amazonaws.com/privacy-policy.html" target="_blank"
                                            rel="noopener noreferrer">Privacy Policy</a></strong>
                                    </span>
                                )}
                                {isBusiness() && (
                                    <span className={classes.subtitleLeft}>
                                        By checking this box, I agree to the <strong>
                                        <a
                                        href="https://reserve-dining-exp-legal.s3.amazonaws.com/reserve-terms-of-use_consumers.html" target="_blank"
                                        rel="noopener noreferrer"> Terms of Use</a></strong>,
                                        <strong> <a
                                        href="https://reserve-dining-exp-legal.s3.amazonaws.com/reserve-client-agreement.html" target="_blank"
                                        rel="noopener noreferrer"> Reserve Restaurant Agreement</a></strong>,
                                        and <strong>
                                        <a
                                            href="https://reserve-dining-exp-legal.s3.amazonaws.com/privacy-policy.html" target="_blank"
                                            rel="noopener noreferrer">Privacy Policy</a></strong>
                                    </span>
                                )}
                            </Box>
                        </Grid>
                        <Grid item>
                            <Button disabled={!termsChecked} variant="contained" className="w-100" type="submit"
                                    color="primary">
                                Sign Up
                            </Button>
                        </Grid>
                        {/*<Grid item >
                            <Box display="flex" width="100%" justifyContent="center"   >
                                <FormControlLabel
                                    label=""
                                    control={
                                        <Checkbox
                                            onChange={(e: InputEvent) => onChange(e)}
                                            checked={termsChecked}
                                            size="small"
                                        />
                                    }
                                />
                                <span className={classes.subtitle} >
                                    By checking this box, I agree to the <strong>Terms of Service</strong> and <strong>
                                        Privacy Policy</strong>
                                </span>
                            </Box>
                        </Grid>*/}
                        <Grid item>
                            <Box display="flex" justifyContent="center">
                                <Typography variant="body1" className={classes.subtitle}>
                                    Existing Account? <strong>
                                    <Button color="primary" component={Link} to="/auth/login">
                                        Login
                                    </Button></strong>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </>
    )
}
