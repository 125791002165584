export default (theme) => {
    return {
        settingsRow: {
            margin: '0 auto',
            width: '100%',
            maxWidth: '680px'
        },
        overview: {
            fontFamily: 'Sans Serif',
            fontSize: '10px',
            lineHeight: '12px',
            fontWeight: 700,
            color: '#6E84A3',
            marginBottom: '4px'
        },
        settingsHeader: {
            fontFamily: 'Sans Serif',
            fontSize: '28px',
            lineHeight: '32px',
            fontWeight: 700,
            color: '#12263F',
            marginBottom: '16px'
        },
        tabContent: {
            marginTop: '24px'
        },

        textFieldMargin: {
            marginBottom: '24px'
        },
        textFieldLabel: {
            fontFamily: 'Sans Serif',
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: 500,
            color: '#0F172A',
            marginLeft: '12px'
        },

        generalHeading: {
            fontFamily: 'Sans Serif',
            fontSize: '20px',
            lineHeight: '24px',
            fontWeight: 700,
            color: '#1F2D3D',
            marginBottom: '24px'
        },
        generalSettingsFlex: {
            display: 'flex',
            marginBottom: '32px'
        },
        basicCol1: {
            width: '50%'
        },
        basicCol2: {
            width: '50%',
            marginLeft: '24px'
        },

        saveButton: {
            padding: '16px 24px !important',
            backgroundColor: '#041E42 !important',
            color: '#FFFFFF !important',
            borderRadius: '8px !important',
            fontSize: '14px !important',
            lineHeight: '16px !important',
            maxHeight: '48px !important'
        },

        deleteSection: {
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
            }
        },
        deleteButton: {
            padding: '12px 24px !important',
            backgroundColor: '#FFE8F4 !important',
            border: '1px solid #9F0052 !important',
            color: '#9F0052 !important',
            borderRadius: '8px !important',
            fontSize: '14px !important',
            lineHeight: '16px !important',
            fontWeight: '700 !important',
            [theme.breakpoints.down('sm')]: {
                alignSelf: 'flexStart !important'
            }
        },
        deleteNote: {
            fontSize: '14px !important',
            lineHeight: '18px !important',
            fontWeight: '500 !important',
            maxWidth: '350px'
        },
        deleteHeading: {
            fontFamily: 'Sans Serif',
            fontSize: '18px',
            lineHeight: '22px',
            fontWeight: 600,
            marginBottom: '8px'
        }
    }
}
