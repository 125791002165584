import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RouteComponentProps } from "react-router-dom";
import { IToken, ITokenActivation, TokensResponse, TokenState } from 'src/interfaces/token';
import { networkService } from "@services/network-service";
import { MessageType, setMessage} from "@store/slices/app.slice";
import { RootState } from "@store/store";
import { Pagination } from "@interfaces/pagination";
import { toasterService } from "@services/toaster.service";

const initialState: TokenState = {
    myTokens: {} as TokensResponse,
    hotTokens: {} as TokensResponse,
    recentTokens: {} as TokensResponse,
    featuredTokens: {} as TokensResponse,
    recommendedTokens: {} as TokensResponse,
    token: {} as IToken,
    myTokenLoaded: false,
    categoryTokens: {} as TokensResponse,
    searchedTokens: {} as TokensResponse,
    activationDetail: {} as ITokenActivation,
    categories: [],

    hotTokenLoaded: false,
    featuredTokenLoaded: false,
    recentTokenLoaded: false,
    recommendedTokenLoaded: false,
};

export const createTokenAction = createAsyncThunk(
    'token/createFlow',
    async ({ history, model }: { model: IToken, history: RouteComponentProps["history"] }, { rejectWithValue, dispatch }) => {
        try {
            let response: any;
            if (model?.id) {
                const { id, ...rest } = model;
                response = await networkService.put(`/tokens/${id}`, rest);
                toasterService.success('Token updated successfully.')
                history.push(`/tokens`)
            } else {
                response = await networkService.post('/tokens', model);
                toasterService.success('Token create successfully.')
                history.push(`/token/confirmation/${response.data?.data?.id}`)
            }

            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getTokenAction = createAsyncThunk(
    'token/getTokenFlow',
    async (id: string, { rejectWithValue, dispatch }) => {
        try {
            const response = await networkService.get(`/tokens/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getMyTokensAction = createAsyncThunk(
    'token/getTokensFlow',
    async (args, { rejectWithValue, dispatch }) => {
        try {
            const response = await networkService.get(`/tokens`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getHotTokensAction = createAsyncThunk(
    'token/getHotTokens',
    async ({ pagination, categoryId }: { pagination: Pagination, categoryId?: string }, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setTokenLoadingState('hotTokenLoaded'))
            const response = await networkService.get(`/market-place?is_hot=true&page=${pagination.page}&limit=${pagination.limit}&${categoryId ? 'category_id=' + categoryId : ''}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getRecentTokensAction = createAsyncThunk(
    'token/getRecentTokens',
    async ({ pagination }: { pagination: Pagination }, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setTokenLoadingState('recentTokenLoaded'))
            const response = await networkService.get(`/market-place?page=${pagination.page}&limit=${pagination.limit}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getFeaturedTokensAction = createAsyncThunk(
    'token/getFeaturedTokens',
    async ({ pagination, categoryId }: { pagination: Pagination, categoryId?: string }, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setTokenLoadingState('featuredTokenLoaded'))
            const response = await networkService.get(`/market-place?is_parent=true&is_featured=true&page=${pagination.page}&limit=${pagination.limit}&${categoryId ? 'category_id=' + categoryId : ''}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getRecommendedTokensAction = createAsyncThunk(
    'token/getRecommendedTokens',
    async ({ categoryId ,pagination}: { categoryId?: string ,pagination: Pagination}, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setTokenLoadingState('recommendedTokenLoaded'))
            const response = await networkService.get(`/market-place?is_recommended=true&page=${pagination?.page}&limit=${pagination?.limit}&${categoryId ? 'category_id=' + categoryId : ''}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getTokensByCategoryAction = createAsyncThunk(
    'token/getTokensByCategoryFlow',
    async ({ id, pagination }: { id: string, pagination: Pagination }, { rejectWithValue, dispatch }) => {
        try {
            const response = await networkService.get(`/market-place?category_id=${id}&page=${pagination?.page}&limit=${pagination?.limit}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getSearchedTokenAction = createAsyncThunk(
    'token/getSearchedTokenFlow',
    async ({ title, pagination }: { title: string, pagination: Pagination }, { rejectWithValue, dispatch }) => {
        try {
            const response = await networkService.get(`/market-place?title=${title}&page=${pagination.page}&limit=${pagination.limit}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getCategoriesAction = createAsyncThunk(
    'token/getCategoriesFlow',
    async (args, { rejectWithValue, dispatch }) => {
        try {
            const response = await networkService.get(`/categories`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getTokenActivationDetailAction = createAsyncThunk(
    'token/getTokenActivationDetail',
    async (id: string, { rejectWithValue, dispatch }) => {
        try {
            const response = await networkService.get(`/tokens/${id}/activate`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const tokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
        resetTokenSlice(state) {
            Object.assign(state, initialState)
        },
        resetToken(state) {
            state.token = {} as IToken;
        },
        setTokenLoadingState(state: any, { payload }: PayloadAction<any>) {
            state[payload] = false
        }
    },
    extraReducers: builder => {
        builder.addCase(createTokenAction.fulfilled, (state: TokenState, { payload }: PayloadAction<any>) => {
            state.token = payload.data;
        })
        builder.addCase(getTokenAction.fulfilled, (state: TokenState, { payload }: PayloadAction<any>) => {
            state.token = payload.data;
        })
        builder.addCase(getMyTokensAction.fulfilled, (state: TokenState, { payload }: PayloadAction<any>) => {
            state.myTokens = payload;
            state.myTokenLoaded = true;
        })
        builder.addCase(getTokensByCategoryAction.fulfilled, (state: TokenState, { payload }: PayloadAction<any>) => {
            state.categoryTokens = payload;
        })
        builder.addCase(getSearchedTokenAction.fulfilled, (state: TokenState, { payload }: PayloadAction<any>) => {
            state.searchedTokens = payload;
        })
        builder.addCase(getCategoriesAction.fulfilled, (state: TokenState, { payload }: PayloadAction<any>) => {
            state.categories = payload.data;
        })
        builder.addCase(getTokenActivationDetailAction.fulfilled, (state: TokenState, { payload }: PayloadAction<any>) => {
            state.activationDetail = payload.data;
        })
        builder.addCase(getHotTokensAction.fulfilled, (state: TokenState, { payload }: PayloadAction<any>) => {
            state.hotTokens = payload;
            state.hotTokenLoaded = true;
        })
        builder.addCase(getRecommendedTokensAction.fulfilled, (state: TokenState, { payload }: PayloadAction<any>) => {
            state.recommendedTokens = payload;
            state.recommendedTokenLoaded = true;
        })
        builder.addCase(getFeaturedTokensAction.fulfilled, (state: TokenState, { payload }: PayloadAction<any>) => {
            state.featuredTokens = payload;
            state.featuredTokenLoaded = true;
        })
        builder.addCase(getRecentTokensAction.fulfilled, (state: TokenState, { payload }: PayloadAction<any>) => {
            state.recentTokenLoaded = true;
            state.recentTokens = payload;
        })
    }
})

export const { resetTokenSlice, resetToken,setTokenLoadingState } = tokenSlice.actions;
export const { reducer: tokenReducer } = tokenSlice;

export const tokenSelector = (rootState: RootState) => rootState.token;