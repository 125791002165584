import { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import { Box, Button, Grid, InputAdornment, Paper, Typography } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { InputEvent } from '@shared/models/input.model';
import * as yup from 'yup';

import { UseFormTextField } from '@shared/components/UseFormTextField';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { URL_REGEX } from '@shared/constants/app.constant';
import { Business } from '@interfaces/register';
import { UseFormSelect } from './UseFormSelect';
import { STATES_LIST } from '@shared/constants/app.constant';
import { prependHTTPS, validateURL } from '@shared/helpers/global.helper';
import { useAppSelector } from '@store/hooks';
import { appSelector } from '@store/slices/app.slice';


const styles = {
    root: {
        display: 'flex',
        minHeight: 700,
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 350,
        maxWidth: 350,
        padding: '20px'
    },
    profile: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        maxWidth: 950,
        padding: '48px 125.5px'
    },
    uploadContainer: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        border: '1px dashed #000000',
        flexDirection: 'column',
        padding: '28px',
    },
    title: {
        fontWeight: 'bold'
    },
    subtitle: {
        color: 'grey',
        textAlign: 'center',
        fontSize: 'small'
    },
    previewContainer: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        border: '1px dashed #000000',
        flexDirection: 'row',
        height: '140px',
        '& img': {
            width: '100%',
            height: '100%',
            // objectFit: 'cover'
        }
    },
}
const useStyles = makeStyles(styles as any);

export const businessProfileSchema = yup.object().shape({
    name: yup.string().required('Business Name is required.')
        .min(2, 'Business Name must be at least 2 characters')
        .max(50, 'Business Name must not be greater than 50 characters'),
    description: yup.string().nullable().notRequired(),
    state: yup.string().nullable().notRequired(),
    city: yup.string().nullable().notRequired(),
    website: yup.string().nullable().notRequired(),
    facebook: yup.string().nullable().notRequired(),
    twitter: yup.string().nullable().notRequired(),
    instagram: yup.string().nullable().notRequired(),
    tiktok: yup.string().nullable().notRequired(),
    twitch: yup.string().nullable().notRequired(),
    youtube: yup.string().nullable().notRequired(),
});

type ProfileForm = {
    logo: string,
    logo_updated: boolean,
    cover: string,
    logo_error: boolean,
    cover_updated: boolean,
    cover_error: boolean,
}

export const BusinessProfile = ({ profile, children, submit }: { profile: Business, children: React.ReactNode, submit: Function }) => {
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState(false);
    const profileImageRef: any = useRef();
    const coverImageRef: any = useRef();
    const theme = useTheme();
    const appSlice = useAppSelector(appSelector)
    const largeScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [profileForm, setProfileForm] = useState<ProfileForm>({
        logo: profile?.logo,
        logo_error: false,
        logo_updated: false,
        cover: profile?.cover,
        cover_error: false,
        cover_updated: false,
    })

    const { control, handleSubmit, setValue, reset } = useForm({
        resolver: yupResolver(businessProfileSchema),
    });

    const onProfileImageChange = (event: InputEvent) => {
        const reader = new FileReader();
        const file = event.target.files instanceof FileList
            ? event.target.files[0] : null
        if (!file) return;
        reader.readAsDataURL(file);
        reader.onload = () => {
            setProfileForm((prevState: any) => ({ ...prevState, logo: reader.result, logo_updated: true, logo_error: false }));
        };
    }

    const onCoverChange = (event: InputEvent) => {
        const reader = new FileReader();
        const file = event.target.files instanceof FileList
            ? event.target.files[0] : null
        if (!file) return;
        reader.readAsDataURL(file);
        reader.onload = () => {
            setProfileForm((prevState: any) => ({ ...prevState, cover: reader.result, cover_updated: true, cover_error: false }));
        };
    }

    const onSubmit = async (event: any) => {
        /*if (!profileForm.logo) {
            setProfileForm((prevState: any) => ({ ...prevState, logo_error: true }));
        }
        if (!profileForm.cover) {
            setProfileForm((prevState: any) => ({ ...prevState, cover_error: true }));
        }
        if (!profileForm.cover || !profileForm.logo) return;*/

        setProfileForm((prevState: any) => ({ ...prevState, logo_error: false, cover_error: false }));
        const { logo_error, cover_error, ...rest } = profileForm;

        // Object.keys(event).forEach((x: any) => {
        //     if (!event[x]) {
        //         delete event[x];
        //     }
        // })

        if (event.facebook) event.facebook = prependHTTPS(event.facebook);
        if (event.instagram) event.instagram = prependHTTPS(event.instagram);
        if (event.tiktok) event.tiktok = prependHTTPS(event.tiktok);
        if (event.website) event.website = prependHTTPS(event.website);
        if (event.twitter) event.twitter = prependHTTPS(event.twitter);
        if (event.twitch) event.twitch = prependHTTPS(event.twitch);
        if (event.youtube) event.youtube = prependHTTPS(event.youtube);

        setValue('website', validateURL(event.website))
        setValue('facebook', validateURL(event.facebook))
        setValue('instagram', validateURL(event.instagram))
        setValue('tiktok', validateURL(event.tiktok))
        setValue('twitter', validateURL(event.twitter))
        setValue('twitch', validateURL(event.twitch))
        setValue('youtube', validateURL(event.youtube))

        submit({ ...event, ...rest })
    }

    const expand = async (event: any) => {
        setIsExpanded(!isExpanded)
    }



    return (
        <>
            <Typography variant="h6" >
                Business Profile
            </Typography>
            <Box mt={4} width="100%" className="white" >
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <Grid container justifyContent="center" direction='column' spacing={2}>
                        <Grid item >
                            <UseFormTextField defaultValue={profile?.name} control={control} name="name" placeholder="Business Name" />
                        </Grid>
                        {/*<Grid item>
                            <UseFormTextField defaultValue={profile?.description} multiline={true} control={control} name="description" placeholder="Business Description" />
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item md={6} xs={12}>
                                <UseFormTextField control={control} name="city" placeholder="City" defaultValue={profile?.city} />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <UseFormSelect defaultValue={profile?.state} control={control} name="state" placeholder="State" items={STATES_LIST} />
                            </Grid>

                        </Grid>*/}
                        {/*<Box mt={4} width="100%" className="white" >
                        <Typography variant="h6" >
                            Optional
                        </Typography>
                        </Box>*/}
                        <Grid display={'none'} item container direction={largeScreen ? 'column' : 'row'} spacing={4}>
                            <Grid item md={6} xs={12}>
                                <h3> Upload Business Logo</h3>
                                <Box className={`${classes.uploadContainer} `}>
                                    <Typography className={classes.uploadDescription}>
                                        PNG, GIF, JPG. Max 10mb.
                                        <br />
                                        Recommended size (180x180)
                                    </Typography>
                                    <input
                                        accept="image/*"
                                        ref={profileImageRef}
                                        onChange={(e: InputEvent) => onProfileImageChange(e)}
                                        style={{ display: 'none' }}
                                        type="file"
                                    />
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => profileImageRef?.current.click()}

                                        className={classes.uploadButton}
                                    >
                                        Choose File
                                    </Button>

                                </Box>
                                {profileForm.logo_error && (
                                    <Box mt={2} color="red">Profile Image is required</Box>
                                )}
                            </Grid>
                            <Grid item md={6} xs={12} className="flex-align-center" style={{ flexDirection: 'column' }}>
                                <h3>Preview</h3>
                                <Box className={classes.previewContainer} >
                                    {profileForm.logo ? (
                                        <img src={profileForm.logo} style={{ width: '160px' }} />
                                    ) : (
                                        <Typography className={classes.uploadDescription}>
                                            Upload file to preview profile photo
                                        </Typography>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid display={'none'} item container direction={largeScreen ? 'column' : 'row'} spacing={4}>
                            <Grid item md={6} xs={12}>
                                <h3>Upload Business Cover Image</h3>
                                <Box className={`${classes.uploadContainer} `} >
                                    <Typography className={classes.uploadDescription}>
                                        PNG, GIF, JPG. Max 10mb.
                                        <br />
                                        Recommended size (940x352)
                                    </Typography>
                                    <input
                                        accept="image/*"
                                        ref={coverImageRef}
                                        onChange={(e: InputEvent) => onCoverChange(e)}
                                        style={{ display: 'none' }}
                                        type="file"
                                    />
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => coverImageRef?.current.click()}
                                        className={classes.uploadButton}
                                    >
                                        Choose File
                                    </Button>
                                </Box>
                                {profileForm.cover_error && (
                                    <Box mt={2} color="red">Cover Image is required</Box>
                                )}
                            </Grid>
                            <Grid item md={6} xs={12} >
                                <h3>Preview</h3>
                                <Box className={classes.previewContainer}   >
                                    {profileForm.cover ? (
                                        <img src={profileForm.cover} style={{ objectFit: 'cover' }} />
                                    ) : (
                                        <Typography className={classes.uploadDescription}>
                                            Upload file to preview your  cover
                                        </Typography>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                        {/*<Grid item>
                            <Typography variant="h6" className={classes.title} gutterBottom>
                                Social Media
                            </Typography>
                        </Grid>
                        <Grid item>
                            <UseFormTextField defaultValue={profile?.website} control={control} name="website" placeholder="Website" inputProps={{
                                startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                            }} />
                        </Grid>
                        <Grid item>
                            <UseFormTextField defaultValue={profile?.facebook} control={control} name="facebook" placeholder="Facebook" inputProps={{
                                startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                            }} />
                        </Grid>
                        <Grid item>
                            <UseFormTextField defaultValue={profile?.twitter} control={control} name="twitter" placeholder="Twitter" inputProps={{
                                startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                            }} />
                        </Grid>
                        <Grid item>
                            <UseFormTextField defaultValue={profile?.instagram} control={control} name="instagram" placeholder="Instagram" inputProps={{
                                startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                            }} />
                        </Grid>
                        {
                            isExpanded === true
                                ? <>
                                    <Grid item>
                                        <Button endIcon={<KeyboardArrowUpIcon />} onClick={expand}>
                                            View less
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <UseFormTextField control={control} name="tiktok" placeholder="Tiktok" inputProps={{
                                            startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                                        }} />
                                    </Grid>
                                    <Grid item>
                                        <UseFormTextField control={control} name="twitch" placeholder="Twitch" inputProps={{
                                            startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                                        }} />
                                    </Grid>
                                    <Grid item>
                                        <UseFormTextField control={control} name="youtube" placeholder="Youtube" inputProps={{
                                            startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                                        }} />
                                    </Grid>
                                </>
                                : <>
                                    <Grid item>
                                        <Button endIcon={<KeyboardArrowDownIcon />} onClick={expand}>
                                            View more
                                        </Button>
                                    </Grid>
                                </>
                        }*/}
                    </Grid>
                    <br />

                    {children}
                </form>
            </Box>
        </>
    )
}
