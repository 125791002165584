
import * as yup from 'yup';

import { ALPHBETS_ONLY_REGEX, NUMBERS_ONLY_REGEX, PASSWORD_REGEX } from '@shared/constants/app.constant';

export const profileSchema = yup.object().shape({
    first_name: yup.string().required('First Name is required.')
        .matches(ALPHBETS_ONLY_REGEX, "First Name can only contain letters.")
        .min(2, 'First Name must be at least 2 characters')
        .max(50, 'First Name must not be greater than 50 characters'),
    last_name: yup.string().required('Last Name is required.')
        .matches(ALPHBETS_ONLY_REGEX, "Last Name can only contain letters.")
        .min(2, 'Last Name must be at least 2 characters')
        .max(50, 'Last Name must not be greater than 50 characters'),

    address1: yup.string().required('Address1 is required.'),
    address2: yup.string().defined().nullable(),
    city: yup.string().required('City is required.')
        .min(2, 'City must be at least 2 characters'),
    state: yup.string().required('State is required.'),
    zip: yup.string().required('Zip code is required.')
        .matches(NUMBERS_ONLY_REGEX, 'Zip code can contains only numbers.')
        .min(5, 'Zip code must be 5 digits')
        .max(5, 'Zip code must be 5 digits'),

    phone_number: yup.string().required('Phone Number is required.')
});


export const updatePasswordSchema = yup.object().shape({
    old_password: yup.string().required('Current Password is required.'),
    new_password: yup.string()
        .required('New Password is required.')
        .matches(PASSWORD_REGEX, 'Password must consist of a minimum of 8 characters, at least one capital letter, one lowercase letter, one number and one special character.'),
    confirm_password: yup.string()
        .required('Confirm Password is required')
        .oneOf([yup.ref('new_password'), null], 'Confirm Password does not match'),
});