import {Link as RouterLink, Link, useHistory} from 'react-router-dom';
import { Box, Button, Grid, Paper, Typography, Link as MuiLink } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  useLocation
} from "react-router-dom";
import styles from '../styles';
import { AppLogo } from '@shared/components/Logo';
import { signInAction } from '../../store/slices/auth.slice';
import { useAppDispatch } from '@store/hooks';
import { loginSchema } from '../auth.schema';
import { UseFormTextField } from '@shared/components/UseFormTextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material'
import { useState } from 'react';
import {appIsMobile, useQuery} from "@shared/helpers/global.helper";
import {getProfileAction} from "@store/slices/app.slice";

const useStyles = makeStyles(styles as any);

export const Login = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  let query = useQuery();
  const [showPassword, setShowPassword] = useState(false)
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(loginSchema),
  });

  let queryStringReturnUrl: any = query.get("returnUrl");
  if (queryStringReturnUrl == window.location.pathname) {
    queryStringReturnUrl = '/membership'
  }
  let returnUrl = queryStringReturnUrl ? `/?returnUrl=${queryStringReturnUrl}` : '';

  const onSubmit = (event: any) => {
    dispatch(signInAction({ model: { email: event.email, password: event.password }, path: queryStringReturnUrl ? queryStringReturnUrl : '/membership', history, returnUrl}))
    // dispatch(getProfileAction())
  }

  return (
    <>
      <Box display="flex" sx={{ justifyContent: { xs: 'center', md: 'space-between' } }} px={4} pt={3}>
        <MuiLink color="inherit" component={RouterLink} to={"/membership"} style={{ textDecoration: 'inherit' }} >
          <AppLogo />
        </MuiLink>
      </Box>
      <div className={classes.root}>
        <Box className={`${classes.paper} ${classes.boxDimension}`}
          sx={{
            bgcolor: 'background.paper',
            boxShadow: { xs: 0, md: 10 },
            borderRadius: 1,
            width: { xs: '100%' }
          }} >
          <Typography variant="h6" className={classes.title} gutterBottom>
            Login
          </Typography>
          <Typography variant="body1" className={classes.subtitle} gutterBottom>
            Enter your email and password below
          </Typography>
          <Box mt={4} width="100%">
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} >
              <Grid container justifyContent="center" direction="column" spacing={2}>
                <Grid item>
                  <UseFormTextField control={control} name="email" placeholder="Email" />
                </Grid>
                <Grid item>
                  <UseFormTextField type={showPassword ? 'text' : 'password'} control={control} name="password" placeholder="Password" 
                  
                  inputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>,
                }}/>
                </Grid>
                <Grid item >
                  <Box display="flex" justifyContent="flex-end"  >
                    <Button color="primary" component={Link} to="/auth/forgot-password">
                      Forgot Password?
                    </Button>
                  </Box>
                </Grid>
                <Grid item>
                  <Button variant="contained" className="w-100" color="primary" type="submit">
                    Login
                  </Button>
                </Grid>

                {!appIsMobile() && (
                    <Grid item>
                      <Box display="flex" justifyContent="center" alignItems="center"
                           sx={{flexDirection: {xs: 'column', md: 'row'}}}>
                        <Typography variant="body1" className={classes.subtitle}>
                          Haven't signed up yet?
                        </Typography>
                        <Typography className={`${classes.subtitle} body2`}>
                          <strong>
                            <Button color="primary" component={Link} to={`/auth/signup${returnUrl}`}>
                              Create an Account
                            </Button>
                          </strong>
                        </Typography>
                      </Box>
                    </Grid>
                )}

              </Grid>
            </form>
          </Box>
        </Box>
      </div>
    </>
  )
}
