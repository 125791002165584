import Logo from '@assets/images/token.svg';
import CardMembership from '@assets/images/card_membership.svg';
// import HomeIcon from '@assets/images/home_black_24dp.svg'
import HomeIcon from '@assets/images/menu_book_FILL0_wght400_GRAD0_opsz24.svg'

export const HomeLogo = ({ style = { height: '20px' } }: any) => {
    return (
        // <img src={Logo} alt='Tokens Logo' style={style} />
        <img src={HomeIcon} alt='Home Logo' style={style} />
    )
}
