import React from 'react'
import { Typography, Checkbox } from '@mui/material';
import { Box } from '@mui/system';
import { NotificationModel } from '@interfaces/notifications';
import {AppType } from "@shared/constants/app.constant";

export const Notification = ({ handleChange, notifications, role }: { handleChange: Function, notifications: NotificationModel, role: String }) => {
    return (
        <Box width={1} >
            <Box p={1} className={`flex-space-between border-bottom`} style={{ backgroundColor: '#F1F5F9', marginBottom: '10px' }}>
                <Typography > Type </Typography>
                <Typography > Email </Typography>
            </Box >

            {role === AppType.Business && (
                <>
                <Box py={1} className={`flex-space-between border-bottom`}>
                    <Typography > Membership has been sold </Typography>
                    <Checkbox onChange={(e) => handleChange(e, 'is_sold_token_email_notify')} checked={notifications.is_sold_token_email_notify} />
                </Box>
                </>
            )}

            {role === AppType.Customer && (
                <>
                <Box py={1} className={`flex-space-between border-bottom`}>
                    <Typography >Membership has been successfully purchased</Typography>
                    <Checkbox onChange={(e) => handleChange(e, 'is_purchased_token_email_notify')} checked={notifications.is_purchased_token_email_notify} />
                </Box>
                <Box py={1} className={`flex-space-between border-bottom`}>
                    <Typography > Membership has been redeemed </Typography>
                    <Checkbox onChange={(e) => handleChange(e, 'is_redeemed_token_email_notify')} checked={notifications.is_redeemed_token_email_notify} />
                </Box>
                </>
            )}

        </Box>
    )
}
