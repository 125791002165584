import { Box, Grid, Typography } from '@mui/material'
import React from 'react'

import Vectormobilecode from '@assets/images/Vectormobilecode.png';

export const SendCodeConfirmation = ({ email, children }: { email: string, children: React.ReactNode }) => {
    const getMaskedEmail= (value: string) => {
        if (!value) return;
        return value.replaceAll(/(?!^.*)[a-zA-Z0-9\+\_\-\s](?=.{5})/gim, "*");
    }

    return (
        <Grid container rowSpacing={{ xs: 4, md: 0 }} columnSpacing={{ md: 4 }}>

            <Grid item md={12} xs={12}>
                <Typography variant="body1">
                    Send verification code to my registered phone number
                </Typography> <br />
                <Typography variant="body1">
                    <strong>{getMaskedEmail(email)}</strong>
                </Typography>
                {children}
            </Grid>
        </Grid>
        
    )
}
