import { Box, Container } from '@mui/material'
import React from 'react'

export const AppContainer = ({ children }: { children: React.ReactNode }) => {
    return (
        <Box sx={{ m: { md: 4 } }} >
            <Container maxWidth={'lg'} >
                {children}
            </Container>
        </Box >
    )
}
