import { useEffect, useState } from 'react';

import ReactInputVerificationCode from 'react-input-verification-code';
import { Box, Button, Grid, Paper, Typography, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { Link, useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@store/hooks';
import { resetVerification, sendOTPAction, verificationDataSelector, verifyOTPAction } from '@store/slices/auth.slice';
import { AppLogo } from '@shared/components/Logo';
import {useQuery} from "@shared/helpers/global.helper";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        minHeight: 700,
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 350,
        padding: '20px'
    },
    title: {
        fontWeight: 'bold'
    },
    subtitle: {
        color: 'grey',
        textAlign: 'center',
        fontSize: 'small'
    },
    noWidth: {
        width: 'unset !important',
        maxWidth: 'unset !important'
    },
    boxDimension: {
        minWidth: 350,
        maxWidth: 350,
    },
}));

export const Verification = () => {
    const classes = useStyles();
    const [code, setCode] = useState('');
    const dispatch = useAppDispatch();
    const verificationData = useAppSelector(verificationDataSelector)
    let history = useHistory();
    const query = useQuery();
    let queryStringReturnUrl: any = query.get("returnUrl");
    if (queryStringReturnUrl == window.location.pathname) {
        queryStringReturnUrl = '/membership'
    }
    let returnUrl = queryStringReturnUrl ? `/?returnUrl=${queryStringReturnUrl}` : '';

    const onSendOTP = () => {
        dispatch(sendOTPAction(verificationData.email))
    }

    const onSubmit = () => {
        dispatch(verifyOTPAction({ email: verificationData.email, password: verificationData.password, history, code, queryStringReturnUrl }))
    }

    const isValid = () => {
        return code.toString().length === 6;
    }

    const getMaskedEmail = (value: string) => {
        if (!value) return;
        // return value.replace(/\d(?=\d{4})/g, "*");
        return value.replaceAll(/(?!^.*)[a-zA-Z0-9\+\_\-\s](?=.{5})/gim, "*");
    }

    const getLast4Digits = (phoneNumber: string) => {
        if (!phoneNumber) return;
        let last4 = phoneNumber.substring(phoneNumber.length - 4);
        return last4;
    }

    useEffect(() => {
        if (!verificationData.email) {
            history.push(`/auth/login${returnUrl}`);
            return;
        }
        // onSendOTP();
        return () => {
            dispatch(resetVerification())
        }
    }, [])

    return (
        <>
            <Box display="flex" sx={{ justifyContent: { xs: 'center', md: 'space-between' } }} px={4} pt={3}>
                <AppLogo />
            </Box>
            <div className={classes.root}>
                <Box className={`${classes.paper} ${classes.boxDimension}`}
                    sx={{
                        bgcolor: 'background.paper',
                        boxShadow: { xs: 0, md: 10 },
                        borderRadius: 1,
                        width: { xs: '100%' }
                    }} >
                    <Typography variant="h6" className={classes.title} gutterBottom>
                        Security Verification
                    </Typography>
                    <Box display="flex" width="100%" justifyContent="center">
                        <span className={classes.subtitle} >
                            To secure your account, please complete the following verification
                        </span>
                    </Box>
                    <Box className={classes.noWidth} mt={4} width="100%">
                        <Grid container justifyContent="center" direction="column" spacing={2}>
                            <Grid item>
                                <div className="verify-code-styles">
                                    <ReactInputVerificationCode
                                        length={6}
                                        onChange={setCode}
                                        placeholder={''}
                                        value={code}
                                    />
                                </div>

                            </Grid>
                            <Grid item>
                                <Box display="flex" justifyContent="center">
                                    <Typography variant="body1" className={classes.subtitle} gutterBottom>
                                        Enter the 6 digit code sent to the phone number ending in <strong>{getLast4Digits(verificationData.phone_number)}</strong>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" className="w-100" color="primary" type="submit" disabled={!isValid()} onClick={onSubmit} >
                                    Verify
                                </Button>
                            </Grid>
                            <Grid item >
                                <Box display="flex" width="100%" justifyContent="center" onClick={onSendOTP}  >
                                    <span className={classes.subtitle} >
                                        Didn’t receive the code? <strong className="hover">Resend code</strong>
                                    </span>
                                </Box>
                            </Grid>
                            <Grid item >
                                <Button color="primary" className="w-100" component={Link} to="/auth/login">
                                    Back to Login
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </div>
        </>
    )
}
