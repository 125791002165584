import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, FormGroup, Grid, Typography, Button, FormControlLabel, Checkbox } from '@mui/material';
import { AppContainer } from '@shared/components/AppContainer';
import { isBusiness, isCustomer } from '@shared/helpers/global.helper';
import { UseFormTextField } from '@shared/components/UseFormTextField';
import { UseFormTextArea } from '@shared/components/UseFormTextArea';
import { UseFormCheckBox } from '@shared/components/UseFormCheckBox';
import { UseFormSelect } from '@shared/components/UseFormSelect';
import { yupResolver } from '@hookform/resolvers/yup';
import { createAnnouncementSchema } from './announcement.schema';
import { useForm } from 'react-hook-form';
import { Theme } from '@mui/system';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getMyTokensAction, tokenSelector } from '@store/slices/token.slice';
import { createAnnouncementAction } from '@store/slices/common.slice';
import { commonService } from '@services/common.service';
import { AnnouncementResponse } from "@interfaces/register";
import { boolean } from 'yup';
import { InputEvent } from '@shared/models/input.model';

const useStyles = makeStyles((theme: Theme) => ({

}));
export const CreateAnnouncement = () => {
    const tokenSlice = useAppSelector(tokenSelector);
    const dispatch = useAppDispatch();
    let history = useHistory();
    const { announcementId } = useParams<{ announcementId: string }>();
    const classes = useStyles();
    const [createAndPost, setCreateAndPost] = useState(false);
    const [isActive, setIsActive] = useState(Boolean);

    const { control, handleSubmit, reset, watch } = useForm({
        resolver: yupResolver(createAnnouncementSchema),
    });

    let filters: any = [];
    tokenSlice.myTokens.data?.forEach((val) => {
        const filter = {
            id: val.profile.id,
            name: val.profile.title
        };
        filters.push(filter)
    });

    const getAnnouncement = async (id: string) => {
        const response: any = await commonService.getAnnouncementById(id);
        const announcement: AnnouncementResponse = response.data;
        var status = announcement?.data.status === 'ACTIVE' ? true : false
        reset({
            id: announcement?.data.id,
            token_profile_id: announcement?.data.token_profile_id,
            subject: announcement?.data.subject,
            message: announcement?.data.message,
            status: status
        })
        setIsActive(status);
    }
    useEffect(() => {
        dispatch(getMyTokensAction())
        if (announcementId) {
            getAnnouncement(announcementId);
        }
    }, [])

    const handleStatusChange = (e: InputEvent) => {
        setIsActive(e.target.checked);
    }

    const onSubmit = (event: any) => {
        dispatch(createAnnouncementAction({
            model: {
                ...event,
            },
            history
        }))
    }
    return <AppContainer>
        <Box mt={5} mb={3} pt={4} className="flex-space-between">
            <Typography variant="h6">Create new announcement</Typography>
        </Box>

        <Box width={1} >
            <Box p={0} className={`flex-space-between`} style={{ marginBottom: '40px' }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} >
                                <h3 className={'body4'}>Token Name</h3>
                                <UseFormSelect items={filters} control={control} name="token_profile_id" placeholder={announcementId ? "Membership Name" : "Choose a Membership Name"} disabled={announcementId ? true : false} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <h3 className={'body4'}>Title</h3>
                                <UseFormTextField control={control} name="subject" placeholder="Announcement title" />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <h3 className={'body4'}>Description</h3>
                                <UseFormTextArea control={control} name="message" placeholder="Announcement description" />
                            </Grid>
                            <Grid item xs={12} md={12} >
                                <Box mt={4}>
                                    <UseFormCheckBox control={control} name="status" label="Create and Post"
                                        onChange={(e: InputEvent) => handleStatusChange(e)} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Box>
                                    <Link to="/announcements" style={{ textDecoration: 'none' }}>
                                        <Button className={'button'}
                                            type="submit"
                                            variant="outlined"
                                            color="primary"
                                            style={{ marginRight: '25px' }}
                                        >
                                            <Typography
                                                fontWeight={700}
                                                className={'button-label'}
                                            >
                                                Cancel
                                            </Typography>
                                        </Button>
                                    </Link>
                                    <Button className={'button'}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        <Typography
                                            fontWeight={700}
                                            className={'button-label'}
                                        >
                                            {announcementId ? 'Update' : 'Create'}
                                        </Typography>
                                    </Button>


                                </Box>
                            </Grid>
                        </Grid>
                    </FormGroup>
                </form>


            </Box >
        </Box>

    </AppContainer>
}
