
import * as yup from 'yup';

export const createAnnouncementSchema = yup.object().shape({
    token_profile_id: yup.string().required('Token Name is required'),
    subject: yup.string().required('Title is required.'),
    message: yup.string().required('Description is required.'),
    status: yup.string(),

});
