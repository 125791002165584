import React from 'react';
import { makeStyles } from '@mui/styles';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography, useMediaQuery } from '@mui/material';
import PersonOutlineIcon from '@assets/images/person.svg';
import PinDropIcon from '@assets/images/pin.svg';
import { IToken, } from 'src/interfaces/token';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    li: {
        background: '#FFFFFF',
        border: '1px solid #F1F5F9',
        boxSizing: 'border-box',
        marginBottom: '10px',
        borderRadius: '8px',
    }
}));

type TokenProps = {
    token: IToken,
    children: React.ReactNode,
    onListClick: Function
};

const Secondary = ({ token, children }: { token: IToken, children: React.ReactNode }) => {
    return (
        <>
            <Box display="flex" alignItems="center" justifyContent="flex-start">
                <Box display="flex" marginRight="15px" alignItems="center">
                    <Box display="flex" alignItems="center" mr={1}>
                        <img src={PersonOutlineIcon} />
                    </Box>
                    <Typography className={'body2'} color={'#64748B'}>
                        {token?.business?.name}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                    <Box display="flex" alignItems="center" mr={1}>
                        <img src={PinDropIcon} />
                    </Box>
                    <Typography className={'body2'} color={'#64748B'}>
                        {token?.business?.city}, {token?.business?.state}
                    </Typography>
                </Box>
            </Box>
            <Box mt={2} sx={{ display: { xs: 'block', sm: 'none' } }}> {children}</Box>
        </>
    )
}

export default function TokenListItem({ token, onListClick, children }: TokenProps) {
    const classes = useStyles();
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <List className={classes.root} >
            <ListItem button style={{ backgroundColor: '#FFFFFF', borderRadius: '8px' }} className={classes.li} onClick={() => onListClick(token)}>
                {largeScreen && (
                    <ListItemAvatar >
                        <Avatar>
                            <img src={token.profile?.thumbnail?.file} alt="Cover" />
                        </Avatar>
                    </ListItemAvatar>
                )}
                <ListItemText
                    primary={
                        <>
                            <Box mb={2} sx={{ display: { xs: 'block', sm: 'none' } }}>
                                <Avatar>
                                    <img src={token.profile?.thumbnail?.file} alt="Cover" />
                                </Avatar>
                            </Box>
                            <Typography className={'subtitle1'} color={'#1E293B'}>{token.profile?.title}</Typography>
                        </>}
                    secondary={<Secondary token={token} children={children} />}
                />
                <ListItemSecondaryAction>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}> {children}</Box>
                </ListItemSecondaryAction>
            </ListItem>
        </List>
    );
}
