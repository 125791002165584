import { Box, Grid, Skeleton, Typography } from '@mui/material'
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    flex: {
        display: 'flex',
        alignItems: 'center'
    }
}));

export const TokenOverviewSkeleton = () => {
    const classes = useStyles();
    return (
        <>
            <Box mb={6} width="20%">
                <Typography component="div" className="w-100" variant="body1"><Skeleton /></Typography>
            </Box>
            <Grid container spacing={4} sx={{ flexDirection: { md: 'row', xs: 'column' }}}>
                <Grid item md={2}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Skeleton variant="rectangular" height={100} width={150} /> <br />
                        <Typography component="div" className="w-100" variant="body1"><Skeleton /></Typography>
                        <Typography component="div" className="w-100" variant="body1"><Skeleton /></Typography>
                        <Typography component="div" className="w-100" variant="body1"><Skeleton /></Typography>
                        <Typography component="div" className="w-100" variant="body1"><Skeleton /></Typography>
                        <Typography component="div" className="w-100" variant="body1"><Skeleton /></Typography>
                    </Box>
                </Grid>
                <Grid item md={10} xs={12}>
                    <Typography variant="h5"><Skeleton /></Typography>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Grid container spacing={4}>
                            <Grid item md={2} className={classes.flex}>
                                <Skeleton variant="circular" width={40} height={30} /> &nbsp;
                                <Typography component="div" className="w-100" variant="body1"><Skeleton /></Typography>
                            </Grid>
                            <Grid item md={2} className={classes.flex}>
                                <Skeleton variant="circular" width={40} height={30} />&nbsp;
                                <Typography component="div" className="w-100" variant="body1"><Skeleton /></Typography>
                            </Grid>
                            <Grid item md={2} className={classes.flex}>
                                <Skeleton variant="circular" width={40} height={30} />&nbsp;
                                <Typography component="div" className="w-100" variant="body1"><Skeleton /></Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box my={4}>
                        <Skeleton variant="rectangular" height={250} />
                    </Box>
                  
                    <Typography component="div" className="w-25" variant="body1"><Skeleton /></Typography>
                    <Typography component="div" className="w-100" variant="body1"><Skeleton /></Typography>
                    <Typography component="div" className="w-100" variant="body1"><Skeleton /></Typography>
                    <Typography component="div" className="w-100" variant="body1"><Skeleton /></Typography>
                </Grid>

            </Grid>
        </>
    )
}
