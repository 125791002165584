export const PASSWORD_REGEX = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/
export const URL_REGEX = /^(ftp|http|https):\/\/[^ "]+$/;
export const NUMBER_HYPHEN_REGEX = /^[\d -]+$/;
export const US_ACCOUNT_NO_REGEX = /^[0-9]{7,17}$/;
export const ALPHBETS_ONLY_REGEX = /^[aA-zZ\s]+$/;
export const NUMBERS_ONLY_REGEX = /^\d+$/;
export const NUMBERS_GREATER_THAN_0_REGEX = /^[1-9][0-9]*$/;
export const NUMBERS_GREATER_THAN_0_OR_NEG_1_REGEX = /^[1-9][0-9]*$|^-1$/;
export const DECIMAL_NUMBER_REGEX = /^(\d{1,5}|\d{0,5}\.\d{1,2})$/;
export const ETH_DECIMAL_NUMBER_REGEX = /^(\d{1,9}|\d{0,9}\.\d{1,9})$/;
export const EMAIL_REGEX=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const MESSAGES = {
    DEFAULT_ERROR: 'We are unable to process the request. Try again later'
}

export enum BusinessProfileFlow {
    ACCOUNT_CREATION = 'ACCOUNT_CREATION',
    UPDATE_PROFILE = 'UPDATE_PROFILE',
}
export enum AppType {
    Business = 'BUSINESS',
    Customer = 'CONSUMER',
}

export const PAGINATION_LIMIT = 12;
export const MAX_CHARACTER_LIMIT_PERK_DESCRIPTIONS = 1000;
export const MAX_CHARACTER_LIMIT_PERK_TITLE = 200;

/*export const CURRENCY = {
    USD: 'USD',
    ETH: 'ETH'
};*/

export const CURRENCY = {
    USD: 'USD'
};

export const LOCKOUT_PERIOD_TYPE = {
    HOURS: 'HOURS',
    DAYS: 'DAYS'
};

export const GATE_TYPES = {
    OPENTABLE: 'OPENTABLE',
    REDIRECT: 'REDIRECT'
}

export const CURRENCIES = Object.keys(CURRENCY).map(key => key);
export const LOCKOUT_PERIOD_LIST = Object.keys(LOCKOUT_PERIOD_TYPE).map(key => key);

export const TOKEN_STATUS = {
    PENDING: 'PENDING',
    ACTIVE: 'ACTIVE',
    ARCHIVED: 'ARCHIVED'
}

export const BLOCKCHAIN_NETWORK_ID: { [key: number]: string; } = {
    1: 'MAINNET',
    137: 'POLYGON',
    80001: 'MUMBAI',
    4: 'RINKEBY',
    5: 'GOERLI',
    10: 'OPTIMISM'
}

export const STATES_LIST = [
    { id: "Alabama", name: "Alabama" },
    { id: "Alaska", name: "Alaska" },
    { id: "Arizona", name: "Arizona" },
    { id: "Arkansas", name: "Arkansas" },
    { id: "California", name: "California" },
    { id: "Colorado", name: "Colorado" },
    { id: "Connecticut", name: "Connecticut" },
    { id: "Delaware", name: "Delaware" },
    { id: "Florida", name: "Florida" },
    { id: "Georgia", name: "Georgia" },
    { id: "Hawaii", name: "Hawaii" },
    { id: "Idaho", name: "Idaho" },
    { id: "Illinois", name: "Illinois" },
    { id: "Indiana", name: "Indiana" },
    { id: "Iowa", name: "Iowa" },
    { id: "Kansas", name: "Kansas" },
    { id: "Kentucky", name: "Kentucky" },
    { id: "Louisiana", name: "Louisiana" },
    { id: "Maine", name: "Maine" },
    { id: "Maryland", name: "Maryland" },
    { id: "Massachusetts", name: "Massachusetts" },
    { id: "Michigan", name: "Michigan" },
    { id: "Minnesota", name: "Minnesota" },
    { id: "Mississippi", name: "Mississippi" },
    { id: "Missouri", name: "Missouri" },
    { id: "Montana", name: "Montana" },
    { id: "Nebraska", name: "Nebraska" },
    { id: "Nevada", name: "Nevada" },
    { id: "New Hampshire", name: "New Hampshire" },
    { id: "New Jersey", name: "New Jersey" },
    { id: "New Mexico", name: "New Mexico" },
    { id: "New York", name: "New York" },
    { id: "North Carolina", name: "North Carolina" },
    { id: "North Dakota", name: "North Dakota" },
    { id: "Ohio", name: "Ohio" },
    { id: "Oklahoma", name: "Oklahoma" },
    { id: "Oregon", name: "Oregon" },
    { id: "Pennsylvania", name: "Pennsylvania" },
    { id: "Rhode Island", name: "Rhode Island" },
    { id: "South Carolina", name: "South Carolina" },
    { id: "South Dakota", name: "South Dakota" },
    { id: "Tennessee", name: "Tennessee" },
    { id: "Texas", name: "Texas" },
    { id: "Utah", name: "Utah" },
    { id: "Vermont", name: "Vermont" },
    { id: "Virginia", name: "Virginia" },
    { id: "Washington", name: "Washington" },
    { id: "West Virginia", name: "West Virginia" },
    { id: "Wisconsin", name: "Wisconsin" },
    { id: "Wyoming", name: "Wyoming" }
]
