import { Box, Button, Grid, Paper, Typography, } from '@mui/material'
import { makeStyles } from '@mui/styles';

import styles from '@auth/styles';
import { resetRegister } from '@store/slices/auth.slice';
import { useAppDispatch } from '@store/hooks';
import { useHistory } from 'react-router-dom';
import {useQuery} from "@shared/helpers/global.helper";


const useStyles = makeStyles(styles as any);

export const Success = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    let history = useHistory();
    const query = useQuery();
    let returnUrl = query.get("returnUrl") ? `/?returnUrl=${query.get("returnUrl")}` : '';

    const onSubmit = () => {
        dispatch(resetRegister());
        history.push(`/auth/login${returnUrl}`);
    }

    return (
        <div className={classes.boxDimension}>
            <Typography variant="h6" className={classes.title} gutterBottom>
                Your account has been successfully created!
            </Typography>
            <Box display="flex" width="100%" justifyContent="center">
                <span className={classes.subtitle} >
                    Click the button below to login to your account.
                </span>
            </Box>
            <Box mt={4} width="100%">
                <form noValidate autoComplete="off" >
                    <Grid container justifyContent="center" direction="column" spacing={2}>
                        <Grid item>
                            <Button variant="contained" className="w-100" color="primary" onClick={onSubmit}>
                                Take me to Login Page
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </div>
    )
}
