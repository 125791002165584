import { Profile, User } from "@interfaces/user";
import { AlertColor } from "@mui/material";
import { AnyAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { networkService } from "@services/network-service";
import { toasterService } from "@services/toaster.service";
import { RootState } from "@store/store";
import { RouteComponentProps } from "react-router-dom";
import { resetAuthSlice } from "./auth.slice";
import { resetTokenSlice } from "./token.slice";
import {resetWalletSlice} from "@store/slices/wallet.slice";
import {resetAnnouncementSlice} from "@store/slices/common.slice";

interface IApp {
  loadingMsg?: string | null;
  loading?: boolean;
  alert: AppError;
  user: User,
  profile: Profile,
  accounts: any[],
  showAccountWarning: boolean,
  accountsLoaded: boolean,
  accessToken: string | null
}

export interface AppError {
  message?: string;
  type?: AlertColor | undefined;
  open: boolean
}

export enum MessageType {
  error = 'error',
  success = 'success',
  warn = 'warning',
  info = 'info'
}

const initialState: IApp = {
  loading: false,

  alert: { message: '', type: MessageType.info, open: false },
  user: {} as User,
  profile: {} as Profile,
  accessToken: null,
  showAccountWarning: true,
  accountsLoaded: false,
  accounts: []
}

const isPending = (action: AnyAction) => action.type.endsWith("/pending");
const isRejected = (action: AnyAction) => action.type.endsWith("/rejected");

const hasPrefix = (action: AnyAction, prefix: string) =>
  action.type.startsWith(prefix);

const isPendingAction = (prefix: string) => (
  action: AnyAction
): action is AnyAction => { // Note: this cast to AnyAction could also be `any` or whatever fits your case best
  return hasPrefix(action, prefix) && isPending(action);
};

const isRejectedAction = (prefix: string) => (
  action: AnyAction
): action is AnyAction => { // Note: this cast to AnyAction could also be `any` or whatever fits your case best - like if you had standardized errors and used `rejectWithValue`
  return hasPrefix(action, prefix) && isRejected(action);
};

export const updateProfileAction = createAsyncThunk(
  'app/updateProfile',
  async (model: User, { rejectWithValue, dispatch }) => {
    try {
      const response = await networkService.put(`/auth/me`, model);
      toasterService.success('Profile updated successfully.')
      return response.data;
    }
    catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getProfileAction = createAsyncThunk(
  'app/getProfile',
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const response = await networkService.get(`/auth/me`);
      return response.data;
    }
    catch (error) {
      return rejectWithValue(error);
    }
  }
);

/*export const getPaymentMethods = createAsyncThunk(
  'app/getPaymentMethods',
  async (args, { rejectWithValue, dispatch }) => {
    try {
      const response = await networkService.get(`/bank-accounts`);
      return response.data;
    }
    catch (error) {
      return rejectWithValue(error);
    }
  }
);*/


export const signOutAction = createAsyncThunk(
  'app/signout',
  async (history: RouteComponentProps["history"], { rejectWithValue, dispatch }) => {
    try {
      await networkService.delete(`/auth/signout`);
      dispatch(setLoggedInUser({}))
      dispatch(resetApp())
      dispatch(resetAuthSlice())
      dispatch(resetTokenSlice())
      dispatch(resetWalletSlice())
      dispatch(resetAnnouncementSlice())
      history.push(`/auth/login`);
      return;
    }
    catch (error) {
      return rejectWithValue(error);
    }
  }
);

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetApp(state) {
      Object.assign(state, initialState)
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoadingMsg: (state, action: PayloadAction<string | null>) => {
      state.loadingMsg = action.payload;
    },
    setMessage: (state: IApp, { payload }: PayloadAction<AppError>) => {
      state.alert.open = payload.open === false ? false : true;
      state.alert.message = payload.message;
      state.alert.type = payload.type ? payload.type : undefined;
    },
    setLoggedInUser: (state: IApp, { payload }: PayloadAction<any>) => {
      state.user = { ...state.user, ...payload };
    },
    setAccessToken: (state: IApp, { payload }: PayloadAction<any>) => {
      state.accessToken = payload;
    },
    /*stopPaymentAlert: (state: IApp, { payload }: PayloadAction<any>) => {
      state.showAccountWarning = false;
    }*/
  },
  extraReducers: builder => {
    builder.addCase(signOutAction.fulfilled, (state: IApp, { payload }: PayloadAction<any>) => {
      state.user = {} as User;
    })
    builder.addCase(getProfileAction.fulfilled, (state: IApp, { payload }: PayloadAction<any>) => {
      state.profile = payload.data;
    })
    /*builder.addCase(getPaymentMethods.fulfilled, (state: IApp, { payload }: PayloadAction<any>) => {
      state.accounts = payload.data;
      state.accountsLoaded=true;
    })*/

    /*builder.addMatcher(isRejectedAction(''), (state, action: PayloadAction<AppError>) => {
      // state.loading = false;
      // appSlice.caseReducers.setMessage(state, action)
    })
    builder.addMatcher(isPendingAction(''), (state, action: PayloadAction<IApp>) => {
      state.loading = true;
    })*/

  }
})

export const { setLoading, setMessage, setLoggedInUser, resetApp, setAccessToken, setLoadingMsg,/*stopPaymentAlert*/ } = appSlice.actions;
export const appUserSelector = (rootState: RootState) => rootState.app.user;
export const appUserProfileSelector = (rootState: RootState) => rootState.app.profile;
export const appSelector = (rootState: RootState) => rootState.app;
export default appSlice.reducer;