import Logo from '@assets/images/kebab.svg';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/material';
const useStyles = makeStyles((theme: Theme) => ({
    badge: {
        fontSize: '12px'
    }
}));

export const KebabIcon = ({ style }: any) => {
    const theme = useTheme();
    const classes = useStyles();


    return (

        <img src={Logo} alt='Profile Logo' style={style} />

    )
}
