
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import {MessageType, setLoading, setLoadingMsg, setMessage} from "@store/slices/app.slice";
import { MESSAGES } from "@shared/constants/app.constant";
import { ExtractErrorMessage } from "@shared/helpers/global.helper";
import { environment } from "@env/environment";
import { toasterService } from "./toaster.service";


const headers: Readonly<Record<string, string | boolean>> = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
  "Access-Control-Allow-Credentials": true,
  "X-Requested-With": "XMLHttpRequest",
};

// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    const { store } = require('@store/store');
    const { app } = store.getState()
    store.dispatch(setLoading(true))
    if (app && app.accessToken) {
      config.headers['x-access-token'] = app.accessToken;
    }
    return config;
  } catch (error: any) {
    throw new Error(error);
  }
};

class NetworkService {
  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const http = axios.create({
      baseURL: environment.API_BASE_URL,
      headers,
      withCredentials: true,
    });

    http.interceptors.request.use(injectToken, (error) => Promise.reject(error));
    http.interceptors.response.use((response) => {
      const { store } = require('@store/store');
      store.dispatch(setLoading(false))
      store.dispatch(setLoadingMsg(null))
      return response
    },
      (error) => {
        const { response } = error;
        return this.handleError(response);
      });

    this.instance = http;
    return http;
  }

  // Handle global app errors
  // We can handle generic app errors depending on the status code
  private handleError(error: any) {
    const { store } = require('@store/store');
    store.dispatch(setLoading(false))

    //TODO: FIX THIS!
    if (error?.data?.statusCode == 401 && (window.location.pathname != '/auth/login' && window.location.pathname != '/auth/login/')) {
      //window.location.href = `/auth/login/?returnUrl=${window.location.pathname}`;
      return;
    }
    // message: typeof error.data.error == 'string' ? error.data.error : ExtractErrorMessage(error?.data?.error || error.data)
    // store.dispatch(setMessage({ message: ExtractErrorMessage(error?.data?.errors || error?.data || MESSAGES.DEFAULT_ERROR), open: true, type: MessageType.error }));

    toasterService.error(ExtractErrorMessage(error?.data?.errors || error?.data || MESSAGES.DEFAULT_ERROR))
    return Promise.reject(error?.data);
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }
}

export const networkService = new NetworkService();
