import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Grid, Theme, Typography } from '@mui/material'
import { BusinessQuickLinks } from '../components/BusinessQuickLinks';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { AppContainer } from '@shared/components/AppContainer';
import { appSelector, appUserProfileSelector, appUserSelector, getProfileAction } from '@store/slices/app.slice';
import { TokenListSkeleton } from '@shared/skeletons/TokenListSkeleton';
import { FilteredToken } from '@shared/components/FilteredToken';
import { IToken } from '@interfaces/token';
import { AppNoToken } from '@shared/components/AppNoToken';
import { DashboardHeader } from '@shared/components/DashboardHeader';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NoTokenImage from '@assets/images/noTokenIcon.png';
import { getMyTokensAction, tokenSelector } from '@store/slices/token.slice';
import MagnifierImage from '@assets/images/mdi_magnify.svg';

export const BusinessTokens = () => {
    const userProfile = useAppSelector(appUserProfileSelector);
    const tokenSlice = useAppSelector(tokenSelector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getMyTokensAction())
        dispatch(getProfileAction())
        
    }, [])
    return (
        <AppContainer>
            <Grid container spacing={4} sx={{ flexDirection: { md: 'row', xs: 'column-reverse' }, gridAutoFlow: { xs: 'dense', md: 'row' } }}>
                <Grid item md={8} xs={12}>
                    <Box width={1} sx={{
                        display: {
                            md: 'flex',
                            xs: 'none'
                        },
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <Box width={1 / 2}>
                            <DashboardHeader profile={userProfile} />
                        </Box>
                        <Box width={1 / 2} textAlign="right">
                            {/*<Button className={'button'}
                                style={{ height: '40px' }}
                                component={Link} to={`/business-page/${userProfile?.business?.id}`}
                                variant="contained"
                                size="small"
                                color="primary"
                            >
                                <Typography
                                    fontWeight={700}
                                    className={'button-label'}
                                    align='center'
                                >
                                    View Business Page
                                </Typography>
                            </Button>*/}
                        </Box>
                    </Box>

                    {!tokenSlice.myTokenLoaded && (
                        <>
                            <TokenListSkeleton width="70%" />
                            <TokenListSkeleton width="90%" />
                            <TokenListSkeleton width="60%" />
                            <TokenListSkeleton width="70%" />
                        </>
                    )}

                    {tokenSlice.myTokenLoaded && (
                        <FilteredToken tokens={tokenSlice?.myTokens?.data}
                            emptyListMessage={
                                <AppNoToken
                                icon={<img src={NoTokenImage} />}
                                    title="You do not have any Memberships"
                                    body=""
                                >
                                    {/*<Button className={'button'}
                                        component={Link} to={`/token/create`}
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AddCircleIcon />}
                                    >
                                        <Typography
                                            fontWeight={700}
                                            className={'button-label'}
                                        >
                                            Create Membership
                                        </Typography>
                                    </Button>*/}
                                </AppNoToken>
                            }
                            searchNotMatchedMessage={
                                <AppNoToken
                                    icon={<img src={MagnifierImage} />}
                                    title="No Membership found" body={'Try a different search or create a new Membership by clicking the button below.'}>
                                    <Box my={3}>
                                        <Button size="large" component={Link} to={`/token/create`} color="primary" variant="contained" startIcon={<AddCircleIcon />} >
                                        <Typography
                                            fontWeight={700}
                                            className={'button-label'}
                                        >
                                            Create Membership
                                        </Typography>
                                        </Button>
                                    </Box>
                                </AppNoToken>
                            }

                            actions={(token: IToken) => {
                                return (
                                    <>
                                        <Button className={'button'}
                                            style={{ width: '82px', height: '40px' }}
                                            component={Link}
                                            to={`/tokens/${token.id}`}
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                        >
                                            <Typography
                                                fontWeight={700}
                                                className={'button-label'}
                                            >
                                                View
                                            </Typography>
                                        </Button>
                                        <Button className={'button'}
                                            style={{ height: '40px', border: '2px solid #041E42', marginLeft: '16px' }} component={Link} to={`/token/edit/${token.id}`}
                                            variant="outlined"
                                            size="small"
                                            color="primary"
                                        >
                                            <Typography
                                                fontWeight={700}
                                                className={'button-label'}
                                            >
                                                Edit
                                            </Typography>
                                        </Button>
                                    </>
                                )
                            }} />
                    )}

                </Grid>
                <Grid item md={4} xs={12}>

                    <Box flexDirection='column' sx={{
                        display: {
                            md: 'none',
                            xs: 'flex'
                        },
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <Box>
                            <DashboardHeader profile={userProfile} />
                        </Box>
                        {/*<Box
                            width={1}
                            display="flex"
                        >
                            <Button className={'button w-100'}
                                style={{ height: '40px' }}
                                component={Link} to={`/business-page/${userProfile?.business?.id}`}
                                variant="contained"
                                size="small"
                                color="primary"
                            >
                                <Typography
                                    fontWeight={700}
                                    className={'button-label'}
                                    align='center'
                                >
                                    View Business Page
                                </Typography>
                            </Button>
                        </Box>*/}
                    </Box>
                    {/*<BusinessQuickLinks />*/}
                </Grid>
            </Grid>
        </AppContainer>
    )
}
