import { IToken } from '@interfaces/token'
import { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, TextField, Typography } from '@mui/material'
import TokenListItem from '@shared/components/TokenListItem';
import { useHistory } from 'react-router-dom';
import { TokenLength } from './TokenLength';

export const FilteredToken = ({ tokens, emptyListMessage, searchNotMatchedMessage, actions }: { tokens: IToken[], searchNotMatchedMessage: React.ReactNode, emptyListMessage: React.ReactNode, actions: Function }) => {
    let [filteredTokens, setFilteredTokens] = useState<IToken[]>(tokens)
    const history = useHistory();

    const onSearchHandler = (event: any) => {
        const filtered = tokens.filter((x: IToken) => x.profile?.title.toLocaleLowerCase().indexOf(event.target.value.toLocaleLowerCase()) > -1)
        setFilteredTokens(filtered)
    }

    const onTokenClick = (id: string) => {
        history.push(`/tokens/${id}?owned=${true}`)
    }

    useEffect(() => {
        setFilteredTokens(tokens)
    }, [tokens])

    return (
        <div>
            {/*{tokens?.length > 0 && (
                <TextField
                    size="small"
                    id="searchTokenCustomer"
                    className="w-100"
                    onChange={onSearchHandler}
                    placeholder="Find your Membership"
                    variant="standard"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            )}

            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <TokenLength length={filteredTokens.length} />
            </Box>*/}

            {filteredTokens?.map(token => {
                return <div key={token.id} >
                    <TokenListItem token={token} onListClick={() => onTokenClick(token.id)}>
                        {actions(token)}
                    </TokenListItem>
                </div>
            })}

            {((!filteredTokens || filteredTokens?.length == 0) && tokens?.length>0) && (
                <Box display="flex" height="400px" flexDirection="column" alignItems="center" justifyContent="center">
                    {searchNotMatchedMessage}
                </Box>
            )}
            {(tokens?.length == 0) && (
                <Box display="flex" height="400px" flexDirection="column" alignItems="center" justifyContent="center">
                    {emptyListMessage}
                </Box>
            )}
        </div>
    )
}
